
.addProjectNameTitle {
    font-size: 14px;
    color: white!important;
    letter-spacing: 0.165em;
}

.addProjectInputBox {
    background: none!important;
    background-color: none!important;
    outline: none;
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    width: 100%;
    color: white;
    font-size: 14px!important;
    padding-left: 20px;
    height: 43px;
}

.addProjectInputBox::-webkit-input-placeholder {
    color: white;
    font-size: 14px!important;
}

.nextStepBtn {
    background:  rgba(255, 255, 255, 0.11)!important;
    outline: none!important;
    border-radius: 2px;
    float: right;
    color: white!important;
    font-weight: 500!important;
    margin-top:10px!important;
    width: 106px!important;
    height: 35px!important;
    letter-spacing: 0.08em!important;
    font-size: 13px!important;
}

//Override filepond file upload 
.filepond--root * {
    background-color: #101b29!important;
    border-radius: 4px;
    color: white;
    font-size: 13px!important;
}

//Override filepond file upload 
.filepond--drop-label::after {
    background-color: #1B2D46;
    border-radius: 4px;
    color: white;
    font-size: 13px;
}

//Override react-hamburger-menu
.bm-item {
    display: block;
}