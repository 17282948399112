
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
@import "~bootstrap/scss/bootstrap";

.slideshow {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
}

.slideshow__panel {
  box-sizing: border-box;
  box-sizing: border-box;
  height: 100vh;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  width: 100%;

}

.slideshow__caption {
  opacity: 0;
  transform: translate(0, 30px);
  transition: all 3s;
  
  &.visible {
    opacity: 1;
    transform: translate(0, 0);
  }
  
  &.hidden {
    opacity: 0;
    transform: translate(0, -30px);
  }
}



label {
  color: #FFFFFF!important;
}

.scroll-container {
  height: 100vh;
  overflow-y: scroll;
}

section {
  height: 100vh;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

section h2 {
  opacity: 0;
  transition: opacity 5000ms;
}

section.is-visible h2 {
  opacity: 1;
}


.MuiCard-root {
  overflow: visible;
}

.left-blue-circle {
  z-index: 999;
  background: linear-gradient(222deg, #7e4bff 50%, #438EFF 100%);
  border: 1px solid rgba(255, 255, 255, 0.09);
  box-sizing: border-box;
  height: 280.5px;
  position: absolute;
  width: 340px!important;
  width: 472.86px;
  top: 70px;
  box-shadow: -38px 108px 27px rgba(0, 0, 0, 0.25), -25px 61px 52px rgba(0, 0, 0, 0.5), -22px 60px 32px rgba(0, 0, 0, 0.25), inset 0px -32px 15px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 300px;
  left: 0px;
}

.clear-matte-circle {
  height: 260.5px;
  display: none;
  z-index: 99999;
  animation: float 4s infinite;
  position: absolute;
  width: 242.86px;
  top: 0px;
  border-bottom-right-radius: 300px;
  left: 0px;
  background: rgba(255, 255, 255, 0.08);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(31px);
}

.pink-circle {
  position: absolute;
  width: 151.22px;
  height: 147.55px;
  left: 0px;
  background-size: 500%;
  animation: gradient 15s ease infinite;
  top: 136px;
  background: linear-gradient(180deg, #FF8540 0%, #FF43F7 100%);
  border-radius: 500px;
  transform: matrix(-1, 0.01, 0.01, 1, 0, 0);
}

.aura-ball {
  position: absolute;
  top: 220px;
  left: 50px;
  z-index: 999;
  border-radius: 100%;
  -webkit-animation: float 5s infinite ;
  animation: float 5s infinite;
  box-shadow: 0px 0px 139px 19px #241b93;
  -webkit-backdrop-filter: blur(35.8256px);
  backdrop-filter: blur(35.8256px);
}

.pop-art-diamond {
    position: absolute;
  width: 142.88px;
  height: 180.55px;
  box-shadow: none!important;
  filter: none!important;
  left: -4px;
  -webkit-animation:  float 15s infinite;
  animation: float 15s infinite;
  top: 349px;
  z-index: 99;
}

.part-diamond {
  position: absolute;
  width: 153.23px;
  animation: float 5s infinite;
  height: 149.62px;
  left: 44.05px;
  box-shadow: none!important;
  top: 618.34px;
}

.pink-diamond {
  position: absolute;
  width: 91.09px;
  z-index: 999;
  animation: float 4s infinite;
  box-shadow: none!important;
  height: 116.65px;
  left: -1px;
  top: 658px;
}

.glowy-small-ring {
  position: absolute;
  left: 0.47px;
  top: 723.91px;
}

.right-blue-circle {
  border: 1px solid rgba(234, 222, 237, 0.31);
  box-sizing: border-box;
  box-shadow: 100px 100px 250px rgba(0, 0, 0, 0.25), -5px 100px 100px rgba(0, 0, 0, 0.25), -18px 37px 35px rgba(0, 0, 0, 0.25), inset 0px -40px 30px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 1000px;
  width: 582.51px;
  position: absolute;
  border-bottom-right-radius: 400p;
  height: 398.6px;
  right: 0;
  top: 66.52px;
  background: linear-gradient(227.29deg, #A43AF8 6.61%, #5D43FF 71.76%)!important;
  border: 1px solid rgba(234, 222, 237, 0.31);
  /* transform: matrix(-1, 0.01, 0.01, 1, 0, 0); */
}



.gradient-blue-circle {
  background: linear-gradient(
    120deg
    , #40F4FF 0%, #7F43FF 100%);
        background: linear-gradient(
    204.76deg
    , #40F4FF 4.03%, #5D5AFF 81.65%, #43FFFF 86.79%);
    border: 1px solid rgba(255, 255, 255, 0.09);
    box-sizing: border-box;
    border-radius: 500px;
    border: 1px solid rgba(234, 222, 237, 0.31)!important;
    box-sizing: border-box;
    box-shadow:-6px 20px 8px rgba(0, 0, 0, .25), inset -5px -4px 38px rgba(0, 0, 0, .25);
    position: absolute;
    width: 143.92px;
    height: 147.55px;
    right: 20px;
    z-index: 999;
    top: 83px;
    transform: matrix(-1, 0.01, 0.01, 1, 0, 0);
  }

.floating-dark-hexagon {
  position: absolute;
  width: 166.54px;
  box-shadow: none!important;
  animation: float 5s infinite;
  height: 164.74px;
  left: -28px;
  mix-blend-mode: hard-light;
  top: 496px;
}



.container {
  max-width: 1240px!important;
}

.ml-deployment-home-body {
  height: 100%;
  overflow: hidden;
  // overflow-x: hidden;
  background: linear-gradient(119.56deg, #07091B -2.27%, #110D2D 19.77%, #071832 42.53%, #07091B 54.42%)
}

.mainFirstLineTitle {

  font-family: Helvetica;
font-style: normal;
font-weight: normal;
font-size: 80px;
line-height: 103px;
margin-bottom: -20px!important;
/* or 129% */

display: flex;
margin-bottom: 0px;
align-items: center;
letter-spacing: -0.0503em;

color: #FFFFFF;
}

.mainSecondLineTitle {
  font-family: Helvetica;
font-style: normal;
font-weight: normal;
font-size: 80px;
line-height: 103px;
/* or 129% */

display: flex;
align-items: center;
letter-spacing: -0.0503em;

color: #FFFFFF;
}


.Terminal-code {
  line-height: 24px;
}

.feature-small-img {
  margin-bottom: 47px!important;
}

.model-tag {
  position: absolute;
  top: 40px;
  left: 80px;
  z-index: 999;
}

.Terminal-window {
  height: revert!important;
  border-radius: 7px!important;
  background: radial-gradient(45.78% 46.11% at 75.28% 54.22%, rgba(74, 127, 143, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(
  180deg
  , rgba(108, 234, 143, 0.106) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(
  132.8deg
  , #36426F 11.13%, #2B3660 41.94%, #151C37 62.08%);
    box-shadow: 31px 48px 27px rgba(0, 0, 0, .40), 34px 40px 31px rgba(0, 0, 0, .25), -17px 67px 33px -30px rgba(0, 0, 0, .25)!important;
    border-radius: 18px!important;
    border: none!important;
}

.Terminal-btn-close {
  display: none!important;
}

.Terminal-btn-minimize {
  display: none!important;
}

.Terminal-btn-maximize {
  display: none!important;
}


.right-pink-circle {
  background: linear-gradient(
    125deg
    , #FF8540 0%, #FF43F7 100%);
  border-radius: 500px;
  position: absolute;
  width: 301.84px;
  border-top-left-radius: 0px!important;
  height: 312.55px;
  right: 30px;
  border: 1px solid rgba(234, 222, 237, 0.31);
  box-sizing: border-box;
  box-shadow: 0px 46px 35px rgba(0, 0, 0, .25), 29px 39px 31px rgba(0, 0, 0, .25), inset 0px -40px 50px rgba(0, 0, 0, .25)!important;
  border-radius: 500px;
  top: 125px;
}
    
.stack-of-diamonds {
  position: absolute;
  bottom: -100px;
  right: -100px;
}

.mainDescription {
  font-family: Inter;
  font-style: normal;
  position: relative;
  z-index: 999;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 42px;
  line-height: 28px;
  color: #FFFFFF;
}

.getOnListInput {
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: 600!important;
  padding-left: 20px!important;
  position: relative;
  z-index: 99999;
  width: 285px!important;
  height: 55px!important;
  font-size: 15px;
  line-height: 20px;
  color: white!important;
  background: #373F45!important;
  border-radius: 55px!important;
  outline: none!important;
  border: none!important;
}


.feature-section {
  padding-bottom: 130px;
}


.getOnListInput::placeholder {
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: 500!important;
  padding-left: 10px!important;
  font-size: 15px;
  line-height: 20px;
  color: white!important;
}



.small-feature-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 24px;
  line-height: 20px;
  letter-spacing: 0.09em;
  color: rgba(255, 255, 255, 0.81);
}


.feature-h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 62px;
  width: 462px;
  margin-bottom: 32px;
  line-height: 73px;
  color: #FFF;
}


.scroll-container {
  // height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

section h2 {
  opacity: 0;
  transition: opacity 5000ms;
}

section.is-visible h2 {
  opacity: 1;
}

section:nth-child(2n) {
  background-color: turquoise;
}

section:nth-child(3n) {
  background-color: tomato;
}



Resources

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

body {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
}


section {
  height: 100vh;
  scroll-snap-align: center;
}

section {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: darkorchid;
}

section:nth-child(2n) {
  // background-color: turquoise;
}

section:nth-child(3n) {
  // background-color: tomato;
}

.feature-p1 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  width: 407px;
  font-size: 16px;
  line-height: 200%;
  color: #FFFFFF;
}

.underline-word {
  padding-bottom: 3px;
  border-bottom: 1px solid white;
}

.feature-divider {
  border: 1px solid rgba(82, 82, 82, .44);
  margin-bottom: 27px!important;
  margin-top: 70px;
  width: 472px;
  margin-left: 0px;

}

.left-light-purple-circle {
  position: absolute;
  width: 144.27px;
  animation: float 5s infinite;
  height: 144.27px;
  left: -20px;
  border-radius: 2000px;
  top: -150px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(132.68deg, rgba(72, 80, 255, 0.35) 0%, rgba(112, 117, 255, 0.35) 100.59%);
  box-shadow: 53px 48px 20px rgba(0, 0, 0, 0.25);
}

.left-light-pink-circle {
  position: absolute;
  width: 144.06px;
  background-size: 700%!important;
  animation: float 10s infinite, gradient 7s ease infinite;
  height: 144.27px;
  left: 30.21px;
  z-index: 9999;
  top: -70px;
  border-radius: 1000px;

  background: linear-gradient(132.68deg, rgba(181, 106, 255, 0.2) 0%, rgba(249, 94, 178, 0.178) 194.4%);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */

}

.right-light-purple-circle {
  position: absolute;
  width: 147px;
  height: 147px;
  border-radius: 900px;
  right: 60px;
  z-index: 999;

  top: 0px;
  
  background: linear-gradient(132.68deg, rgba(72, 80, 255, 0.15) 0%, rgba(112, 117, 255, 0.15) 100.59%);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(9px);
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.right-radial-circle {
  position: absolute;
  background-size: 600%!important;
  animation: float 5s infinite, gradient 14s ease infinite;
  width: 153px;
  height: 155px;
  border-radius: 1000px;
  right: 0px;
  top: -90px;
  background: radial-gradient(68.85% 84% at 22.5% 16%, #ff9cba 0%, rgba(208, 96, 247, 0.7) 47.43%, rgba(88, 22, 245, .70) 78.12%, rgb(18, 224, 168) 159.79%);
  box-shadow: 0px 0px 224px rgba(0, 10, 238, .70);
}

.ml-deployment-landing-page-header {
  position: relative;
  top: 100px;
  z-index: 9999;
}

.contact-us-title {
  color: white;
  font-weight: 500;
  /* font-size: 72px; */
  font-family: Helvetica;
  letter-spacing: -0.045em;
  margin-bottom: 30px!important;
}


.right-light-teal-circle {
  position: absolute;
  width: 147px;
  height: 147px;
  border-radius: 100%;
  right: 30px;
  top: 100px;  
  background: linear-gradient(132.68deg, rgba(106, 148, 255, 0.6) 0%, rgba(139, 220, 255, 0.6) 104.4%);
  box-shadow: 9px 49px 20px rgba(0, 0, 0, 0.25);
}

.right-pink-radial-circle {
  position: absolute;
  width: 81px;
  height: 81px;
  border-radius: 3000px;
  right: 20px;
  top: -150px;
  animation: float 5s infinite;
  background: radial-gradient(113% 106% at 37.5% 27%, rgba(255, 156, 186, 0.7) 0%, rgba(208, 96, 247, 0.7) 37.43%, rgba(37, 124, 255, 0.7) 78.12%, rgba(37, 255, 137, 0.7) 94.79%);
  box-shadow: 0px 0px 224px rgba(0, 10, 238, .70);
}

.right-lower-pink-radial-circle {
  position: absolute;
  width: 143px;
  height: 143.31px;
  right: 50px;
  transition: all 2s;
  background-size: 500%!important;
  animation: float 5s infinite, gradient 3s ease infinite;
  top: 190px;
  border-radius: 300px;
  
  background: radial-gradient(110% 115% at 78.5% 10%, rgba(255, 156, 186, 0.7) 0%, rgba(208, 96, 247, 0.7) 37.43%, rgba(37, 124, 255, 0.7) 64.83%);
  box-shadow: 0px 0px 224px rgba(0, 10, 238, 0.7), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.left-radial-circle {
  position: absolute;
  width: 152px;
  height: 147px;
  border-radius: 100%;
  left: 10.58px;
  top: 30.77px;
  background-size: 500%!important;
  animation: float 5s infinite, gradient 7s ease infinite;
  background: radial-gradient(68.85% 84% at 22.5% 16%, #ff9cba 0%, rgba(208, 96, 247, 0.7) 47.43%, rgba(88, 22, 245, .70) 78.12%, rgb(18, 224, 168) 159.79%);
  border: 1px solid rgba(249, 209, 255, .12);
  box-shadow: 0px 0px 224px rgba(0, 10, 238, 0.7);
  transform: rotate(102.87deg);
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-50px);
	}
	100% {
		transform: translatey(0px);
	}
}


.feature-btn {
  font-family: Inter!important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px!important;  
  background: none!important;
  border: none!important;
  color: #E6C6FF!important;
}







.RSPBprogressBar {
  width: 20%;
}

.modal-content {
  padding: 30px;
  max-height: 800px;
  overflow-y: auto
}
.tryBtn {

}

label {
  color: black!important;
}


.waitlist-btn {
  padding: 1rem;
  width: 175px;
  height: 55px;
  margin-left: 20px !important;
  font-family: Inter;
  font-style: normal;
  border-radius: 300px !important;
  font-weight: normal;
  font-size: 14px!important;
  line-height: 20px;
  z-index: 99999;
  color: #FFFFFF;
  font-size: 1rem;
  margin-top: 5px;
  padding: .5rem 3rem;
  border: solid 1px transparent !important;
  background: none !important;
  transition: all 2s ease!important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #e6faff, #aa80f3) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  box-shadow: 2px 1000px 1px #110f2d inset!important;
}

.waitlist-btn:hover {
  padding: 1rem!important;
  width: 175px;
  height: 55px;
  margin-left: 20px !important;
  font-family: Inter;
  margin-top: 5px;
  font-style: normal;
  z-index: 99999;
  border-radius: 300px !important;
  font-weight: normal;
  font-size: 14px!important;
  line-height: 20px;
  color: #FFFFFF;
  font-size: 1rem;
  padding: .5rem 3rem;
  border: solid 3px transparent !important;
  background: none !important;
  transition: all 2s ease!important;
  background-image: linear-gradient(rgba(199, 203, 255, 0), rgba(187, 255, 239, 0)), linear-gradient(101deg, #e6faff, #aa80f3) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  box-shadow: 2px 1000px 1px #110f2d inset!important;
}

.mainTitle {

  font-family: Poppins;
  font-style: normal;
  width: 521px;
  text-align: left;
  font-weight: 600!important;
  font-size: 64px;
  line-height: 128.5%;
  /* or 121px */
  
  letter-spacing: -0.07em;
  
  color: #000000;
}

body {
  background: #F5F8FF!important;
}

.section_1_desc {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  width: 386px;
  text-align: left;
  line-height: 166.5%;
  /* or 30px */
  
  
  color: rgba(0, 0, 0, 0.78);
}

.pivotal-section {
  background: linear-gradient(179.94deg, rgba(0, 0, 0, 0) 0.68%, #000000 20.31%);;
  padding-top: 50px;
}


.footer-body {
  padding: 50px;
}


.settings-endpoint-URL {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  border: 1px solid #555555;
  box-sizing: border-box;
  border-radius: 39px;
  padding: 10px;
}

.settings-status {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  margin-right: 20px;
  margin-left: 20px;
}

.settings-version-number {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF
}

.settings-subtab-subtitle {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.54);
}

.MuiInputBase-input {
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: 300!important;
  font-size: 14px!important;
  line-height: 28px!important;
  color: #FFFFFF!important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21)!important;
}

.section {
  color: white;
  position: relative;
  scroll-snap-align: center;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	

  a {
    text-decoration: underline;
    color: white;

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}

.section__header {
	display: none;
  position: sticky;
  top: 0;
  left: 0;
  padding: rem(20px);
  color: #6b6a67;
  height: rem(80px);
	flex: 0 0 auto;
  z-index: 2;
  align-items: center;
  width: 300px;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  background-color: black;
	
}

.section__title {
  margin: 0;
  font-size: rem(22px);
	letter-spacing: 0.03rem;
}

.section__content {
  --pad: #{rem(20px)};
  padding: var(--pad);
	flex-grow: 1;
  display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
  position: relative;
  z-index: 1;

}

.is-visible {
  .section__content > * {
    opacity: 1;
    transform: translate3d(0, 1rem, 0);
  }
	
	.section__img {
		opacity: 0.75;
	}
}

.section__main-title {
	width: 100%;
	text-align: center;

}

.section__main-title--left {
  grid-column: 2 / -2;
  grid-row: 4 / 6;
}

.section__main-title--right {
	grid-column: 2 / -2;
  grid-row: 4 / 6;
	
}

.section__img {
	max-width: rem(300px);
	margin-bottom: rem(30px);
	grid-column: 4 / -1;
	grid-row: 1 / -1;
	
}

.section__img--left {
	grid-column: 1 / -4;
}

.section__intro {
	text-align: center;
	max-width: 50ch;
	
}

/* Hero */
.hero {
  max-height: rem(800px);
  position: relative;
  color: #fff;
  background-color: #364b3b;
  overflow: hidden;
}

.hero__img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.hero__content {
	flex-grow: 1;
	display: flex;
	justify-content: center;
  align-items: center;
	padding: rem(20px);
}

.hero__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    background: radial-gradient(rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 1));
    opacity: 1;
    transition: opacity 4000ms;
  }
}

.is-visible {
  .hero__bg {
		filter: grayscale(0);
		
    &::after {
      opacity: 0.6;
    }
  }
}

.hero__text {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-shadow: 0 0.1em rem(15px) rgba(0, 0, 0, 0.25);
}

.hero__title {
  margin-bottom: 0;
  width: 100%;
	z-index: 1;
}

.hero__intro {
  width: 100%;
	z-index: 1;
}

[data-aos="fade-out"] {
  opacity: 1;
  transition-property: opacity;
  &.aos-animate {
    opacity: 0;
  }
}

.hero__footprint {
  opacity: 0.7;
  position: absolute;
	width: 150%;
	height: 150%;
	object-fit: contain;
	object-position: cneter;
}

.gentle-giants {
	background-color: rgba(43, 138, 130, 0.8);
}

.gentle-giants__intro {
  grid-column: 3 / 6;
  grid-row: 6 / 8;
}

.gentle-giants__img {
	grid-column: 4 / -1;
	grid-row: 1 / -1;
}

.carnivore-kingdom__intro {
	grid-column: -6 / -3;
  grid-row: 6 / 8;
}

.aerial-adventure {
	background-color: rgba(138, 43, 68, 0.8);
}

.aerial-adventure__intro {
	grid-column: 3 / 6;
  grid-row: 6 / 8;
}



.ball-on-cube {
  mix-blend-mode: color-dodge;
  position: absolute;
  z-index: 99;
  left: -20px;
  top: -90px;
}

.backdrop-index-cards {
  position: absolute;
}

.large-3d-hexagon {
  position: absolute;
  right: -30px;
  bottom: -80px;
  z-index: 99999;
}

.MuiInputLabel-formControl {
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 14px!important;
  line-height: 28px!important;
  color: #FFFFFF!important;
}

.MuiInputLabel-active {
  background: black!important;
  color: white!important;
}

.MuiInputLabel-active {
  background: black!important;
  color: white!important;
}

.MuiSlider-thumb.MuiInputLabel-active 	{
  background: black!important;
}

.MuiSlider-valueLabel	 {
  color: rgb(110, 74, 254)!important;
  left: -20%!important;
}

.MuiSlider-thumb {
  left: 51%;
  width: 25px!important;
  height: 26px!important;
  background: rgb(110, 74, 254)!important;
  border: 1px solid rgba(255, 255, 255, 0.24)!important;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, .25) 0px 4px 4px!important;
}

.MuiSlider-rail {
  background: none!important;
}

.MuiSlider-track {
  background: linear-gradient(88.8deg, #967CFF 79.18%, #D8CEFF 112.03%);
  border: 1px solid #1F1F23;
  box-sizing: border-box;
  height: 12px!important;
  box-shadow: 0px 0px 19px 6px rgba(194, 179, 255, 0.18);
  border-radius: 28px!important;
}

.card-progress-bar {
  background: #212124!important;
  border: 1px solid rgba(14, 13, 13, 0.97)!important;
  box-sizing: border-box!important;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25)!important;
  border-radius: 12px!important;
}

.MuiSlider-root {
  background: #2C2C2C;
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 28px!important;
  height: 12px!important;
  padding: 0px!important;
}


.settings-subtab-title {
  font-family: Inter;
  font-style: normal;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;

  color: #FFFFFF;
}

.settings-subtab-card-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  transition: all 2s ease;
  line-height: 28px;
  margin-bottom: -2px;
  color: #FFFFFF;
}

.settings-subtab-card-description {
  font-family: Inter;
  font-style: normal;
  transition: all 2s ease;
  font-weight: normal;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.83);
}


.settings-search-bar {
  color: rgba(255, 255, 255, 0.57)!important;
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 14px!important;
  width: 250px!important;
  line-height: 28px!important;
  background: none!important;
  margin-top: -10px;
  border: none!important;
}

.green-light {
  filter: drop-shadow(0px 0px 4px rgba(208, 255, 221, 0.71));
  color: #6AFF94;
}

.neon-circle-1 {
  width: 147px;
  border-radius: 100%;
  height: 147px;
  left: 176.58px;
  top: 3056.77px;

  background: radial-gradient(68.85% 84% at 22.5% 16%, rgba(255, 156, 186, 0.54) 0%, rgba(208, 96, 247, 0.54) 37.43%, rgba(37, 124, 255, 0.54) 78.12%, rgba(37, 255, 137, 0.54) 150.79%);  
  box-shadow: 0px 0px 224px rgba(0, 10, 238, 0.7);
  transform: rotate(102.87deg);
}

.light-gradient-card {
  background: linear-gradient(148.81deg, #25244D 6.41%, #0F0F29 29.21%, rgba(21, 25, 53, 0) 51.7%);  box-shadow: 0px 0px 11px #000000, inset 0px 13px 12px rgba(0, 0, 0, 0.25);
  border-top: 6px solid #292A48;
  padding: 90px;
  padding-top: 60px!important;
  border-left:  1px solid rgba(255, 255, 255, 0.17);
  border-right:  1px solid rgba(255, 255, 255, 0.17);
}

.light-gradient-card-h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 46px;
  color: white;
  line-height: 73px;
  letter-spacing: 0.0047em;
}

.MuiCard-root {
  overflow: visible!important;
}

.light-gradient-card-p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  color: white;
  font-size: 16px;
  width: 808px;
  position: relative;
  z-index: 9999999!important;
  line-height: 191.5%;
}

.feature-p2 {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  width: 230px;
  font-size: 16px;
  line-height: 176.5%;
  color: #FFFFFF;
}


.ml-deployment-header {
  width: 100%;
  height: 63px;
  color: white!important;
  position: relative;
  top: 30px;
  z-index: 999999;
}

.terminal-card {
  background: linear-gradient(114.46deg, #3849DD 18.78%, #3849DD 19.09%, #C25FFE 83.51%, #FE5FB5 99.32%)!important;
  border: 1px solid rgba(234, 222, 237, 0.33)!important;
  box-sizing: border-box;
  box-shadow: 0px 100px 100px rgba(0, 0, 0, 0.69), 100px 100px 50px rgba(0, 0, 0, 0.25), inset 0px -40px 40px rgba(0, 0, 0, 0.25)!important;
  border-radius: 16px!important;
}

.terminal-card-1 {
  background: linear-gradient(114.46deg, #3849DD 18.78%, #3849DD 19.09%, #2596FF 74.7%, #31B5FF 98.74%, #31B5FF 99.32%);
  border: 1px solid rgba(234, 222, 237, 0.33);
  box-sizing: border-box;
  box-shadow: 0px 100px 100px rgba(0, 0, 0, 0.17), inset 0px -40px 40px rgba(0, 0, 0, 0.25);
  border-radius: 16px!important;
  margin-left: 50;
  margin-right: -90;
}

.terminal-card-2 {
  background: linear-gradient(114.46deg, #3849DD 18.78%, #3849DD 19.09%, #C25FFE 83.51%, #FE5FB5 99.32%)!important;
  border: 1px solid rgba(234, 222, 237, 0.33)!important;
  box-sizing: border-box!important;
  box-shadow: 0px 100px 100px rgba(0, 0, 0, 0.69), 100px 100px 50px rgba(0, 0, 0, 0.25), inset 0px -40px 40px rgba(0, 0, 0, 0.25)!important;
  border-radius: 16px!important;
}

.main-top-section {
  padding-bottom: 260px;
}

.mainSecondLineTitle {
  position: relative;
  z-index: 999;
}

.stackstr-text {
  left: 0px;
  margin-top: 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  position: absolute;
  z-index: 99999;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.02em;

  color: #FFFFFF;

}

.mainFirstLineTitle {
  position: relative;
  z-index: 999;
}

.header-links {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  margin-right: 55px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  letter-spacing: 0.02em;

  color: #FFFFFF
}

.header-action-links {
  font-family: Inter;
  font-style: normal;
  margin-right: 63px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */


  color: #FFFFFF;

}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place front is invalid - may break your css so removed */  
  padding-top: 100px; /* Location of the box - don't know what this does?  If it is to move your modal down by 100px, then just change top below to 100px and remove this*/
  left: 0;
  right:0; /* Full width (left and right 0) */
  top: 0;
  bottom: 0; /* Full height top and bottom 0 */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  z-index: 9999; /* Sit on top - higher than any other z-index in your site*/
}

.section_1 {
  text-align: center;
  max-width: 90%;
  margin: 0 auto;
}

.settings-main-body-card {
  background: #222222!important;
  border: 1px solid rgba(12, 11, 11, 0.65)!important;
  box-sizing: border-box!important;
  box-shadow: 21px 17px 22px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)!important;
  border-radius: 2px!important;
  border-top-left-radius: 24px!important;
  border-top-right-radius: 24px!important;
}

.settings-subbody-card {
  background: #2C2C2C!important;
  border: 1px solid rgba(14, 13, 13, 0.55)!important;
  box-sizing: border-box!important;
  box-shadow: 9px 21px 30px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)!important;
  border-radius: 13px!important;
}


.footer {
  padding-bottom: 70px;
}

.sign-in-input {
  background: #FFFFFF!important;
  border: 1px solid #E2E7EA!important;
  box-sizing: border-box!important;
  box-shadow: 0px 6px 7px 1px rgba(0, 0, 0, 0.04)!important;
  border-radius: 4px!important;
}

.signInTitle {
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: 600!important;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px!important;
  letter-spacing: -0.0303em;
  
  color: #000000;
}

.sign-in-label {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 9px!important;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.02em;

  color: #000000;
}

::-webkit-scrollbar { 
  display: none!important; 
}

.full-width-divider {
  margin-left: calc(50% - 50vw);
  width: 100vw;
}

.stretch-bg {
  margin-left: calc(50% - 50vw);
  width: 100vw;
  position: relative;
}

.blue-square-square {
  position: absolute;
  left: 12px;
  top: 0px;
  animation: float 5s infinite;
  z-index: 99;
}

.signInDesc {
  font-family: Inter;
  font-style: normal;
  margin-bottom: 16px!important;
  font-weight: normal;
  width: 305px;
  font-size: 14px;
  line-height: 129.34%;
  color: #6C6C6C;
}

.modal-content {
  border-radius: 10px!important;
}

.cancel-btn {
  background: none!important;
  border: 1px solid black!important;
  color: black!important
}

.cancel-btn:hover {
  background: black!important;
  border: 1px solid black!important;
  color: white!important
}

.input-icons {
  font-size: 13px!important;
  margin-top: -5px!important;
  margin-left: 5px!important;
}

.finished-title {
  background: linear-gradient(90.92deg, #E361F9 27.39%, #6A59FF 67.79%);
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.circle-right-blur {
  position: absolute;
  width: 136px;
  height: 147px;
  animation: float 5s infinite;
  border-radius:300px;
  top: 223px;
  right: 0px;

  background: linear-gradient(132.68deg, rgba(72, 80, 255, 0.15) 0%, rgba(112, 117, 255, 0.15) 100.59%);
  mix-blend-mode: hard-light;
  box-shadow: inset 1px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(9px);
}

.glowy-triangle {
  position: absolute;
  right: 0px;
  animation: float 5s infinite;

  top: 04px;
}

.circle-blur {
  position: absolute;
  width: 136px;
  height: 147px;
  z-index: 9999;
  border-radius: 100%;
  left: 27.17px;
  top: 156.06px;
  background: linear-gradient(132.68deg, rgba(72, 80, 255, 0.15) 0%, rgba(112, 117, 255, 0.15) 100.59%);
  backdrop-filter: blur(9px);
}

.half-circle {
  position: absolute;
  width: 136px;
  border-radius: 50%;
  height: 147px;
  right: 0px;
  top: 48px;

  background: linear-gradient(132.68deg, rgba(72, 80, 255, 0.15) 0%, rgba(112, 117, 255, 0.15) 100.59%);
}

.signInBtn {
  background: #000000!important;
  border: 1px solid rgba(65, 63, 63, 0.37)!important;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(115, 109, 150, 0.34)!important;
  border-radius: 5px;
  width: 140px;
  font-family: Inter!important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: -0.02em!important;

  color: #FFFFFF!important

}

.footer-links {
  font-family: Inter;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
}

.footer-copyright {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7)
}




.feature-h3 {

  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 73px;
  /* or 365% */
  letter-spacing: -0.035em;
  
  color: #FFFFFF;
}

.footer-body {

}


.triangle:focus {
  background: linear-gradient(-45deg, #8e2bff, #e73c7e,#4742c0, #1d26fa);
  box-shadow: -7px 16px 2px rgba(172, 177, 194, 1);
  height: 406px;
  z-index: 99;
  border-radius: 50px;
  transform: rotate3d(3, 1, 3, 17deg);
  position: absolute;
  top: 0px;
  width: 300px;
  transition: all 2s;
  margin-top: -20px;
  background-size: 400%!important;
  animation: gradient 15s ease infinite;
}






@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


// .triangle::after {
//   content: "";
//   position: absolute;
//   top: 10px;
//   bottom: 10px;
//   left: 10px;
//   right: 10px;
//   background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #70879e), color-stop(100%, #3f4c6b));
//   transform: rotate(-45deg);
// }

.submitEvent {
  background: #4A41F6!important;
  border-radius: 25px!important;
    font-style: normal;
  font-weight: 600!important;
  font-size: 16px;
  height: 48px;

  width: 108px;

  line-height: 19px;
  /* identical to box height */

  letter-spacing: -0.025em!important;

  color: #FFFFFF;
}

.ui.input>input {
  margin-left: 15px;
  color: black!important;
  background: none!important;
  border: 1px solid black;
  width: 300px;
}

.ui.input>input::placeholder {
  color: black!important;
}

.iconImg {
  margin-top: 20px;
    margin-right: 30px;
    position: absolute;
    top: 10px;
    z-index: 9999999;
}
.Terminal-window {
  // width: 60%;
  box-shadow: 0px 94px 62px -56px rgba(76, 84, 96, 0.62);
  border-radius: 26px;
  opacity: .9;
  height: 330px;
  width: 400px;
  text-align: left;
}

.Terminal-cursor {
  background: #A6EA8E;
}

.Terminal-code {
  font-size: 14px;
}

.lineOfCommand {
  left: 497px;
  display: block;

  top: 82px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  font-family: monospace;
  line-height: 18px;
  letter-spacing: -0.00em;
  padding: 15px;
  color: #070809;
  background: rgba(179, 179, 179, 0.24);
  border-radius: 5px;
}

.howitWorksText {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.04em;
}

.featureCard {
  box-shadow: none!important;
  border-radius: 0px!important;
  background: none!important;
  margin-right: 10px;
  border-top: 1px solid black;
}

.featureHeader {
  margin: 1rem 0;
}

.featureDesc {
  font-size: 1.125rem;
  font-weight: 300;
  margin-bottom: 40px;
}

.Toastify__toast-container {
  z-index: 99999999999;
}

body{
  background: #e3e5f5 !important;
  overflow-x: hidden 
}



.hidden-title {
  display: none;
  color: white;
  font-size: 55px;
  line-height: 1.12;
  position: relative;
  letter-spacing: -.15rem;
  font-family: 'Inter';
  z-index: 999;
}


.main-top-section-padding {
  padding-top: 227px;
}

.mobile-header {
  visibility:hidden;
}


@media (min-width: 700px) and (max-width: 1200px) {
  
  .row-1 {
    display: flex!important;
    position: relative;
    z-index: 999999;
    flex-direction: column-reverse!important;
  }

  .hidden-title {
    display: block;
    color: white;
    font-size: 55px;
    line-height: 1.12;
    position: relative;
    letter-spacing: -.15rem;
    font-family: 'Inter';
    z-index: 999;
  }


  .stack-of-diamonds {
    height: 200px;
    width: 200px;
    right: 10px;
  }




  .mobile-header {
    display: block!important;
    position: absolute;
    visibility: visible;
    top: 0px;
    width: 100%;
    z-index: 999;
    left: 0px;
    height: 300px;
  }

  .header {
    display: none;
  }

  .stackstr-menu-position {
    position: absolute;
    right: 20px;
    top: 30px;
  }

  .stackstr-menu {
    background: none!important;
    border: none!important;
  }

  .footer-copyright {
    text-align: center;
  }

  .stackstr-logo {
    top: 30px;
    left: 20px;
    width: 40px;
    position: absolute;
  }
  
  .mainFirstLineTitle {
    display: none;
  }

  .mainSecondLineTitle {
    display: none;
  }

  .MuiCard-root {
    overflow: visible!important;
  }

  .part-diamond {
    mix-blend-mode: hard-light;
  }

  .pop-art-diamond {
    mix-blend-mode: color-dodge;
  }
  .aura-ball {
    mix-blend-mode: color-burn;
  }

  .pink-diamond {
    mix-blend-mode: color-dodge;
  }
  .right-pink-circle {
    mix-blend-mode: color-burn;
  }


  .signInBtn {
    background: #000000!important;
    border: 1px solid rgba(65, 63, 63, 0.37)!important;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(115, 109, 150, 0.34)!important;
    border-radius: 5px;
    width: 195px;
    font-family: Inter!important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 13px;
    letter-spacing: -0.02em!important;

    color: #FFFFFF!important

  }


  

  .light-gradient-card-p {
    margin-top: 40px;
    width: 100%;
  }

  .light-gradient-card {
    padding: 50px;
    margin-top: 0px!important;
  }
  .light-gradient-card-h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    color: white;
    line-height: 1.12;
    letter-spacing: 0.0047em;
  }

  .pivotal-section {
    padding-top: 0px;
  }

  .light-gradient-card-p {
    margin-top: 40px;
    width: 100%;
  }

  .feature-img  {
    width: 70%;
  }

   
  .getOnListInput {
    height: 46px!important;
    margin: 20px;
    background: black!important;
    color: white!important;
    width: 100%!important;
    padding: .75rem 1.5rem!important;
  }

  .Terminal-window {
    width: 300px!important;
  }





  .feature-section {
    padding: 20px;
  }
  
}



@media (max-width: 699px) {

  .footer-copyright {
    text-align: center;
  }




  .stackstr-logo {
    top: 30px;
    left: 20px;
    width: 40px;
    position: absolute;
  }

  .stackstr-menu-position {
    position: absolute;
    right: 20px;
    top: 30px;
  }

 
  .stackstr-menu {
    background: none!important;
    border: none!important;
  }

  .dropdown-menu.show {
    left: -90px;
  }

  .mobile-header {
    display: block!important;
    position: absolute;
    visibility: visible;
    top: 0px;
    width: 100%;
    z-index: 999;
    left: 0px;
    height: 300px;
  }

  .Terminal-window {
    width: 300px!important;
  }

  .main-top-section-padding {
  padding-top: 137px;
  }

  .hidden-title {
    display: block;
    color: white;
    font-size: 43px;
    line-height: 1.12;
    position: relative;
    letter-spacing: -.15rem;
    font-family: 'Inter';
    z-index: 999;
  }

  .mainFirstLineTitle {
    display: none;
  }

  .mainSecondLineTitle {
    display: none;
  }

  .MuiCard-root {
    overflow: visible!important;
}

  .main-top-section {
    padding-bottom: 140px;
  }

  .feature-section {
    padding: 20px;
  }

  .light-gradient-card-h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    color: white;
    line-height: 1.12;
    letter-spacing: 0.0047em;
  }

  .pivotal-section {
    padding-top: 0px;
  }

  .light-gradient-card-p {
    margin-top: 40px;
    width: 100%;
  }

  .light-gradient-card {
    padding: 20px;
    margin-top: 0px!important;
  }

  .ml-deployment-landing-page-header {
    display: none;
  }

  .mainFirstLineTitle {

  }

  .terminal-card {
    overflow: inherit;
  }

  .mainDescription {
    font-size: 16px;
    line-height: 2;
    position: relative;
    z-index: 999;
    margin-bottom: 0px;
  }

  
  .right-pink-circle {
    mix-blend-mode: color-burn;
  }

  .feature-img {
    width: 100%;
  }

  .mainFirstLineTitle {
    font-size: 64px;
    position: relative;
    z-index: 99999;
  }

  .aura-ball {
    mix-blend-mode: color-burn;
  }

  .pink-diamond {
    mix-blend-mode: color-dodge;
  }

  .terminal-card-1 {
    margin-top: 30px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .light-gradient-card-p {
    position: relative;
    z-index: 9999;
  }



  .model-tag {
    top: 70px;
    left: 30px;
  }

  .dropdown-menu.show {
    background: #FFFFFF;
    border: 1px solid rgba(224, 233, 255, 0.34);
    box-sizing: border-box;
    box-shadow: 17px 23px 15px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }



  .pop-art-diamond {
    mix-blend-mode: color-dodge;
  }

  .feature-btn {
    font-size: 16px;
    margin-left: 0px;
  }

  .stack-of-diamonds {
    height: 200px;
    width: 200px;
    right: 10px;
  }

  .mainSecondLineTitle {
    font-size: 64px;
    position: relative;
    z-index: 99999;
  }

  .row-1 {
    display: flex!important;
    position: relative;
    z-index: 999999;
    flex-direction: column-reverse!important;
  }
  
  .getOnListInput {
    height: 46px!important;
    margin: 20px;
    background: black!important;
    color: white!important;
    width: 100%!important;
    padding: .75rem 1.5rem!important;
  }

  .part-diamond {
    mix-blend-mode: hard-light;
  }

  .small-feature-text {
    font-size: 14px;
  }

  .feature-h1 {
    font-size: 36px;
    width: 100%;
    line-height: 1.12;
  }

  .feature-section {
    margin: 10px;
  }

  .feature-divider {
    width: 100%;
    margin-top: 50px;
  }

  .feature-p1 {
    font-size: 16px;
    width: 100%;
  }

  .feature-h3 {
    line-height: 1.12;
  }

  .feature-p2 {
    width: 100%;
  }

  .half-circle {
    top: 408px;
  }

  .right-light-purple-circle {
    backdrop-filter: blur(3px);
  }

  .glowy-triangle {
    top: 400px;
  }
}