.pageNotFoundBg {
    background: #1D3558;
    height: 100vh;
}

.pageNotFoundBody {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.pageNotFoundTitle {
    color: white;
    font-weight: bold;
    font-size: 86px;
    text-align: center;
    margin-bottom: -4px;
}

.pageNotFoundSubTitle {
    color: white;
    font-size: 29px;
    font-weight: 300;
}

.pageNotFoundBtn {
    background: white!important;
    border-radius: 32px!important;
    font-weight: bold!important;
    outline: none!important;
    color: black;
    width: 160px!important;
    text-align: center;
    font-size: 17px;
    letter-spacing: 0.08em!important;
    height: 54px!important;
}
