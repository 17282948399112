
.issueInstanceTable {
    background: #37496C!important;
    border: 1px solid rgba(231, 245, 255, 0.4)!important;
    box-sizing: border-box!important;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15)!important;
    border-radius: 10px!important;
    padding: 30px!important;
}

.issueInstanceTableProject {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.085em;
    color: rgba(255, 255, 255, 0.65);
}

.issueInstanceTableProjectName {
    font-style: normal;
    font-weight: 500!important;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0px;
    margin-right: 6px;
    color: #FFFFFF;
}

.issueInstanceTableTitleMetrics {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.71);
}

.issueInstanceTopBorder {
    background: rgba(97, 114, 154, 0.29);
    border: 1px solid rgba(231, 245, 255, 0.4);
    box-sizing: border-box;
    height: 47px;
    top: 0px;
    position: relative;
    padding: 13px;
    margin-top: -30px;
    width: 100%;
    /* margin-left: -30px; */
    margin-bottom: 16px;
}

.issueInstanceBg {
    background: linear-gradient(100.44deg, #51637C 8.32%, rgba(40, 58, 89, 0) 82.43%);
    border-radius: 25px;
    padding: 40px;
}

.issueTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 27px;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}

.issuesInstanceTitle {
    font-style: normal;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;
}

.issuesInstanceH1 {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.03em;  
    color: #E8E8E8;
    margin-bottom: 10px;
}

.issuesInstanceH2 {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: rgba(255, 255, 255, 0.76);
    margin-bottom: 0px;
}

.issuesInstanceH3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.76);
}

.issuesInstanceMetrics {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    position: absolute;
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%)
}

.rdrStaticRangeLabel {
    color: black;
}

.issueInstanceSeparator {
    border: 1px solid rgba(255, 255, 255, 0.17)!important;
    width: 100%;
}