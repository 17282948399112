@import "~bootstrap/scss/bootstrap.scss";
@import "variables";

// @import "variables";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono');

@import url('https://fonts.googleapis.com/css2?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&display=swap');
@import 'react-toastify/scss/main.scss';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400&display=swap');

body {
  font-family: 'Inter', sans-serif;
  background: #161719!important;
}

.endpoint-performance-banner {
  // width: 170px;
  height: 35px;
  position: relative;
  top: -20px;
  padding-left: 6px!important;
  cursor: pointer;

  background: rgba(234, 96, 220, 0.44);
  border-radius: 2px;
  padding: 4px;
}


.home-icon {
  font-size: 18px;
  position: absolute;
  top: 100px;
  cursor: pointer;
  color: #9E9E9E;
  font-size: 18px!important;
  left: 28px;
}

.endpoint-performance-banner-text {
  font-family: Inter;
  cursor: pointer;
  padding-left: 13px!important;
  padding-right: 13px!important;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  color: #FFE7FD;
}

.dashboard-lighter-mark {
  background: #434343;
  border: 1px solid #4B4B4B;
  box-sizing: border-box;
  height: 18px;
  box-shadow: 1px 12px 12px rgba(0, 0, 0, .25), 0px 2px 4px rgba(31, 41, 55, .06), 0px 4px 6px rgba(31, 41, 55, .10);
}


.filter-by-text {

}

.dashboard-menu {
  width: 100%;
  background: #2C2C2C;
  border: 1px solid #1D1D1D;
  margin-top: 18px;
  padding-left: 140px;
  box-sizing: border-box;
  border-radius: 1px;
}

.dashboard-medium-gray {
  margin-left: calc(50% - 50vw);
  width: 100vw;
}

.dashboard-grid-bg {
  width: 167px;
  height: 73px;

  background: #222222;
}

.projectsBg {
  background: linear-gradient(168.35deg, #1B1C31 12.72%, rgba(11, 17, 30, 0) 64.13%), linear-gradient(73.71deg, #090A1A 12.75%, #111F2C 55.11%, #102254 93.99%);
    height: 100vh;
}

table th {
  width: auto !important;
}


.sendPredBtn {
  border-radius: 4px !important;
  height: 36px !important;
  outline: none !important;
  border: 1px solid white!important;
  font-family: Inter !important;
  z-index: 999 !important;
  background:none !important;
  border-radius: 4px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  background-size: 200% auto !important;
  color: white !important;
  transition: 0.5s !important;
}


.sendPredBtn:hover {
  border-radius: 4px !important;
  height: 36px !important;
  outline: none !important;
  border: 1px solid white!important;
  font-family: Inter !important;
  z-index: 999 !important;
  background: black !important;
  border-radius: 4px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  background-size: 200% auto !important;
  color: white !important;
  transition: 0.5s !important;
}

.close {display: none;}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
  margin-top: -10px!important;
}

.topGrayThinHeader {
  height: 25px;
  margin-bottom: 0px!important;
  background: #323235;
  border-bottom: 1px solid #201C1C!important;
  box-sizing: border-box;
}

.lightGrayThickBlock {
  height: 31px;
  padding-top: 0px!important;
  margin-top: 0px;
  background: #FAFAFA;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
}

.darkGrayThickHeader {
  height: 63px;
  margin-bottom: 0px!important;
  background: #262626;  
  border-bottom: 1px solid #060606;
}

div.fade.in.modal {
  display:flex !important;
}

.ui.basic.modal {
  height: 600px!important;
  width: 100px;
  height: 600px!important;
  /* background-color: red; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.modal {
  width: 100%!important;
}

.modal-dialog {
  margin: auto;
}

.projectHeader {
  border-top: 1px solid rgba(255, 255, 255, 0.221);
  height: 69px;
  left: 0px;
  z-index: 9999;
  top: 70px;
  width: 100%;

  background: #000000;
  box-sizing: border-box;

  position: absolute;
  box-sizing: border-box;
}

.projectHeaderBottom {
  background: #2C2C2C;
  height: 20px;
  margin-top: 15px;
  width: 100%;
  border: 1px solid #2C2C2C;
  box-sizing: border-box;
  // box-shadow: 0px 13px 15px rgba(0, 0, 0, 0.49);
}


.gradient-header {
  background-size: 600%!important;
  animation: gradient 7s infinite;
  background: linear-gradient(90.05deg, rgba(234, 96, 220, 0.68) 1.65%, rgba(122, 89, 255, 0.68) 96.45%);
  height: 13px;
  margin-top: -4px;
  width: 100%;
  border: 1px solid #2C2C2C;
  box-sizing: border-box;
  box-shadow: 0px 13px 15px rgba(0, 0, 0, 0.49);
}

.ml-deployment-stackstrLogo {
  position: absolute;
  top: 35px;
  left: 30px;
}

.stackstr-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  color: #FFFFFF;
}

.ui.input > input {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  color: #FFFFFF;
}

.ui.input > input::placeholder {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  color: #FFFFFF
}

.input-searchbar {
  width: 268px;
  height: 37px;
}

.projectHeaderDivider {
  background: #37373A;
  width: 1px;
  height: 69px;
  position: absolute;
  top: 0px;
  right: 240px;
}

.projectHeaderLinks {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.02em;
  
  color: white!important;
  
}

.avatarIcon {
  position: absolute;
  top: 20px;
  cursor: pointer;
  right: 130px;
  width: 36px;
}

.ui.input>input::placeholder {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  color: #3E3D3D;
}

.ui.input>input {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #B9B9B9!important;
  box-sizing: border-box;
  width: 268px;

  border-radius: 8px;
  color: #3E3D3D;
}

.homeIcon {
  position: absolute;
  top: 120px;
  left: 35px;
  cursor: pointer;
  color: white;
}

.table td, .table th {
  padding: 1.1rem!important;
}

.projectsBody {
  padding-left: 150px;
  padding-top: 140px;
  padding-right: 200px;
}

.projectsTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */
  
  display: flex;
  align-items: center;
  
  color: #FFFFFF;
}

.table-bottom-mark {
  background: rgb(33, 33, 36)!important;
  border-bottom: 1px solid rgba(238, 238, 238, 0.17)!important;
  box-shadow: 1px 12px 12px rgba(0, 0, 0, .25), 0px 2px 4px rgba(31, 41, 55, .06), 0px 4px 6px rgba(31, 41, 55,.10)!important;
}

.tableTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 187% */

  display: flex;
  align-items: center;

  color: white;
}

.dashboardHeaderLink {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  
  /* identical to box height, or 200% */

  color: white
}

.table>tbody>tr.no-border>td,
.table>tbody>tr.no-border>th {
  border-top: none;
}

.ml-deployment-dashboardTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
  /* identical to box height, or 112% */

  display: flex;
  align-items: center;

  color: white;
}


.dateMarkBtn {
  background-color: rgba(255, 255, 255, 0.0);
  font-family: Inter;
  padding: 5px!important;
  padding-left: 7px!important;
  padding-right: 7px!important;
  outline: none!important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px!important;
  border-radius: 40px!important;
  margin-right: 8px;
  transition: all .6s;
  line-height: 20px;
  color: white;
}

// style={{position: 'absolute', color: 'black',width: 84, fontSize: 14, fontFamily: 'inter', top: '17px', background: 'none', border: '1px solid black', borderRadius: '5px'
.backBtn {
  position: absolute;
  color: white!important;
  width: 84px;
  font-size: 14px!important;
  font-family: inter!important;
  top: 17px;
  background: none!important;
  border: 1px solid black!important;
  border-radius: 5px!important;
}

.gotItBtn {
  color: black!important;
  width: 94px;
  height: 40px;
  font-size: 14px!important;
  font-family: inter!important;
  background: none!important;
  border: 1px solid black!important;
  border-radius: 5px!important;
}

.chooseLangDropdown {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 17px;
  /* identical to box height */
  
  letter-spacing: -0.0303em;
  
  color: #464646;
}

.copyCodeLink {
  font-family: Inter;
  font-style: normal;
  padding-top: 20px;
  float: right;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 17px;
  /* identical to box height */
  
  letter-spacing: -0.0303em;
  
  color: #464646;
}

.gotItBtn:hover {
  color: white!important;
  width: 94px;
  height: 40px;
  font-size: 14px!important;
  font-family: inter!important;
  background: black!important;
  border: 1px solid black!important;
  border-radius: 5px!important;
}

.container-code-editor__qxcy .code-editor__qxcy .code-editor__textarea__qxcy, .container-code-editor__qxcy .code-editor__qxcy .code-editor__hlcode__qxcy {
  font-size: 14px!important;
}

.backBtn:hover {
  position: absolute;
  color: white!important;
  width: 84px;
  font-size: 14px!important;
  font-family: inter!important;
  top: 17px;
  background: black!important;
  border: 1px solid black!important;
  border-radius: 5px!important;
}

.dateMarkBtn:hover {
  background: #EA60DC!important;
  font-family: Inter;
  padding: 5px!important;
  border: none!important;
  font-style: normal;
  padding-left: 7px!important;
  padding-right: 7px!important;
  transition: all .6s;
  font-weight: normal;
  font-size: 14px!important;
  border-radius: 40px!important;
  margin-right: 8px;
  line-height: 20px;
  
  color: white!important;
}

.dashboardSubTabs {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  margin-left: 20px;
  cursor: pointer;
  padding: 8px;
  border-bottom: 4px solid #EA60DC; 
  text-align: center;
  background: none;
  color: white!important;
}

.clicked-dashboardSubTabs {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  margin-left: 20px;
  cursor: pointer;
  padding: 8px;
  border-bottom: none; 
  text-align: center;
  background: none;
  color: white!important;
}

.dashboardSubTabs:hover {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  margin-left: 20px;
  cursor: pointer;
  padding: 8px;
  border-bottom: 4px solid #EA60DC; 
  text-align: center;
  background: none;
  color: white!important;
}

.dashboardSubtitle {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 28px;
  color: white;
}



.dashboardCard {
  background: #212124!important;
  border: 1px solid rgba(255, 255, 255, 0.17)!important;
  box-sizing: border-box!important;
  box-shadow: 19px 26px 25px rgba(0, 0, 0, 0.25), 34px 2px 4px rgba(31, 41, 55, 0.06), 30px 4px 6px rgba(31, 41, 55, 0.1)!important;
  border-radius: 4px!important;
}

.dashboardWatermark {
  font-family: Inter;
  font-style: normal;
  width: 168px;
  height: 32px;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  display: flex;
  background: rgba(234, 96, 220, 0.24);
  border-radius: 2px;
  padding: 10px;
  align-items: center;

  color: #EA60DC;
}

tr td {
   height: auto !important;
}

.bigWhiteBanner {
  height: 254px;
  left: 0px;
  top: 0px;

  background: #222222;
  /* Border */

  box-sizing: border-box;
  /* soft */

  box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.1);
}

.cellTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #A1A1AA;
}

.cellText {
  font-family: Inter;
  font-weight: 400!important;
  font-size: 14px;
  line-height: 20px;
  color: white;
}

.signInCol-1 {
  position: absolute;
  width: 100%;
  float: left;
  height: 100%;
  background-color: #FEFFFF;
  z-index: 1
}

.signInCol-2 {
  position: relative;
  width: 1000px;
  float: right;
  height: 100%;
  background-color: #281597;
  z-index: 99
}

.blue-diamond {
  position: absolute;
  width: 94px;
  height: 378px;
  left: -50px;
  bottom: 0px;
}

.blue-mist {
  position: absolute;
  width: 1015px;
  height: 239px;
  left: -20px;
  bottom:0px;

}

.terminal-test {
  position: absolute;
  left: 178px;
  top: 302px;
  z-index: 9999;
}

.right-blue-column{
  position: absolute;
  width: 94px;
  height: 419px;
  left: 907px;
  bottom: 0px;
  z-index: 9999;
}

.right-mini-blue-column {
  position: absolute;
  width: 94px;
  height: 419px;
  left: 776px;
  bottom:0px;
}

.right-purple-mini-column {
  position: absolute;
  width: 94px;
  height: 419px;
  left: 776px;
  bottom: 0px;
}

.glowy-blue-square {
  position: absolute;
  left: 405px;
  bottom: 40px;
}

.pink-diamond {
  position: absolute;
  width: 93.45px;
  height: 408px;
  left: 100px;
  bottom: 0px;
}

.silver-ring {
  position: absolute;
  width: 128.54px;
  height: 109.39px;
  left: 740px;
  top: 205px;  
}

.outlined-cubes {
  position: absolute;
  width: 152.35px;
  height: 236.19px;
  left: 405px;
  top: 620px;
}

.layeredSignInBg {
  position: absolute;
  left: 20px;
  top: -30px;
  width: 95%;
}

.sign-in-h2 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 14px!important;
  font-size: 15px;
  line-height: 18px;
  color: #000000
}

.stackstr-logo {
  position: absolute;
  width: 101px;
  left: 60px;
  top: 56px;
}

.table td{
  border-bottom: #E4E4E7 solid 1px !important;
}

.projectNumText {
  font-family: Inter;
  font-style: normal;
  margin-left: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  color: #FFE7FD;
}

.tableProjectBg {
  border-radius: 16px 16px 0px 0px!important;
  background: #2C2C2C!important;
  border: 1px solid #1D1919!important;
  box-sizing: border-box!important;
  box-shadow: inset 0px -1px 0px #E4E4E7!important;
}

.projectNumBg {
  background-size: 600%!important;
  animation: gradient 7s infinite;
  background: linear-gradient(90.05deg, rgba(234, 96, 220, 0.68) 1.65%, rgba(122, 89, 255, 0.68) 96.45%);
  width: 33px;
  height: 28px;
  padding: 11px;
  border-radius: 2px;
}

.projectSideMenuMark {
  width: 89px;
  height: 82px;
  position: absolute;
  z-index: 9999;
  left: 0px;
  top: 0px;
  border-bottom-right-radius: 300px;

}

.projectSideMenu {
  box-sizing: border-box;
  position: absolute;
  top: 140px;
  z-index: 999;
  background: linear-gradient(180deg, #282433 0%, #000000 100%);
  border-right: 1px solid rgba(255, 255, 255, 0.12)!important;
  box-sizing: border-box;
  box-shadow: 14px 45px 33px rgba(0, 0, 0, 0.28);
  height: 100%;
  width: 79px;
  border-bottom-right-radius: 300px;
}


.colorful-blob {
  position: absolute;
  width: 266.6px;
  height: 377px;
  left: -30px;
  top: -69px;
  z-index: 999;

}

.feature-cards-sign-in {
  position: absolute;
  width: 833px;
  height: 490px;
  right: 80px;
  top: 193px;
  z-index: 99999;
}

.signInBtn {
  background: #000000!important;
  border: 1px solid rgba(65, 63, 63, 0.37)!important;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(115, 109, 150, 0.34)!important;
  border-radius: 5px;
  width: 195px;
  font-family: Inter!important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: -0.02em!important;

  color: #FFFFFF!important

}

.forgot-password-link {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #EA60DC;
}

.code-preview {
  position: absolute;
  width: 39px;
  height: 39px;
  left: 204px;
  top: 135px;
}

.deployment-sign-in-title {
  position: absolute;
  width: 398px;
  height: 121px;
  left: 204px;
  top: 190px;
  z-index: 9999;

  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 95.99%;
  /* or 70px */

  letter-spacing: -0.05em;

  color: #FFFFFF;
}

.sign-in-center-card {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  top: 200px;
  border: none!important;
}

.already-have-an-account {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 12px!important;

  letter-spacing: -0.02em;
  margin-top: 27px!important;
  color: rgba(0, 0, 0, 0.56);
}

.signInTitle {
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: 600!important;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 27px!important;
  letter-spacing: -0.0303em;
  
  color: #000000;
}

.sign-in-input {
  background: #FFFFFF!important;
  border: 1px solid #E2E7EA!important;
  box-sizing: border-box!important;
  box-shadow: 0px 6px 7px 1px rgba(0, 0, 0, 0.04)!important;
  border-radius: 4px!important;
}

.signInDesc {
  font-family: Inter;
  font-style: normal;
  margin-bottom: 31px!important;
  font-weight: normal;
  width: 305px;
  font-size: 14px;
  line-height: 129.34%;
  color: #6C6C6C;
}

.sign-in-label {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 9px!important;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.02em;

  color: #000000;
}


.signInCard {
  position: absolute!important;
  margin-left: auto;
  width: 335px;
  margin-right: auto;
  z-index: 999;
  padding: 6px;
  top: 50%;
  left: 50%;
  background: #FFFFFF!important;
  border: 1px solid rgba(241, 153, 255, 0.14)!important;
  box-sizing: border-box;
  box-shadow: 0px 62px 51px rgba(17, 12, 21, 0.1)!important;
  border-radius: 23px!important;
}

.signInBg {
  background: #EFF2F5;
  height: 100vh;

}

.signInBtn:hover {
    background-position: right center;
  
}

.ui.reveal>.visible.content .ml-deployment {

}

.keyReveal {
  background: black;
  color: white;
  transition: all .6s;
}

.refresh-icon {
  color: #999999;
  transition: .4s all ease;
}

.refresh-icon:hover {
  transition: .4s all ease;
  color: rgb(234, 96, 220);
}

.table-top-header {
  padding: 10px 10px 10px 15px;
  width: 400px!important;
  // border: 1px solid rgba(68, 68, 70, 0.55);
  background: linear-gradient(90.12deg, #212124 54%, rgba(33, 33, 36, 0) 90.48%) ;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ui.menu .active.item {
  background: #151518!important;
}

.ui.menu .item {
  color: white!important;
}

.ui.menu {
  background: rgb(33, 33, 36);
    color: white!important;
}

.keyRevealed {
  border: 1px solid pink;
  background: black;
  color: white;
  transition: all .6s;
}

.textFieldSignIn {
  outline: black!important;
  color: black!important;
}


.ui.reveal {
  position: absolute!important;
}

.ml-deployment.ui.reveal>.visible.content{
  position: relative!important;
}

.APIKeyBtn {
  background:black!important;
  border-radius: 100px!important;
  font-weight: 500!important;
  // margin-left: 10px;
  font-size: 14px!important;
  border: none!important;
  border: 1px solid white!important;
  width: 206px;
  height: 32px;
  line-height: 16px!important;
  color: #FFFFFF!important;
}

.ui.move.reveal {
  position: absolute!important;
}

.graph_title {

  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600!important;
  font-size: 18.0602px;
  line-height: 27px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.496657px;

  color: #FFFFFF;
  margin-top: 0px;
  top: 30px;
  left: 40px;
  position: relative;
  margin-bottom: 5px;
}

.card-width {
  width: 500px!important;
}

.graph_subtitle {
  position: relative;
  left: 40px;
  top: 30px;

  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15.8027px;
  line-height: 23px;

  letter-spacing: 0.282191px;

  color: #C5D2D9;
}

.gettingStarted-h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.055em;
  
  color: #FFFFFF;
}

.gettingStarted-h2 {
  margin-bottom: 7px!important;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.04em;
  
  color: #FFFFFF;
}

.gettingStarted-p1 {
  margin-bottom: 13px!important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143.84%;
  color: rgba(255, 255, 255, 0.87);
}

.gettingStarted-img {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 54px 26px -23px rgba(0, 0, 0, 0.25);  
  border-radius: 10px;
}

.code-text {
font-family: Source Code Pro, monospace;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
}

.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
  font-family: Inter!important;
}

.filter-by-text {
  margin-right: 16px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff54;
}

.refresh-btn {
  font-family: Inter;
  font-style: normal;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  display: flex;
  transition: .5s all ease;
  align-items: center;

  color: #FFFFFF;

}

.ui.inverted.top.popup:before {
  background: black;
}

.code-bg {
  background: #002451;
  color: white;
  padding: 30px!important;
  line-height: 2;
  height: 360px;
  // overflow-x: hidden;
  overflow-y: auto;
  border-radius: 6px!important;
  box-shadow: none!important;
  font-family: Source code pro;
  width: 100%!important;
}


.hover-project-cell:hover {
  background: #28282E;
  box-shadow: 0px 11px 18px rgba(0, 0, 0, 0.25), 0px 32px 15px -4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  width: 70%;
  border: none!important;
  transition: .4s all;

}

.refresh-btn:hover {
  color: rgb(255, 107, 208);
  transition: .5s all ease;
}

.ui.search.dropdown>input.search {
  color: white!important;
}

.center-loader {
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
}

.gettingStarted-lightBlock {

  padding: 11px;
  margin-bottom: 10px;
  background-size: 600%!important;
  animation: gradient 7s infinite;
  background: linear-gradient(90.05deg, rgba(234, 96, 220, 0.68) 1.65%, rgba(122, 89, 255, 0.68) 96.45%);
  border-radius: 2px;
  box-sizing: border-box;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.gettingStarted-codeBlock {
  padding: 15px;
  margin-bottom: 14px;
  background: #1C1A23;
  border: 1px solid rgba(255, 255, 255, 0.19);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 25px 40px -8px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  transform: rotate(0deg);
}

.gettingStarted-btn {
  width: 133px;
  height: 36px;
  left: 813px;
  top: 798px;

  background: #000000!important;
  border-radius: 30px!important;
  font-family: Inter;
  font-style: normal;
  font-weight: 600!important;
  border: none!important;
  font-size: 14px;
  line-height: 20px;

  color: #FFFFFF!important;
}

.gettingStarted-card {
  background: #292828!important;
  border: 1px solid rgba(111, 110, 110, 0.71)!important;
  box-sizing: border-box;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15)!important;
  border-radius: 8px!important;
}

.gettingStarted-cardText {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  
  color: #FFFFFF;
}


/**
* Settings Page 
**/

.settings-main-body-card {
  background: #222222!important;
  border: 1px solid rgba(12, 11, 11, 0.65)!important;
  box-sizing: border-box!important;
  box-shadow: 21px 17px 22px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)!important;
  border-radius: 2px!important;
  border-top-left-radius: 24px!important;
  border-top-right-radius: 24px!important;
}

.settings-subbody-card {
  background: #2C2C2C!important;
  border: 1px solid rgba(14, 13, 13, 0.55)!important;
  box-sizing: border-box!important;
  box-shadow: 9px 21px 30px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)!important;
  border-radius: 13px!important;
}

.settings-search-icon {
  color: rgba(255, 255, 255, 0.35)!important;
  font-size: 18px!important;
}

.settings-endpoint-URL {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  border: 1px solid #555555;
  box-sizing: border-box;
  border-radius: 39px;
  padding: 10px;
}

.settings-status {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  margin-right: 20px;
  margin-left: 20px;
}

.settings-subtab-card {
  background: #2C2C2C!important;
  box-sizing: border-box!important;
  box-shadow: 4px 11px 39px rgba(0, 0, 0, 0.25)!important;
  border-radius: 4px!important;
  margin-bottom: 3px;
  cursor: pointer;
}

.settings-subtab-card-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  transition: all 2s ease;
  line-height: 28px;
  margin-bottom: -2px;
  color: #FFFFFF;
}

.settings-subtab-card-description {
  font-family: Inter;
  font-style: normal;
  transition: all 2s ease;
  font-weight: normal;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.83);
}

.settings-search-bar {
  color: rgba(255, 255, 255, 0.57)!important;
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 14px!important;
  width: 250px!important;
  line-height: 28px!important;
  background: none!important;
  margin-top: -10px;
  border: none!important;
}

.green-light {
  filter: drop-shadow(0px 0px 4px rgba(208, 255, 221, 0.71));
  color: #6AFF94;
}

.settings-version-number {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF
}

.settings-gray-divider {
  height: 70px;
  width: 2px;
  background: #2C2C2C;
}

.settings-subtab-title {
  font-family: Inter;
  font-style: normal;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;

  color: #FFFFFF;
}

.settings-save-btn {
  width: 91px!important;
  height: 36px;
  background: #000000!important;
  border-radius: 2px!important;
  font-family: Inter;
  font-style: normal;
  font-weight: 500!important;
  font-size: 14px!important;
  line-height: 28px;
  border: none!important;
  color: #FFFFFF!important
}

.settings-cancel-btn {
  width: 82px;
  height: 36px;

  border: 1px solid #FFFFFF!important;
  background: none!important;
  box-sizing: border-box;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  margin-left: 10px!important;

  color: #FFFFFF!important;
  border-radius: 2px;
}

.MuiInputBase-input {
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: 300!important;
  font-size: 14px!important;
  line-height: 28px!important;
  color: #FFFFFF!important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21)!important;
}

.MuiInputLabel-active {
  background: black!important;
  color: white!important;
}

.MuiSlider-thumb.MuiInputLabel-active 	{
  background: black!important;
}

.MuiSlider-valueLabel	 {
  color: rgb(110, 74, 254)!important;
  left: -20%!important;
}

.MuiInputLabel-formControl {
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 14px!important;
  line-height: 28px!important;
  color: #FFFFFF!important;
}

.settings-subtab-subtitle {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.54);
}

.card-progress-bar {
  background: #212124!important;
  border: 1px solid rgba(14, 13, 13, 0.97)!important;
  box-sizing: border-box!important;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.25)!important;
  border-radius: 12px!important;
}

.MuiSlider-root {
  background: #2C2C2C;
  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 28px!important;
  height: 12px!important;
  padding: 0px!important;
}


.MuiSlider-thumb {
  left: 51%;
  width: 25px!important;
  height: 26px!important;
  background: rgb(110, 74, 254)!important;
  border: 1px solid rgba(255, 255, 255, 0.24)!important;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, .25) 0px 4px 4px!important;
}

.MuiSlider-rail {
  background: none!important;
}

.MuiSlider-track {
  background: linear-gradient(88.8deg, #967CFF 79.18%, #D8CEFF 112.03%);
  border: 1px solid #1F1F23;
  box-sizing: border-box;
  height: 12px!important;
  box-shadow: 0px 0px 19px 6px rgba(194, 179, 255, 0.18);
  border-radius: 28px!important;
}


@media (max-width: 1500px) {
  .card-width {
    width: 300px!important;
  }  

  .sign-in-center-card {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    top: 150px;
    border: none!important;
  }
}