




.stepTitleCard {
    width: 100%!important;
    height: 80px!important;
    left: 482px;
    top: 288px;

    background: rgba(255, 255, 255, 0.1)!important;
    box-shadow: 1px 18px 13px rgba(45, 39, 39, 0.25)!important;
    border-radius: 4px!important;
}

.stepTitleText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    margin-bottom: 8px;
    margin-top: 0px;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: #FFFFFF;
}

.stepSubTitleText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;

    color: rgba(255, 255, 255, 0.53);
}

.expandStepCard {
    border: 1px solid rgba(255, 255, 255, 0.53)!important;
    background: none!important;
    box-sizing: border-box!important;
    border-radius: 7px!important;
    box-shadow: none!important;
}

.expandStepCardPadding {
    padding: 25px!important;
}

.expandStepsInput {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    width: 80%;
    line-height: 16px;
    height: 40px;
    padding-left: 12px;
    /* identical to box height */

    letter-spacing: -0.005em;

    color:white;

    border: 1px solid rgba(255, 255, 255, 0.4)!important;
    box-sizing: border-box!important;
    background: none!important;
    outline: none!important;
    border-radius: 3px!important;
}

.expandStepsInput::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: -0.005em;
    color: rgba(255, 255, 255, 0.38);
}

.createStepsBtn {
    font-style: normal;
    width: 114px;
    height: 36px!important;
    margin-left: 19px;
    background: #505E78!important;
    border-radius: 22px!important;
    font-weight: 500!important;
    font-size: 14px;
    line-height: 17px;
    border: none!important;
    /* identical to box height */

    letter-spacing: 0.02em!important;

    color: #FFFFFF!important;
}

.clearStepsBtn {
    background: none!important;
    border: none!important;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: #FFF!important;

}

.metricTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    margin-right: 19px;
    color: #FFFFFF;
}

.metricInputBoxes {
    background: rgba(162, 169, 188, 0.5)!important;
    border: none!important;
    width: 124px;
    height: 34px;
    border-radius: 5px!important;
}

.addThresholdsBtn {
    background: none!important;
    border: 1px solid rgba(255, 255, 255, 0.19);
    border-radius: 5px;
    font-weight: 500;
    margin-right: 17px;
    font-size: 15px;
    line-height: 152.84%;
    /* identical to box height, or 23px */


    color: #FFFFFF!important;


}


.saveAvatar {
    background: none!important;
    border: 1px solid rgba(255, 255, 255, 0.19);
    border-radius: 5px;
    font-weight: 500;
    margin-right: 17px;
    font-size: 15px;
    line-height: 152.84%;
    /* identical to box height, or 23px */


    color: #FFFFFF!important;


}

.saveBtn {
    background: rgba(6, 10, 20, 0.5)!important;
    border-radius: 5px!important;
    border: none!important;
    font-weight: 500;
    width: 96px;
height: 37px;
    font-size: 15px;
    line-height: 152.84%;
    color: #FFFFFF!important;
}