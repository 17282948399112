


.notifTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    margin-left: 12px;
    margin-bottom: 10px;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.55);
}
paper-button {
    text-transform: none!important;
  }

.allAlertsBtn {
    background: rgba(255, 255, 255, 0.35)!important;
    border-radius: 0px!important;
    height: 20px!important;
    width: 142px!important;
    font-weight: normal;
    font-size: 14px!important;
    outline: none!important;
    border: none!important;
    line-height: 17px!important;
    font-weight: 400!important;
    /* identical to box height */
    text-transform: none!important;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    color: #FFFFFF!important;

}

.cardTitleMarker {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.045em;

    color: #9AA1B0;
}

.cardTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 2px;
    /* identical to box height */


    color: #FFFFFF;
}

.cardDescription {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.045em;

    color: rgba(255, 255, 255, 0.74);
}

.unreadBtn {
    height: 20px!important;
    width: 142px!important;
    font-weight: 400!important;
    border-bottom-right-radius: 0px!important;
    outline: none!important;
    border: none!important;
    font-style: normal!important;
    font-weight: normal!important;
    font-size: 14px!important;
    text-transform: none!important;

    line-height: 17px!important;
    /* identical to box height */
    
    
    color: #000000!important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif!important;
    background: #ABB2BF!important;
    border-radius: 0px!important;
}


.notifCard {
    margin-bottom: 7px!important;
    background: rgba(24, 36, 60, 0.39)!important;
    border: 0.5px solid #1C2A46!important;
    box-shadow: none!important;
    transition: .5s ease-in;
    box-sizing: border-box!important;
    backdrop-filter: blur(12px)!important;
    border-radius: 6px!important;
}



.notifMenuSeparater {
    border: 1px solid rgba(255, 255, 255, 0.12);
    margin-left: -40px;
    margin-bottom: 19px;
}