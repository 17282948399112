@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

label {
  color: #FFFFFF!important;

}
.ml-experimentation-home-body {
  background: linear-gradient(162.07deg, #02102C 12.23%, rgba(91, 48, 112, 0) 56.24%), linear-gradient(248.77deg, #161F9B 2.49%, #0E1D2B 38.42%, #000000 68%, #111015 84.01%);  
  height:100%;
}

.projectsHeader {
  background: black;  
  border-top: 1px solid rgba(255,255, 255, .27);
  height: 63px;
  width: 100%;
  position: absolute;
  top: 70px;
}

.voyagersTitle {
  font-family: Poppins;
  font-style: normal;
  position: absolute;
  top: 23px;
  left: 80px;
}

.headerPadding {
  padding-left: 220px;
  padding-right: 170px;
}

.ui.inverted.table {
  background: none!important;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.headerLinks {
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 15px!important;
  line-height: 18px!important;
  cursor: pointer;
  padding-bottom: 6px;
  margin-right: 35px;
  transition:  all 1s ease;
  color: #FFFFFF!important;
}

.headerLinks:hover {
  border-bottom: 1px solid #6faf8c;
  // width: 97px;
  transition:  all 1s ease;
  padding-bottom: 6px;
}

.lighterGrayBlockHeader {
  background: rgba(196, 196, 196, 0.14);
  position: absolute;
  padding: 10px;
  top: 0px;
  right: 0px;
  width: 132px;
  height: 63px;
}



.experimentSideMenu {
  background: linear-gradient(180deg, #000000 2.66%, rgba(16, 40, 89, 0.42) 100%);
  box-sizing: border-box;
  box-shadow: 12px 4px 21px rgba(0, 0, 0, 0.17);
    backdrop-filter: blur(62px);
  height: 70%;
  top: 159px;
  z-index: 999;
  border-radius: 300px;
  border-bottom-right-radius: 400px!important;
  padding: 30px;
  transition: all 3s ease;
  width: 0px;
  position: absolute;
  left: 14px;
}

.exp_menu_body {
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
  display: none;
}



// document.getElementById('exp_menu_body').style.transition = 'fadeOut 3s ease-in-out'



.dashboardBg {
  background: linear-gradient(133.45deg, #1B1C31 17.37%, rgba(11, 17, 30, 0) 27.47%), linear-gradient(69.09deg, #090A1A 14.49%, #111F2C 49.08%, #04103A 86.1%);
  height:200vh;
}



.experimentBanner {
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(62px);
  top: 135px;
  transition: all 2s ease;
  background: linear-gradient(89.97deg, rgba(255, 255, 255, 0.06) 5.73%, rgba(255, 255, 255, 0.0138) 89.96%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  padding-left: 110px;
  padding-right: 110px;
  position: absolute;
  height: 77px;

  width: 100%;
}


.experimentBannerTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  border-bottom: 1px solid #ADFCC9;;
  padding-bottom: 3px;

  color: #FFFFFF;
}

.dashboardMetricsBody {
  padding-left: 130px;
  transition: all 2s ease;
  padding-top: 240px;
  padding-right: 100px;
}


.experimentSideMenuLink {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  width: 97px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.0);

  transition:  all 1s ease;
  padding-bottom: 5px;
  color: #FFFFFF;
}

.experimentSideMenuLink:hover {
  cursor: pointer;
  font-weight: 500;
  border-bottom: 1px solid #6faf8c;
  width: 97px;
  transition:  all 1s ease;
  padding-bottom: 6px;
}

.experimentSideMenuTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.voyagersLogo {
  position: absolute;
  top: 12px;
  left: 40px;
}

.voyagersTitle {
  font-family: Poppins;
  font-style: normal;
  position: absolute;
  top: 23px;
  left: 80px;
}

.APIKeyBtn {
  background: #6F45F5!important;
  border-radius: 3px!important;
  font-weight: 500!important;
  // margin-left: 10px;
  font-size: 14px!important;
  border: none!important;
  width: 206px;
  height: 32px;
  line-height: 16px!important;
  color: #FFFFFF!important;
}

.grayBlockHeader {
  position: absolute;
  padding: 17px;
  background: rgba(196, 196, 196, 0.14);
  width: 376px;
  height: 63px;
  right: 0px;
  top: 0px;
}

.userProfileDropdownBtn {
  background: none!important;
  border: none!important;
  margin-top: 4px;
}

.usernameText {
  font-weight: 300;
  font-size: 15px!important;
  line-height: 18px!important;
  letter-spacing: -0.025em;
  color: rgba(255, 255, 255, 0.9)!important;
  opacity: 0.8!important;
}

.ui.input>input {
  width: 300px!important;
  font-size: 15px;
  background: none!important;
  border: 1px solid #A099A5;
  color: black!important;
  height: 37px!important;
}

.ui.search .results {
  font-size: 15px!important;
  width: 300px!important;
  max-height: 20vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.ui.search>.results .result {
  width: 300px!important;
}

.dashboardMetricsTabs {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-right: 31px;
  /* identical to box height */
  
  
  color: #FFFFFF;
  
}

.centerLoader {
  position: fixed;
  top: 50%;
  transition: all 2s ease;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.hljs {
  font-size: 13px!important;
}

.hljs-subst {
  color: pink!important;
}

.hljs-title,
.hljs-section,
.hljs-type,
.hljs-doctag {
  color: #ff73fd!important;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-tag,
.hljs-name {
  color: #ffaf9d!important;
}

.hljs-symbol,
.hljs-bullet,
.hljs-variable,
.hljs-template-variable,
.hljs-literal { 
  color: rgb(191, 182, 247)!important;
}

.container-code-editor__qxcy {
  width: 100%!important;
  height: 77vh!important;
}

.mlTitle {
  // width: 624px;
  left: 847px;
  top: 346px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  margin-bottom: 0px;
  font-size: 76px;
  // line-height: 124%;
  /* or 94px */

  letter-spacing: -0.065em;

  color: #FFFFFF;
}

.mlSubtitle {
  left: 846px;
  top: 433px;
  margin-bottom: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 76px;
  line-height: 124%;
  /* or 94px */

  letter-spacing: -0.065em;

  color: white;

}
.createProjectLabel {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3c4b64!important;
}

.signInBtn {
  // background: linear-gradient(165.99deg, #FFFFFF -130.93%, rgba(255, 255, 255, 0) 69.53%), linear-gradient(91.8deg, #D6924E 0%, #D56A82 33.1%, #7A25B3 97.3%);
  outline: none!important;
  border: none!important;
  border-radius: 4px!important;
  z-index: 999!important;
  width: 100%;
  background-image: linear-gradient(165.99deg, #FFFFFF -130.93%, rgba(255, 255, 255, 0) 69.53%), linear-gradient(91.8deg, #D6924E 0%, #D56A82 33.1%, #7A25B3 97.3%)!important;
  // width: 233px;
  height: 41px!important;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08)!important;
  font-style: normal!important;
  font-weight: 500!important;
  font-size: 14px!important;
  line-height: 17px!important;
  background-size: 200% auto!important;

  color: rgba(255, 255, 255, 0.92)!important;
  transition: 0.5s!important;
  
}

.signInBtn:hover {
    background-position: right center;
  
}

.desktopMsg {
  display: none;
}

div.fade.in.modal {
  display:flex !important;
}

.modal-dialog {
  margin: auto;
}

.contactUsBtn {
  width: 147px;
  height: 56px;
  left: 858px;
  top: 566px;
  outline: none!important;
  background: #7A5EEA!important;
  border: none!important;
  border-radius: 50px!important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px!important;
  line-height: 136.5%;
  letter-spacing: 0.025em;
  color: #FFF7F7;
}

.highlightBtn {
  border: 1px solid #BEFACD!important;
}

.mlDesc {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 149%;
  color: #FFFFFF;
  width: 435px;
  height: 72px;
}

.interactTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 149%;
  background: linear-gradient(90.16deg, #F7AF9C 30%, #C464AB 40.77%, #7A5EEA 87.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section2Bg {
  background: linear-gradient(315.74deg, #C464AB -1.07%, rgba(97, 8, 74, 0.046875) 28.42%), radial-gradient(47.08% 47.08% at 31.9% 96.23%, rgba(196, 100, 171, 0.13) 0%, rgba(134, 220, 239, 0.08) 25.84%, rgba(196, 100, 171, 0) 100%), linear-gradient(352.14deg, #C4C4C4 -9.93%, #3429C4 -9.92%, rgba(81, 77, 133, 0.617984) 27.79%, rgba(31, 33, 96, 0) 88.79%);
}



.interactDesc {
  font-weight: normal;
font-size: 16px;
line-height: 149%;
/* or 24px */
width: 485px;

text-align: center;

color: rgba(255, 255, 255, 0.87);
}

.modal-header1 {
  padding-top: 40px;
}

.section1 {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 300px;
}



.interactiveDashboardContainer {
  width: 70%;
  display: block!important;
}

.scribblesImg {
  display: block;
}

.curiousLargeBtn {
  display: block;
}

.curiousMiniBtn {
  display: none;
}

.mainpicture {
  position: relative!important;
}

@media (min-width: 1620px) { 

  .interactiveDashboardContainer {
    width: 70%;
  }

}

@media (min-width: 1220px) and (max-width: 1619px) { 
  
  .interactiveDashboardContainer {
    width: 80%;
  }

}


@media (min-width: 701px) and (max-width: 1219px) { 
  
  .mainpicture {
    position: absolute!important;
    width: 90%;
    opacity: .5;
  }
  .interactiveDashboardContainer {
    width: 100%;
  }

}


@media (max-width: 700px) { 

  .homebody {
    overflow-x: hidden;
  }
  
  .mainpicture {
    position: absolute!important;
    width: 90%;
    opacity: .5;
  }

  .interactiveDashboardContainer {
    display: none!important;
  }


  .scribblesImg {
    display: none;
  }


  .section_2 {
    display: block;
    margin-top: 50px;
    padding: 20px;
  }

  .blobHeaderImg {
    display: none;
  }

  .section1 {
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 150px;
  }

  .mlTitle {
    font-size: 56px;
  }

  .mlSubtitle {
    font-size: 56px;
  }

 

  .curiousLargeBtn {
    display: none;
  }

  .curiousMiniBtn {
    margin-top: 50px;
    display: block;
  }

  .mlDesc {
    width: 100%;
  }

  .experimentalBtn {
    display: none;
  
  }

  .desktopMsg {
    display: block;
    padding: 20px;
    text-align: center;
  }
  
  
}




