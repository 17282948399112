
.dashboardBackground {
    background: linear-gradient(308.88deg, #0B1A42 40.1%, #254262 96.24%);
    height: 1000vh;
}

.dashboardBody {
    padding-left: 190px;
    padding-top: 170px;
    padding-right: 142px;
}

.dashboardHeader {
    width: 100%;
    height: 73px;
    position: absolute;
    padding: 29px;
    padding-left: 150px;
    top: 70px;
    border-bottom-width: 0.05px;
    border-bottom-style: solid;
    border-bottom-color: #6C829E;
    background: linear-gradient(90.48deg, rgba(252, 252, 252, 0.06) 49.59%, rgba(252, 252, 252, 0) 96.09%);
}

.metricDateTitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    float: right;
    letter-spacing: 0.145em;
    color: #FFFFFF;
}

.filterDateBtn {
    border: 1px solid #d5e6fd!important;
    box-sizing: border-box;
    margin-top: 4px;
    background: none!important;
    border-radius: 23px!important;
    font-style: normal;
    font-weight: normal;
    height: 35px;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.12em;

    color: #FFFFFF!important;

}

.dashboardInputText {
    background: none!important;
    outline: none!important;
    margin-top: -9px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 15px;
    height: 37px;
    padding-left: 20px;
    padding-bottom: 3px;
    width: 486px;
    line-height: 17px;
    border-radius: 5px;
    margin-left: 21px;
    color: rgba(255, 255, 255, 0.7);

}

.dashboardInputText::placeholder {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;

}

.dashboardHeaderText {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.155em;
    
    color: #FFFFFF;
}

.dashboardSearchBar {
    background: none;
    border-radius: none;
    width: 344px;
    top: 15px;
    position: absolute;
    margin-bottom: px;
    outline: none;
    padding-left: 40px;
    border-bottom: 0.5px solid #FFF;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.7);
}

.dashboardLinks {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    margin-right: 53px;
    letter-spacing: 0.155em;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5)!important;

    transform: matrix(1, 0, 0, 1, 0, 0);
}

.dashboardLinksActive {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
    letter-spacing: 0.155em;
    transition: 1s ease-in;
    margin-right: 53px;
    color: #FFFFFF!important;
    
    transform: matrix(1, 0, 0, 1, 0, 0);
}

.progress-bar {
  background-color: #8BDCFF!important;
}

.dashboardTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 17px;
    color: white;
}

.dashboardTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 17px;
    color: white;
}

.dashboardHeading {
    margin-top: 23px;
    margin-bottom: 15px;
    margin-left: 27px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.24em;

    color: #FFFFFF;
}

.dashboardSubHeading {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.24em;
    
    color: #FFFFFF
}

.dashboardOverviewBody {
    background: linear-gradient(93.35deg, rgba(255, 255, 255, 0.05) 14%, rgba(255, 255, 255, 0) 84.9%);
    border-radius: 27px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px;
}

.dashboardCardTitle {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.15em;
    
    color: #FFFFFF
}

.diamondIcon {
    position: absolute;
    right: 55px;
    top: 15px;
}

.benchmarkNumber {
    font-size: 13px;
    text-align: right;
    line-height: 14px;
    padding-bottom: 10px;
    margin-right: 10px;
    letter-spacing: 0.15em;
    color: rgba(255, 255, 255, 0.5)
}

.dashboardInnerCard {
    background: rgba(90, 131, 173, 0.3)!important;
    box-shadow: 16px 20px 19px rgba(0, 0, 0, 0.15), 28px 66px 29px rgba(0, 0, 0, 0.15)!important    ;
    border-top-right-radius: 20px!important;
    margin-top: -15px;
    filter: drop-shadow(16px 20px 19px black);
    border-bottom-right-radius: 100px!important;
    border-top-left-radius: 100px!important;
    border-bottom-left-radius: 20px!important;
    width: 241px!important;
    height: 102px;
    padding: 5px!important;
    border: 0.5px solid rgba(255, 202, 250, 0.28)!important;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}