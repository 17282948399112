.mainProjectsBackground {
    height: 170vh;
    background: linear-gradient(308.88deg, #0B1A42 40.1%, #254262 96.24%);
}

.mainProjectRowCards {
    background: rgba(255, 255, 255, 0.16)!important;
    border: 1px solid rgba(202, 214, 238, 0.3)!important;
    box-sizing: border-box!important;
    box-shadow: 0px 10px 22px 2px rgba(0, 0, 0, 0.1)!important;
    border-radius: 10px!important;
    margin-bottom: 25px;
    cursor: pointer;
    height: 61px!important;
}

.mainProjectRowCardsBorder {
    border: 1px solid rgba(255, 255, 255, 0.19);
    box-sizing: border-box;
    padding: 50px;
    border-radius: 22px;
}

.mainProjectSubTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height */
    
    
    color: #FFFFFF;
}

.searchBarLayout {
    padding-left: 30px;
    background: '#16234C';
    border: 'none';
    height: 39px!important;;
    border-radius: 24px!important;;
    outline: 'none';
    width: 639px!important;
    color: #D9D9D9!important;
    font-weight: 300;
    font-size: 15px;
}

.mainProjectsBody {
    padding-left: 150px;
    padding-top: 106px;
    padding-right: 272px;
}

.mainProjectsOverlay {
    padding: 50px;
    background: linear-gradient(93.35deg, rgba(255, 255, 255, 0.05) 14%, rgba(255, 255, 255, 0) 84.9%);
    border-radius: 27px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.mainProjectsTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 17px;
    color: white;
}

.mainProjectsSubHeading {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    margin-right: 53px;
    letter-spacing: 0.155em;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5)!important;

    transform: matrix(1, 0, 0, 1, 0, 0);
}

.mainProjectsSubHeading2 {
    color: white!important;
    font-size: 15px;
    font-weight: 600;
}

.table-responsive {
    height: 600px;
    overflow: visible;
}

@media (max-width: 1600px) { 
    .mainProjectsBody {
        padding-left: 150px;
        padding-top: 106px;
        padding-right: 162px;
    }
 }
