.homeSideBanner {
    position: absolute;
    top: 73px;
    left: 0;
    width: 116px;
    height: 150vh;
    background: linear-gradient(179.91deg, rgba(252, 252, 252, 0.2) 0.08%, rgba(252, 252, 252, 0) 50.92%);
}

.miniMenuController {
    position: absolute;
    width: 87px;
    border-top-right-radius: 40px;
    border-top-left-radius:0px;
    border-bottom-right-radius: 30px;
    height: 438px;
    padding: 30px;
    top: 138px;
    background: linear-gradient(180deg, #1C3457 0%, #090628 100%);
    border: 1px solid rgba(179, 144, 144, 0.26);
    box-shadow: 0px 35px 21px rgba(0, 0, 0, 0.25);
}

.logo {
    top: 20px;
    width: 30px;
    left: 45px;
    position: absolute;
}

.miniMenuControllerDiamond {
    position: absolute;
    bottom: 20px;
    left: 25px;
}
