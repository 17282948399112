
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');
@import "~bootstrap/scss/bootstrap";

.small-about-text {
    font-family: Inter;
    font-style: normal;
    text-align: center!important;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 204% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.145em;

    color: #FFFFFF;
}  

.about-left-blue-gradient {
    position: absolute;
    mix-blend-mode: color-dodge;
    width: 504px;
    height: 233px;
    left: 0px;
    top: 0px;
    background-size: 600%!important;
    animation: gradient 10s infinite!important;
    z-index: 99;

    border-bottom-right-radius: 100px!important;
    background: linear-gradient(114.46deg, #5891FF 18.14%, #5FCEFE 83.51%, #5FFEBB 99.32%);
    border: 1px solid rgba(234, 222, 237, 0.33);
    box-sizing: border-box;
    box-shadow: -11px 23px 30px rgba(0, 0, 0, 0.25), 0px 100px 100px rgba(0, 0, 0, 0.69), 100px 100px 50px rgba(0, 0, 0, 0.25), inset 0px -40px 32px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
}

.about-left-pink-gradient {
    mix-blend-mode: color-dodge;

    border-top-right-radius: 20px;
    border-bottom-right-radius: 204px!important;
    background-size: 600%!important;
    animation: gradient 14s infinite!important;

    position: absolute;
    width: 504px;
    height: 401px;
    left: 395px;
    top: 15px;

    background: linear-gradient(114.46deg, #F97664 18.78%, #FF60A7 42.05%, #9013BC 99.32%);
    border: 1px solid rgba(234, 222, 237, 0.33);
    box-sizing: border-box;
    box-shadow: 0px 100px 100px rgba(0, 0, 0, 0.69), 100px 100px 50px rgba(0, 0, 0, 0.25), inset 0px -40px 40px rgba(0, 0, 0, 0.25);
    border-radius: 16px;

}


.about-text {
    font-family: Inter;
    font-style: normal;
    position: relative;
    z-index: 999999;
    font-weight: normal;
    font-size: 16px;
    line-height: 38px;
    width: 501px;

    /* or 237% */

    display: flex;
    align-items: center;

    color: #FFFFFF;
}



.it-all-got-started-title {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 52px;
    /* or 113% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;

    color: #FFFFFF;
}

.about-left-purple-gradient {
    position: absolute;
    mix-blend-mode: color-dodge;
    background-size: 600%!important;
    animation: gradient 6s infinite!important;
    width: 493px;
    z-index: 9;
    
    border-top-right-radius: 200px!important;
    height: 315px;
    border-bottom-left-radius: 200px!important;
    left: 202px;
    top: 36px;
    background: linear-gradient(114.46deg, #3849DD 18.78%, #3849DD 19.09%, #C25FFE 83.51%, #FE5FB5 99.32%);
    border: 1px solid rgba(234, 222, 237, 0.33);
    box-sizing: border-box;
    box-shadow: 0px 100px 100px rgba(0, 0, 0, .69), 100px 100px 50px rgba(0, 0, 0, .25), inset 0px -40px 40px rgba(0, 0, 0, .25);
    border-radius: 16px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.about-title {

    font-family: Inter;
    text-align: center!important;
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 20px;
    /* or 36% */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    
    color: #FFFFFF;

}

.about-p1 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    /* or 178% */

    display: flex;
    align-items: center;
    text-align: center;
    width: 506px;


    color: #FFFFFF;

}


.main-body-gradient-card {
    background: linear-gradient(114.46deg, #3849DD 18.78%, #3849DD 19.09%, #C25FFE 83.51%, #FE5FB5 99.32%)!important;
    border: 1px solid rgba(234, 222, 237, 0.33)!important;
    box-sizing: border-box;
    box-shadow: 0px 100px 100px rgba(0, 0, 0, 0.69), 100px 100px 50px rgba(0, 0, 0, 0.25), inset 0px -40px 40px rgba(0, 0, 0, 0.25)!important;
    border-radius: 16px!important;
    width: 839px;
    height: 472px;
}

.about-testimonial {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 52px;
    /* or 162% */
    width: 861px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;

    color: #FFFFFF;
}

.black-bg {
    background: black;
}

.full-width-divider {
    margin-left: calc(50% - 50vw);
    width: 100vw;
  }

.about-left-graphic {
    top: -100px;
    position: absolute;
    left: 0px;
}


  @media (max-width: 800px) {

    .about-left-blue-gradient {
        width: 150px;
        mix-blend-mode: color-dodge;
        background-size: 600%;
        animation: gradient 10s infinite;
    }

    .about-testimonial {
        font-family: Inter;
        font-style: normal;
        font-weight: 300;
        margin: 20px;
        font-size: 16px;
        line-height: 2;
        width: 861px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.05em;
        color: #FFFFFF;
    }

    .about-left-purple-gradient {
        position: absolute;
        width: 293px;
        z-index: 9;
        mix-blend-mode: color-dodge;
        border-top-right-radius: 200px !important;
        height: 315px;
        border-bottom-left-radius: 200px !important;
        left: 0px;
        background-size: 600%;
        animation: gradient 10s infinite;
        top: 36px;
    }

    .about-left-pink-gradient {
        width: 330px;
        left: 0px;
        background-size: 600%;
        animation: gradient 10s infinite;
    }

    .about-title {
        margin: 15px;
        font-size: 38px;
        line-height: 1.12;
        margin-bottom: 0px!important;
    }

    .about-p1 {
        margin: 15px;
        margin-top: 26px!important;
    }

    .main-body-gradient-card {
        margin: 30px!important;
    }

    .about-testimonial {
        margin-top: 20px!important;
    }

    .about-text {
        width: 100%;
    }

    .it-all-got-started-title {
        font-size: 32px;
        width: 100%;
        position: relative;
        z-index: 99999;
        margin-top: 60px!important;
    }


  }