
.homebody {
    background: linear-gradient(113.79deg, #2B2F42 -3.5%, #151722 104.34%);
    background-size: cover;
    margin-top: auto;
    width: auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Open Sans' , sans-serif, 'Helvetica Neue', Roboto, Oxygen, Ubuntu, Cantarell;
    overflow-x: hidden;
  }

  .features {
    width: 100%;
  }


  .dropdownmenu {
    border-radius: 30px;
    background-color: black;
    
  }

  .lifecycle {
    width: 50%;
  }

  

  //SECTION 1: INTRODUCTION & BANNER

  .stickymenu {
    display: none;
  }
  .modalmenudropdown {
    display: none;
  }
  .introduction {
    background: linear-gradient(145.97deg, #202230 16.28%, #0C0F1C 77.99%);
    box-shadow:  0px 40px 70px rgba(6, 6, 6, 0.47), inset -40px 4px 40px rgba(0, 0, 0, 0.25), inset 60px 4px 20px rgba(0, 0, 0, 0.25), inset 0px -60px 40px rgba(0, 0, 0, 0.25);
    width: 100%;
    position: relative;
    top: 0;
    padding-top: 10px;
    left: 0;
  }

  .introductionbody h1 {
    font-size: 56px;
    line-height: 109.34%;
  }

  .introductionbody p {
    font-size: 22px;
    color: rgb(186, 191, 206);
    font-weight: 300;
  }

  .introductionbody {
    padding-left: 10rem;
    // padding-top: 150px;
    // padding-bottom: 70px;
  }

//SECTION 2
.section2 p {
  text-align: center;
  font-size: 22px;
  font-weight: 300;
  color: rgb(186, 191, 206);
  margin-top: 10px;
  margin-bottom: -40px;
}

.section2 h1 {
  text-align: center;
  font-size: 43px;
  padding-top: 130px;
}
.dashboardalone {
  display: none;
}

.explorelinks {
  display: none;
  color: #3E86F2;

}

//SECTION 3
.section3 {
  // height: 657px;
  // padding-top: 140px;
  padding-left: 16rem;
  padding-right: 15rem;
  // padding-bottom: 130px;
  // padding-top: 130px;
  position: relative;
  // background:  linear-gradient(180.05deg, #333751 0.04%, #1C1F33 99.96%);
  // box-shadow: inset 0px -100px 100px rgba(22, 22, 24, 0.6)
}
.triangle {
  position: absolute;
  top: 30px;
  right: 0;
  width: 30%;
}
.section3 h1 {
  font-size: 43px;
  padding-top: 30px;
}
.section3 p {
  font-size: 22px;
  margin-bottom: -30px;
  font-weight: 300;
  color: rgb(186, 191, 206);

}

//SECTION 4:: EXPERIMENT WITHOUT THE MESSY WORK
.section4 {
  padding-left: 1.875rem;
  padding-top: 70px;

  padding-right: 1.875rem;
  position: relative;
}

.section4 h1 {
  font-size: 43px;
}

.section4 p {
  font-size: 22px;
  font-weight: 300;
  color: rgb(186, 191, 206);
}


//SECTION 5:: LOOK UNDER THE HOOD && CONNECT TO ANY SOURCE/DESTINATION
.section5 {
  background: linear-gradient(180deg, #1F212E 0%, #0E0F1B 100%);
  box-shadow: inset 40px 0px 50px rgba(0, 0, 0, 0.5), inset -30px 4px 40px rgba(0, 0, 0, 0.25), inset 0px -50px 100px rgba(0, 0, 0, 0.25), inset 0px 70px 100px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: inset 40px 0px 50px rgba(0, 0, 0, 0.5), inset -30px 4px 40px rgba(0, 0, 0, 0.25), inset 0px -50px 100px rgba(0, 0, 0, 0.25), inset 0px 70px 100px rgba(0, 0, 0, 0.25);
  -moz-box-shadow:  inset 40px 0px 50px rgba(0, 0, 0, 0.5), inset -30px 4px 40px rgba(0, 0, 0, 0.25), inset 0px -50px 100px rgba(0, 0, 0, 0.25), inset 0px 70px 100px rgba(0, 0, 0, 0.25);
  // height: 657px;
  padding-top: 140px;
  padding-left: 15rem;
  padding-right: 15rem;
  padding-bottom: 140px;
  position: relative;
}

.section5 h1 {
  font-size: 43px;
}

.section5 p {
  font-size: 22px;
  color: rgb(186, 191, 206);
  font-weight: 300;
}

.cutoffblob {
  width: 20%;
}
.terminalimg {
  display: block;
}
.terminalimgdevicesize {
  display: none;
} 

.connectdatasource {
  display: block;
}

.cube {
  top: 40%;
  width: 20%;
}

.steps {
  width: 100%;
}



@media  (max-width: 1050px) {

  .features {
    width: 60%;
  }
  .achievements {
    display: none;
  }

  .steps {
    width: 80%;
  }

  .introductionbody {
    padding-top: 6rem;
  }


  //SECTION 3
  .section3 {
    // height: 657px;
    // padding-top: 140px;
    padding-left: 2.875rem;
    padding-right: 2.875rem;
    position: relative;
    padding-top: 5rem;

  }

  .section3 h1 {
    font-size: 28px;
    padding-top:5px;
  }

  .section3 p {
    font-size: 16px;
    margin-bottom: 0px;
  }


  //SECTION 4: EXPERIMENT WITHOUT THE MESSY WORK

  .section4 {
    padding-left: 1.875rem;
    // padding-top: 70px;
    padding-top: 0px;
    padding-right: 1.875rem;
    position: relative;
  }

  .lifecycle {
    width: 100%;
  }

.section4 h1 {
font-size: 28px;
font-weight: bold;
}

.section4 p {
font-size: 16px;
font-weight: 300;
}

  //SECTION 5: LOOK UNDER THE HOOD
.section5 {
  padding-left: 1.875rem;
padding-right: 1.875rem;
position: relative;
padding-top: 5rem;
}
.section5 h1 {
  font-size: 28px;
  font-weight: bold;
}

.section5 p {
  font-size: 16px;
  color: rgb(186, 191, 206);
  font-weight: 300;
}

.cutoffblob {
  width: 30%;
}

.cube {
  top: 40%;
  width: 50%;
}

.terminalimg {
  display: none;
}
.terminalimgdevicesize {
  display: block;

} 
// .connectdatasource {
//   display: none;
// }


  .explorelinks {
    display: block;
    font-size: 14px;
    color: #3E86F2;
    font-weight: 600;
  }

  .dashboardalone {
    width: 100%;
    display: block;
  }

  .stickymenu {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 999;
    height: 55px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.25), inset 0px -10px 10px rgba(0, 0, 0, 0.25);
    background: #121111;
    top: 0px;
  }

  .modalmenudropdown {
    position: absolute;
    background: white;
    height: 100%;
    top: 0;
    width: 100%;
    overflow-y: hidden;
    z-index: 999;
  }

  .menu {
    display: none;
  }

  .introductionbanner {
    display: none;
  }

  .introductionbody {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    // padding-top: 150px;
    margin-top: 30px;
    max-width: 90rem;
    margin-right: auto;
    margin-left: auto;
    // padding-bottom: 70px;
  }

  .introduction {
    position: relative;
    width: 100%;
    height: 100%
    
  }

  .introductionbody h1 {
    font-size: 32px;
    line-height: 1.1;
    
  }
  .introductionbody p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
    
  }



  .section2 h1 {
    font-size: 28px;
    text-align: left;
    padding-top: 0px;
    
  }

  .section2 p {
    font-size: 16px;
    text-align: left;
    margin-bottom: 10px;
    
  }

  .section2 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .keytounlockdataimg {
    display: none;
  }


}



  .headerbtn {
    background:  #272A2F;
    box-shadow: inset 0px 25px 30px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    width: 128px;
    height: 44px;
    color: #BCBFC8;
    border: 2px solid #3E86F2;
  }

  .trystackstrdemobtn {
    background: linear-gradient(180deg, rgba(29, 32, 53, 0.5) 28.13%, rgba(0, 0, 0, 0.5) 141.67%);
    box-shadow:  0px 30px 40px #000000;
    border-radius: 40px;
    color: white;
    font-size: 14px;
    width: 190px;
    height: 48px;
    font-weight: 1000;
    background-color: none;
    border: 3px solid #3E86F2;
    border-color: none;
    outline: 'none';
  }



.keytounlockdataimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
}


.orderlist ol li {
  position: relative;
  padding: 0 25px 20px 40px;
  list-style-type: square;
  flex-basis: 25%;
  padding-right: 25px;
}


.order_list ol li {
  content: counter(sec-counter);
  counter-increment: sec-counter;
  width: 32px;
  background: #ccc;
  color: #fff;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  left: 0;
  top: 0;
  position: absolute;
}

.bg-yellow:before {
  background-color: yellow;
  width: 2000px;
}
.bg-red:before {
  background-color: red;
}
.bg-green:before {
  background-color: green;
}
.bg-orange:before {
  background-color: orange;
}
.bg-aqua:before {
  background-color: aqua;
}
ol {
  counter-reset: myOrderedListItemsCounter;
}
ol li {
  list-style-type: none;
  position: relative;
}
ol li:before {
  counter-increment: myOrderedListItemsCounter;
  content: counter(myOrderedListItemsCounter)" ";
  margin-right: 0.5em;
}

.dataworkflowcards {
  box-shadow: 0px 40px 30px 5px rgba(0, 0, 0, 0.5), inset 0px 15px 30px rgba(0, 0, 0, 0.25);
  border: 2px solid #A5A5B8;
  height: 152px;
  border-radius: 20px;
}

//OVERRIDE BOOTSTRAP BTN COLORS
.btn-secondary {
  border-color: none;
  background-color: none;
}


  .introductionbanner{
    background-color: #100F1E;
    width: 100%;
    height: 750px;
  }

  @media  (min-width: 1900px) {
    .section5 {
      padding-left: 20rem;
      padding-right: 20rem;
    }
    .section3 {
      padding-left: 20rem;
      padding-right: 25rem;
    }


  }
