
.alertNotifMenu {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1716) 0%, rgba(16, 7, 66, 0) 77.58%);
    position: absolute;
    top: 0;
    right: 0;
    overflow-y: auto;
    transition: transform .3s cubic-bezier(0, .52, 0, 1);    
    padding: 40px;
    height: 100%;
    width: 327px;
}

.alertNotifTitle {
    text-align: center;
    color: white;
    letter-spacing: 0.205em;;
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 500;
}

.alertNotifHeading {
    text-align: center;
    color: rgba(255, 255, 255, 0.68);
    font-weight: 300;
    font-size: 14px;
}

.alertNotifSubHeading {
    color: white;
    font-weight: 300;
    letter-spacing: 0.045em;
    font-size: 15px;
    margin-bottom: 10px;
}

.alertNotifSubHeading2 {
    color: white;
    font-size: 14px;
    font-weight: 300;
}

.alertNotifCards {
    background: #172548!important;
    width: 263px;
    height: 87px;
    box-shadow: none!important;
    border-radius: 21px!important;
}

.alertNotifCardPadding {
    padding: 0.7rem
}

.alertNotifCardTitle {
    margin-bottom: 0px;
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.alertNotifCardHeading {
    padding: 0.7rem
}

.alertNotifCardSubHeading {
    color: white;
    font-size: 13px;
}

.openMenuIcon {
    position: absolute;
    cursor: pointer;
    top: 50px;
    right: 270px;
}


