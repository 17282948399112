

.breadCrumbsHeader {
    font-style: normal;
    font-weight: 300;
    padding-top: 5px;
    font-size: 15px;
    padding-left: 10px;
    line-height: 21px;
    letter-spacing: 0.04em;

    color: #FFFFFF
}


.orgMenuBlock {
    padding-top: 27px;
    height: 100%;
    padding-right: 10px;
    padding-bottom: 100px;
    padding-left: 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.27);
    
}

.singleSubTab {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 12px;
    /* identical to box height */

    letter-spacing: 0.04em;

    color: #FFFFFF;
}

.display_name_showcase {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 1px;
    line-height: 19px;
    /* identical to box height */


    color: #FFFFFF;

}

.display_email {
    font-weight: normal;
    font-size: 14px;
    margin-top: 0px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.7);
}

.memberLineSeparater {
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-shadow: 14px 11px 4px rgba(0, 0, 0, 0.25);
}

.searchMembersInput {
    background: rgba(255, 255, 255, 0.22);
    border: 1px solid rgba(255, 255, 255, 0.56);
    box-sizing: border-box;
    height: 43px;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    padding-left: 14px;
    outline:none;

    letter-spacing: 0.04em;

    color: rgba(255, 255, 255, 0.66);
    width: 100%;
    box-shadow: 15px 35px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px
}

.membersTable {
    border: 1px solid rgba(255, 255, 255, 0.56);
    box-sizing: border-box;
    filter: drop-shadow(26px 40px 4px rgba(0, 0, 0, 0.15));
    border-radius: 5px;
}

.headerMembersThinBlock {
    background: rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    font-weight: 600;
    cursor: pointer;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    margin-bottom: 0px;
    height: 46px;
    padding: 13px;

    color: #FFFFFF;
    box-shadow: 9px 18px 12px rgba(0, 0, 0, 0.03);
    // border-radius: 5px;
}
.deleteBtn {
    border: 1px solid rgba(255, 255, 255, 0.4)!important;
    box-sizing: border-box;
    background: none!important;
    float: right;
    margin-right: 50px;
    width: 104px!important;
    height: 34px!important;
    border-radius: 6px!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 13px!important;
    line-height: 16px;
    /* identical to box height */

    letter-spacing: 0.04em;

    color: #FFF!important;
}

.memberRole {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.04em;

    color: #FFFFFF;
}

.searchMembersInput::placeholder {
    font-weight: normal;
    font-size: 15px;
    // padding-left: 14px;
    outline:none;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: 0.04em;

    color: rgba(255, 255, 255, 0.66);
}

.inviteNewMembersBlock {
    height: 54px;
    padding: 15px;
    cursor: pointer;
    margin-bottom: 29px;
    padding-left: 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.04em;

    color: #FFFFFF;
    background: rgba(121, 165, 248, 0.1);
    border: 1px dashed rgba(205, 212, 238, 0.39);
    box-sizing: border-box;
    box-shadow: 14px 30px 16px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
}

.highlightTabName {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    transition: 1s ease-in;
    /* identical to box height */
    
    letter-spacing: 0.05em;
    
    color: #FFFFFF;
}

.subTabTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.04em;

    color: #FFFFFF;
}


.subtabBody {
    padding-top: 27px;
    padding-left: 10px;
}

.tabName {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    transition: 1s ease-in;
    cursor: pointer;
    /* identical to box height */

    letter-spacing: 0.05em;

    color: rgba(255, 255, 255, 0.6);
}

.horizontalLineSeparater {
    border: 1px solid rgba(255, 255, 255, 0.37);
    box-shadow: -1px 22px 11px rgba(0, 0, 0, 0.45);
    margin-bottom: 0px!important;
}

.settingsOverlay {
    border-radius: 9px;
    padding-bottom: 100px;
    padding-top: 25px;
    padding-right: 60px;
    padding-left: 25px;
    background: linear-gradient(100.62deg, rgba(255, 255, 255, 0.18) 36.55%, rgba(255, 255, 255, 0) 91.29%);    
}

.titleMenu {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;

    color: #FFFFFF;
}