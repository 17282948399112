
.loadingSpinnerBg {
    background: linear-gradient(-45deg, #254161, #547fca, #4296ce,#0B1A42);
    height: 100vh;
    // border-radius: 30px;
    background-size: 400% 400%;
    transition: 3s ease-in!important;
    background-size: 400% 400%;
    border: 1px solid rgba(255, 255, 255, 0.18);
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.loadingSpinnerBody {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}