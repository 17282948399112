
.credTableTitle {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.155em;
    color: #FFFFFF;
}

.credKeyBtn {
    border: 1px solid #FFFFFF!important;
    box-sizing: border-box!important;
    width: 110px!important;
    height: 40px!important;
    outline: none!important;
    font-style: normal;
    margin-bottom: 10px!important;
    font-weight: 300!important;
    font-size: 15px!important;
    line-height: 18px!important;
    letter-spacing: 0.07em!important;
    color: #FFFFFF!important;
}

.credKeyTableSubTitle {
    font-style: normal;
    font-weight: 300!important;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.155em;
    color: #FFFFFF; 
}

.keyTableCell {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.07em;
    color: #FFFFFF!important;
}