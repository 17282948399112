@import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');
@import "~bootstrap/scss/bootstrap";

/* STICKY_ITEMS  */


$hamburgerSize: 60px;



.bottom_right_groove_contact {
    display: block;
    position: absolute;
    mix-blend-mode: hard-light;
    bottom: 0px;
    right: 0px;
}


.go_back_to_projects {
    background: none!important;
    border: none!important;
    border-radius: 0px!important;
    outline: none!important;
    border-bottom: 1px solid rgba(255, 255, 255, 0)!important;
    font-size: 14px!important;
    padding-bottom: 0px!important;
    transition: all 0.5s;
    color: white!important;
    padding-left: 0px!important;
}

.go_back_to_projects:hover {
    transition: all 0.5s;
    padding-bottom: 0px!important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.851)!important;
}

.read_full_story {
    background: none!important;
    border: 1px solid white!important;
    border-radius: 4px!important;
    font-size: 14px!important;
    transition: all 0.5s!important;
    outline: none!important;
    padding: 8px!important;
    padding-left: 10px!important;
    padding-right: 10px!important;
}

.read_full_story:hover {
    background: #ffffff4a!important;
    border: 1px solid white!important;
    transition: all 0.5s!important;
    color: white!important;
    border-radius: 4px!important;
    outline: none!important;
}

.slide_project_img {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.slide_title {
    color: white;
    margin-top: 10px;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 22px;
    font-weight: 500;
}

.dribbble_design_link {
    text-decoration: underline;
    color: white;
    cursor: pointer;
    float: right;
}

.go_to_site_btn {
    height: 44px!important;
    font-size: 14px!important;
    width: 100%!important;

    outline: none!important;
    background-size: 700% 300%!important;

    background-image: linear-gradient(to right, #ed6ea0, #ec8c69, #b318f7, #3b8efb) !important;
    /* Gives the button a shadow for a neon-like glow */
    // box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.35)!important;

    border: none!important;
    border-radius: 4px!important;

    animation: gradient 10s ease infinite !important;
    -moz-animation: gradient 10s ease infinite !important;
    -o-animation: gradient 10s ease infinite !important;

    font-weight: 400!important;
    border: none!important;
    margin-right: 15px!important;
    letter-spacing: -0.02em!important;

}

.slide_paragraph {
    color: white;
    width: 500px;
    font-size: 14px!important;
    text-align: center;
    word-break: break-word;
}

.kimia-subtitle {
    font-size: 16px;
    margin-top: 9px!important;
    font-weight: 300;
    text-align: center;
    color: white;
}

.button_arrow_labels {
    color: white;
    font-size: 12px!important;
    text-align: center;
}

.read-my-story-btn {
    background: none!important;
    border: 1px solid #ffffff26!important;    
    outline: none!important;
    transition: all 0.5s;
    font-size: 14px!important;
}

.read-my-story-btn:hover {
    background: #ffffff2b !important;
    border: none !important;
    outline: none !important;
    transition: all 0.5s;
    font-size: 14px !important;
}

.right_icon {
    animation: glow 3s infinite alternate-reverse, floating 4s infinite;
}


.slide_1_row_1 {
    justify-content: center;
}

.slide_1_row_btns {
    justify-content: center;
}

.view-my-works-btn {
    height: 44px!important;
    font-size: 14px!important;

    outline: none!important;
    background-size: 700% 300%!important;
    width: 140px!important;

    background-image: linear-gradient(to right, #ed6ea0, #ec8c69, #b318f7, #3b8efb) !important;
    /* Gives the button a shadow for a neon-like glow */
    // box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.35)!important;

    border: none!important;
    border-radius: 4px!important;

    animation: gradient 10s ease infinite !important;
    -moz-animation: gradient 10s ease infinite !important;
    -o-animation: gradient 10s ease infinite !important;

    font-weight: 400!important;
    border: none!important;
    margin-right: 15px!important;
    letter-spacing: -0.02em!important;
}

.view-my-works-btn:hover {
    box-shadow: #000000;
}

.col.slide_1_col_1 {
    padding-left: 250px;
    z-index: 9999;
    margin-top: -100px;
}

.down-grad-icon {
    border-radius: 100%;
}

.right-icon {
    position: absolute;
    top: 50%;
    cursor: pointer;
    right: 60px;
    z-index: 99999!important;
    border-radius: 120%;
   
    transform: translateY(-50%);

}

@keyframes glowing {
    from {
      box-shadow: 0 0 10px -10px #e5aef45d;
    }
    to {
      box-shadow: 0 0 10px 10px #d1aef46c;
    }
  }

.left-icon {
    position: absolute;
    top: 50%;
    z-index: 99999!important;
    cursor: pointer;
    left: 60px;
    width: 60px;
    transform: translateY(-50%);

}


.hamburger {
    $p: &;
    width: $hamburgerSize;
    height: $hamburgerSize;
    border-radius: 50%;
    display: none!important;
    cursor: pointer;
    position: absolute;
    z-index: 9999999!important;
    top: 40px;
    right: 40px;
    // background: white;
    
    &__patty {
      position: absolute;
      top: 40px;
      right: 40px;
      transform: translate(-50%, -50%);
      height: 3px;
      width: 50%;
      background: darken(white, 3);
      transition: all .8s cubic-bezier(0.86, 0, 0.07, 1);
      
      &:nth-child(1) {
        width: 40px;
        margin-left: -40px;
        transform: translate(-50%, calc(-50% - 8px));
      }
      
      &:last-child {
        right: 50px;
        left: 16px;
        margin-left: -40px;
        transform: translate(-15px, calc(-50% + 8px));
      }
    }
    
    &.active {
      
      #{$p}__patty {
        background: #FFE26F;
        
        &:nth-child(1) {
            left: 20px;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        
        &:nth-child(2) {
            width: 40px;
            left: 16px;
            transform: translate(-15px, -2px) rotate(-45deg);
        }
        
        &:nth-child(3) {
           transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }

.lets_collaborate {
    font-style: normal;
    font-weight: 274;
    width: 650px;
    font-size: 46px;
    line-height: 120.34%;
    /* or 55px */

    text-align: left;
    letter-spacing: 0.015em;

    color: #FFFFFF;
}

.resume_link {
    font-style: italic;
    cursor: pointer;
    font-weight: 276;
    font-size: 16px;
    line-height: 120.34%;
    margin-top: 20px!important;
    /* identical to box height, or 17px */

    text-align: left;
    letter-spacing: 0.005em;

    color: #FFFFFF;
}

.title_links_contact {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120.34%;
    margin-bottom: 9px!important;
    /* or 19px */

    text-align: left;

    color: #FFFFFF;
}

.subtitle_links_contact {
    font-style: normal;
    font-weight: 274;
    cursor: pointer;
    font-size: 14px;
    line-height: 120.34%;
    /* identical to box height, or 17px */

    text-align: center;

    color: rgba(255, 255, 255, 0.67);
}


  

.wrapper_group_sliders {
    position: absolute;
    width: 100%;
    cursor: pointer;
    bottom: 70px;
}

.slider-stick {
    border: 2px solid #FFFFFF;
    width: 23px;
    margin-right: 5px;
    transition: 2s ease;
}

.slider-stick:hover {
    border: 2px solid #FFFFFF;
    width: 77px;
    margin-right: 5px;
    transition: 2s ease;
}

.slider_expand {
    width: 76px;
    margin-right: 5px;
    transition: 4s ease;
}

.slide_1_floating_hexagon {
    position: absolute;
    z-index: 999;
    right: 218px;
    top: 156px;
    animation: floating 11s ease infinite, fadein 6s ease !important;
}

.slide_1_floating_pink_triangle {
    position: absolute;
    z-index: 999;
    right: 598px;
    top: 145.5px;
    animation: floating 9s ease infinite, fadein 6s ease!important;
}

.slide_1_pearl_img {
    position: absolute;
    z-index: 999;
    right: 688px;
    top: 96px;
    animation: floating 10s ease infinite, fadein 6s ease !important;
}

.slide_1_glowy_rectangle {
    position: absolute;
    right: 400px;
    top: 163px;
    animation: floating 10s ease infinite, fadein 6s ease !important;
}

.slide_1_purple_ball {
    position: absolute;
    z-index: 999;
    right: 888px;
    top: 96px;
    // mix-blend-mode: hard-light;
    animation: floating 5s ease infinite, fadein 6s ease !important;
}

.slide_1_tl_animated_groove_1 {
    width: 394px;
    height: 507px;
    z-index: 9999;
    transform: rotate(450);
    background-size: 700% 300% !important;
    background: linear-gradient(
194.51deg, #FFE3F4 16.37%, #FF8BFA 35.07%, #FFD4AD 73.38%);
    -webkit-clip-path: polygon(18% 0, 88% 0, 91% 84%, 0% 100%);
    clip-path: polygon(18% 0, 88% 0, 91% 84%, 0% 100%);
    opacity: .3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .25), -28px -5px 35px black, 49px 31px 15px rgba(0, 0, 0, .25), inset -4px -15px 17px rgba(0, 0, 0, .25);
    animation: gradient 10s ease infinite, slide_1_tl_animated_groove_1 50s ease infinite !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;
    position: absolute;
    top: -100px;
    left: 100px;
}

@keyframes slide_1_tl_animated_groove_1 {
    0% {
        clip-path: polygon(18% 0, 88% 0, 91% 84%, 0% 100%);
    }
    10% {
        clip-path: polygon(0 55%, 97% 0, 100% 45%, 0% 100%);
    }
    20% {
        clip-path: polygon(0 4%, 97% 0, 100% 45%, 0% 100%);
    }
    30% {
        clip-path: polygon(68% 10%, 100% 48%, 63% 100%, 34% 54%);
    }
    40% {
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
    }
    50% {
        clip-path: polygon(100% 0, 37% 100%, 0 100%, 60% 0);
    }
    60% {
        clip-path: polygon(100% 82%, 71% 100%, 0 13%, 33% 0);
    }
    70% {
        clip-path: polygon(78% 100%, 28% 100%, 31% 0, 78% 0);
    }
    80% {
        clip-path: polygon(79% 73%, 33% 75%, 34% 32%, 78% 28%);
    }
    90% {
        clip-path: polygon(79% 73%, 74% 98%, 0 31%, 41% 0);
    }
    100% {
        clip-path: polygon(18% 0, 88% 0, 91% 84%, 0% 100%);
    }
}


.slide_1_tl_animated_groove_2 {
    width: 294px;
    height: 407px;

    background-size: 700% 100%!important;
    background: linear-gradient(194.51deg, #FFE3F4 16.37%, #FF8BFA 35.07%, #FFD4AD 73.38%);    
    clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    opacity: .5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -28px -5px 35px rgb(0, 0, 0), 49px 31px 15px rgba(0, 0, 0, 0.25), inset -4px -15px 17px rgba(0, 0, 0, 0.25);
    
    animation: gradient 10s ease infinite, slide_1_tl_animated_groove_2 45s ease infinite !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;

    position: absolute;
    top: -100px;
    left: 0px;
}

@keyframes slide_1_tl_animated_groove_2 {
    0% {
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    }
    10% {
        clip-path: polygon(29% 2%, 92% 0, 59% 100%, 0% 100%);
    }
    20% {
        clip-path: polygon(36% 0, 75% 0, 100% 49%, 0 51%);
    }
    30% {
        clip-path: polygon(36% 0, 75% 0, 72% 95%, 1% 73%);
    }
    40% {
        clip-path: polygon(36% 0, 83% 44%, 72% 95%, 1% 73%);
    }
    50% {
        clip-path: polygon(31% 0, 100% 0, 73% 95%, 0 94%);
    }
    60% {
        clip-path: polygon(0 4%, 97% 0, 100% 45%, 0% 100%);
    }
    70% {
        clip-path: polygon(78% 100%, 28% 100%, 31% 0, 78% 0);
    }
    80% {
        clip-path: polygon(31% 16%, 100% 10%, 98% 11%, 0 78%);
    }
    90% {
        clip-path: polygon(31% 16%, 100% 34%, 44% 89%, 0 51%);
    }
    100% {
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    }
}

.slide_1_tl_animated_groove_3 {
    width: 594px;
    height: 307px;

    background-size: 700% 100%!important;
    background: linear-gradient(194.51deg, #FFE3F4 16.37%, #FF8BFA 35.07%, #FFD4AD 73.38%);    
    clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    opacity: .5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -28px -5px 35px rgb(0, 0, 0), 49px 31px 15px rgba(0, 0, 0, 0.25), inset -4px -15px 17px rgba(0, 0, 0, 0.25);
    
    animation: gradient 10s ease infinite, slide_1_tl_animated_groove_3 40s ease infinite !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;

    position: absolute;
    top: -100px;
    left: 40px;
}



@keyframes slide_1_tl_animated_groove_3 {
    0% {
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    }
    10% {
        clip-path: polygon(78% 100%, 28% 100%, 31% 0, 78% 0);
    }
    20% {
        clip-path: polygon(0 4%, 97% 0, 100% 45%, 0% 100%);
    }
    30% {
        clip-path: polygon(31% 16%, 100% 10%, 98% 11%, 0 78%);
    }
    40% {
        clip-path: polygon(18% 0, 88% 0, 91% 84%, 0% 100%);
    }
    50% {
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    }
    60% {
        clip-path: polygon(68% 10%, 100% 48%, 63% 100%, 34% 54%);
    }
    70% {
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    }
    80% {
        clip-path: polygon(31% 16%, 100% 10%, 98% 11%, 0 78%);
    }
    90% {
        clip-path: polygon(0 4%, 97% 0, 100% 45%, 0% 100%);
    }
    100% {
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    }
}

.slide_1_tl_animated_groove_4 {
    width: 494px;
    height: 557px;
    transform: rotate(100);
    background-size: 700% 100% !important;
    background: linear-gradient(
359.49deg, rgba(183, 255, 220, 0.81) 12.98%, rgba(204, 120, 255, 0.81) 46.11%, rgba(148, 121, 255, 0.81) 70.36%);
    opacity: .2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .25), -28px -5px 35px black, 49px 31px 15px rgba(0, 0, 0, .25), inset -4px -15px 17px rgba(0, 0, 0, .25);
    -webkit-clip-path: polygon(32% 38%, 100% 39%, 75% 100%, 0% 100%);
    clip-path: polygon(32% 38%, 100% 39%, 75% 100%, 0% 100%);
    animation: gradient 10s ease infinite, slide_1_tl_animated_groove_4 40s ease infinite !important;
    -moz-animation: gradient 10s ease infinite, slide_1_tl_animated_groove_4 40s ease infinite !important;
    -o-animation: gradient 10s ease infinite, slide_1_tl_animated_groove_4 40s ease infinite !important;
    position: absolute;
    top: -100px;
}

@keyframes slide_1_tl_animated_groove_4 {
    0% {
        clip-path: polygon(32% 38%, 100% 39%, 75% 100%, 0% 100%);
    }
    25% {
        clip-path: polygon(78% 100%, 28% 100%, 31% 0, 78% 0);
    }
    50% {
        clip-path: polygon(18% 0, 88% 0, 91% 84%, 0% 100%);
    }
    75% {
        clip-path: polygon(51% 49%, 50% 50%, 100% 100%, 36% 100%);
    }
    100% {
        clip-path: polygon(32% 38%, 100% 39%, 75% 100%, 0% 100%);
    }
}

.slide_1_tl_animated_groove_5 {
    width: 694px;
    height: 807px;

    background-size: 700% 100%!important;
    background: linear-gradient(359.49deg, rgba(183, 255, 220, 0.81) 12.98%, rgba(204, 120, 255, 0.81) 46.11%, rgba(148, 121, 255, 0.81) 70.36%);
    opacity: .2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -28px -5px 35px rgb(0, 0, 0), 49px 31px 15px rgba(0, 0, 0, 0.25), inset -4px -15px 17px rgba(0, 0, 0, 0.25);
    clip-path: polygon(32% 38%, 100% 39%, 75% 100%, 0% 100%);

    animation: gradient 10s ease infinite, slide_1_tl_animated_groove_5 40s ease infinite !important;
    -moz-animation: gradient 10s ease infinite, slide_1_tl_animated_groove_5 40s ease infinite !important;
    -o-animation: gradient 10s ease infinite, slide_1_tl_animated_groove_5 40s ease infinite !important;

    position: absolute;
    top: -100px;
    left: 0px;
}

@keyframes slide_1_tl_animated_groove_5 {
    0% {
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    }
    25% {
        clip-path: polygon(78% 100%, 28% 100%, 31% 0, 78% 0);
    }
    50% {
        clip-path: polygon(36% 0, 75% 0, 72% 95%, 1% 73%);
    }
    75% {
        clip-path: polygon(0 55%, 97% 0, 100% 45%, 0% 100%);
    }
    100% {
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    }
}

.slide_1_tl_animated_groove_5 {
    width: 294px;
    height: 307px;
    transform: rotate(450);
    background-size: 700% 300% !important;
    background: linear-gradient(359.49deg, rgba(183, 255, 220, 0.81) 12.98%, rgba(204, 120, 255, 0.81) 46.11%, rgba(148, 121, 255, 0.81) 70.36%);
    opacity: .6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .25), -28px -5px 35px black, 49px 31px 15px rgba(0, 0, 0, .25), inset -4px -15px 17px rgba(0, 0, 0, .25);
    -webkit-clip-path: polygon(32% 38%, 100% 39%, 75% 100%, 0% 100%);
    clip-path: polygon(32% 38%, 100% 39%, 75% 100%, 0% 100%);
    animation: gradient 10s ease infinite, slide_1_tl_animated_groove_6 40s ease infinite !important;
    -moz-animation: gradient 10s ease infinite, slide_1_tl_animated_groove_6 40s ease infinite !important;
    -o-animation: gradient 10s ease infinite, slide_1_tl_animated_groove_6 40s ease infinite !important;
    position: absolute;
    top: -100px;
    left: 0px;
}

@keyframes slide_1_tl_animated_groove_6 {
    0% {
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    }
    25% {
        clip-path: polygon(78% 100%, 28% 100%, 31% 0, 78% 0);
    }
    50% {
        clip-path: polygon(36% 0, 75% 0, 72% 95%, 1% 73%);
    }
    75% {
        clip-path: polygon(0 55%, 97% 0, 100% 45%, 0% 100%);
    }
    100% {
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
    }
}

.castle {
    transition: 2s ease;
    width: 1200px;
    top: -20px;
    position: relative;
    z-index: 0;
}


.slide_1_blue_diamond {
    position: absolute;
    z-index: 999;
    mix-blend-mode: hard-light;
    right: 258px;
    top: 6px;
    animation: floating 6s ease infinite, fadein 6s ease !important;
}

.slide_1_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
    // position: absolute;
    top: 50%!important;
    transform: translateY(-50%)!important;
    position:absolute;
    top:200px;bottom:0; /* Aligns Vertically - Remove for Horizontal Only */
    left:0;right:0; /* Aligns Horizontally - Remove for Vertical Only  */
   
    /* Prevent div from overflowing main window */
    max-width:100%;
    max-height:100%;
}


.hi_i_am {
    font-style: normal;
    font-weight: normal;
    font-size: 72px;
    line-height: 116.34%;
    letter-spacing: -0.07em;
    
    color: #FFFFFF;    
}

.kimia_txt {
    font-style: normal;
    font-weight: 590;
    font-size: 72px;
    line-height: 86px;
    letter-spacing: -0.035em;

    display: inline!important;

    background: linear-gradient(91.45deg, #FF9F93 1.8%, #FD8BFF 47.45%, #AD6CFF 92.53%);
    background-size: 700% 100%;

    animation: gradient 4s ease infinite !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.kimia_logo {
    position: absolute;
    width: 74px;
    cursor: pointer;
    z-index: 99999;
    height: 39px;
    transition: all 2s;
    left: 55px;
    top: 34px;
}


.slide_2_floating_hexagon {
    position: absolute;
    right: 150px;
    bottom: 100px;
    z-index: 999;
}

.home_bg {
    background: black;
    height: 100vh;
    transition: all 3s;
}


.home_bg_foreground {
    background: linear-gradient(180deg, #080C1E 26.73%, #080C1E 73.07%, #030653 89.98%, #276468 102.02%);
    height: 100vh;
    // position: relative;
    // transform: translate3d(0px, 0px, 0px);
    width: 100%;
    transition: all 3s ease;
    // animation: fadeoutBg 2s ease;
}


@keyframes fadeoutBg {
    from { background: #080C1E; } /** from color **/
    to { background: linear-gradient(180deg, #000000 26.73%, #000000 73.07%, #000000 89.98%, #000000 102.02%); } /** to color **/
}

.white-line-divider {
    position: absolute;
    display: none!important;
    top: 20%;
    left: 50px;
    background: #c4c4c4;
    border-radius: 10px;
    width: 4px;
    bottom: 50%;
    height: 160px;
}

.opened-menu {
    position: absolute;
    width: 134px;
    height: 197px;
    right: 170px;
    top: 50px;

    border-left: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    box-sizing: border-box;
    padding: 30px;
}

.opened-menu-text {
    font-family: Roboto;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 19px;
    font-size: 15px;
    transition: all 4s ease;
    line-height: 16px;

    color: #FFFFFF;
}

.opened-menu-text:hover {
    font-family: Roboto;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 19px;
    transition: all 4s ease;
    font-size: 15px;
    line-height: 16px;
    text-decoration: underline;

    color: #FFFFFF;
}

.maximize-mini-window {
    transition: all 3s;
    position: relative;
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    animation: maxWindow 0.5s ease!important;
}

@keyframes maxWindow {
    0% {
        -webkit-transform:scale(0.7);
        transform: scale(0.7);
    }
    50% {
        -webkit-transform: scale(0.7) ;
        transform: scale(0.7);
    }
    100% {
        -webkit-transform: translate3d(0px, 0px, 0px) scale(1);
        transform: translate3d(0px, 0px, 0px) scale(1);
    }

}

@-webkit-keyframes maxWindow {
    0% {
        -webkit-transform:rotate3d(5, 1, 1, 54deg) scale(0.7);
        transform: rotate3d(5, 1, 1, 54deg) scale(0.7);
    }
    50% {
        -webkit-transform: scale(0.7) rotate3d(52, 100, 0, 39deg);
        transform: scale(0.7) rotate3d(52, 100, 0, 39deg);
    }
    100% {
        -webkit-transform: translate3d(0px, 0px, 0px) scale(1);
        transform: translate3d(0px, 0px, 0px) scale(1);
    }

}





.shrink-mini-window {
    background: black;
    transition: all 3s;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    perspective: 10000;
    animation: shrinkWindow 16s ease infinite alternate!important;

}


@keyframes shrinkWindow {
    0% {
        -webkit-transform: translate3d(0px, 0px, 0px) scale(0.7);
        transform: translate3d(0px, 0px, 0px) scale(0.7);
    }
    25% {
        -webkit-transform: rotate3d(5, 1, 1, 54deg) scale(0.7);
        transform: rotate3d(5, 1, 1, 54deg) scale(0.7);
    }
    50% {
        -webkit-transform: scale(0.7) rotate3d(52, 100, 0, 39deg);
        transform: scale(0.7) rotate3d(52, 100, 0, 39deg);
    }
    75% {
        -webkit-transform: rotate3d(5, 2, 1, 45deg) scale(0.7);
        transform: rotate3d(5, 2, 1, 45deg) scale(0.7);
    }
    100% {
        -webkit-transform: rotate3d(5, 3, 1, 54deg) scale(0.7);
        transform: rotate3d(5, 3, 1, 54deg) scale(0.7);
    }
}

@-webkit-keyframes shrinkWindow {
    0% {
        -webkit-transform: translate3d(0px, 0px, 0px) scale(0.7);
        transform: translate3d(0px, 0px, 0px) scale(0.7);
    }
    25% {
        -webkit-transform: rotate3d(5, 1, 1, 54deg) scale(0.7);
        transform: rotate3d(5, 1, 1, 54deg) scale(0.7);
    }
    50% {
        -webkit-transform: scale(0.7) rotate3d(52, 100, 0, 39deg);
        transform: scale(0.7) rotate3d(52, 100, 0, 39deg);
    }
    75% {
        -webkit-transform: rotate3d(5, 2, 1, 45deg) scale(0.7);
        transform: rotate3d(5, 2, 1, 45deg) scale(0.7);
    }
    100% {
        -webkit-transform: rotate3d(5, 3, 1, 54deg) scale(0.7);
        transform: rotate3d(5, 3, 1, 54deg) scale(0.7);
    }
}

.smaller_container_wrapper {
    width: 100%;
    height: 100vh;
    z-index: 99;
    opacity: 0.2;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 30px;
    overflow-y: scroll;
}

.smaller_container {
    height: 10000vh;
    opacity: 0.2;

    position: relative;
    top: 0px;
    width: 100%;
    overflow: auto;
}

.light-pink-line-divider {
    position: absolute;
    top: 20%;
    left: 50px;
    display: none!important;

    background-size: 700% 200%!important;
    background: rgb(206, 59, 255) !important;

    // border-radius: 10px;
    width: 4px;
    bottom: 50%;
    height: 0px;
}

.sticky_items {
    z-index: 999;
}

.slide_1_grooves {
    position: absolute;
    right: 0px;
    top: 0px;
}

.slide_2_row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
    position:absolute;
    top:0;bottom:0; /* Aligns Vertically - Remove for Horizontal Only */
    left:0;right:0; /* Aligns Horizontally - Remove for Vertical Only  */
   
    /* Prevent div from overflowing main window */
    max-width:100%;
    max-height:100%;
    overflow:auto;
}

.slide_2_grooves {
    position: absolute;
    left: 0px;
    // mix-blend-mode: hard-light;
    top: 0px;
}

.slide_2_glow_stick {
    position: absolute;
    bottom: 160px;
    left: 0px;
    mix-blend-mode: hard-light;

    animation: fadein 10s ease!important;


}

.slide_2_bubble_1 {
    animation: floating 3s ease infinite alternate, fadein 5s ease, 3s infinite alternate shadow!important;
    filter: drop-shadow(22px 31px 21px rgba(0, 0, 0, 0.46));
    position: absolute;
    z-index: 99;
    top: 0px;
    right: 40px;
}

.slide_2_bubble_2 {
    animation: floating 5s ease infinite alternate, fadein 5s ease!important;
    filter: drop-shadow(22px 31px 21px rgba(0, 0, 0, 0.46));

    position: absolute;
    z-index: 99;
    right: 50px;
    top: 500px;
}

.slide_2_bubble_3 {
    animation: floating 7s ease infinite alternate, fadein 5s ease!important;
    filter: drop-shadow(22px 31px 21px rgba(0, 0, 0, 0.46));

    position: absolute;
    z-index: 99;
    right: 100px;
    top: 380px;
}


.slide_2_br_animated_groove_1 {
    width: 294px;
    height: 207px;

    background-size: 600%!important;
    background: linear-gradient(172.43deg, #23FCEF 41.21%, #8D66DF 63.62%, #EC4BE5 84.02%);

    clip-path: polygon(19% 45%, 100% 100%, 100% 100%, 0 100%);
    // mix-blend-mode: hard-light;
    opacity: .6;

    animation: gradient 4s ease infinite, slide_2_br_animated_groove_1 30s ease infinite !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;

    position: absolute;
    bottom: 0px;
    right: 70px;
}

@keyframes slide_2_br_animated_groove_1 {
    10% {
        clip-path: polygon(19% 45%, 100% 100%, 100% 100%, 0 100%);
    }
    20% {
        clip-path: polygon(44% 30%, 100% 100%, 100% 100%, 0 100%);
    }
    30% {
        clip-path: polygon(69% 20%, 100% 100%, 100% 100%, 0 100%);
    }
    40% {
        clip-path: polygon(49% 39%, 100% 100%, 100% 100%, 0 100%);
    }
    50% {
        clip-path: polygon(100% 42%, 100% 100%, 100% 100%, 0 100%);
    }
    60% {
        clip-path: polygon(30% 39%, 100% 100%, 100% 100%, 0 100%);
    }
    70% {
        clip-path: polygon(30% 63%, 100% 100%, 100% 100%, 0 100%);
    }
    80% {
        clip-path: polygon(69% 63%, 100% 100%, 100% 100%, 0 100%);
    }
    90% {
        clip-path: polygon(25% 34%, 100% 100%, 100% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(19% 45%, 100% 100%, 100% 100%, 0 100%);
    }
}

.slide_2_br_animated_groove_2 {
    width: 244px;
    height: 307px;

    background-size: 700% 400%!important;
    background: linear-gradient(188.14deg, rgba(35, 252, 239, 0.81) 14.71%, rgba(43, 240, 238, 0.81) 40.74%, rgba(141, 102, 223, 0.81) 57.35%, rgba(236, 75, 229, 0.81) 95.86%);    opacity: .7;

    clip-path: polygon(0 41%, 100% 30%, 100% 100%, 0% 100%);
    // mix-blend-mode: hard-light;
    opacity: .6;

    animation: gradient 10s ease infinite, slide_2_br_animated_groove_2 30s ease infinite !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;

    position: absolute;
    bottom: 0px;
    right: -30px;
}

@keyframes slide_2_br_animated_groove_2 {
    10% {
        clip-path: polygon(0 41%, 100% 30%, 100% 100%, 0% 100%);
    }
    20% {
        clip-path: polygon(0 70%, 100% 33%, 100% 100%, 0 100%);
    }
    30% {
        clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
    }
    40% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
    50% {
        clip-path: polygon(0 0, 100% 41%, 100% 100%, 0 100%);
    }
    60% {
        clip-path: polygon(0 34%, 100% 41%, 100% 100%, 0 100%);
    }
    70% {
        clip-path: polygon(0 81%, 100% 41%, 100% 100%, 0 100%);
    }
    80% {
        clip-path: polygon(0 81%, 100% 63%, 100% 100%, 0 100%);
    }
    90% {
        clip-path: polygon(0 33%, 100% 63%, 100% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 41%, 100% 30%, 100% 100%, 0% 100%);
    }
}

.slide_2_br_animated_groove_3 {
    width: 144px;
    height: 667px;

    transform: rotate(341deg);
    background-size: 700% 400%!important;
    background: linear-gradient(171.44deg, rgba(255, 142, 250, 0.74) 74.59%, rgba(202, 177, 255, 0.74) 95.21%);    
    // mix-blend-mode: hard-light;

    animation: gradient 10s ease infinite,  slide_2_br_animated_groove_3 30s ease infinite!important;
    -moz-animation: gradient 10s ease infinite, slide_2_br_animated_groove_3 30s ease infinite!important;
    opacity: .6;
    -o-animation: gradient 10s ease infinite,  slide_2_br_animated_groove_3 30s ease infinite!important;;

    position: absolute;
    bottom: 0px;
    right: -30px;
}

@keyframes slide_2_br_animated_groove_3 {
    10% {
        clip-path: polygon(0 41%, 100% 30%, 100% 100%, 0% 100%);
    }
    20% {
        clip-path: polygon(0 40%, 70% 0, 100% 100%, 0 100%);
    }
    30% {
        clip-path: polygon(13% 72%, 70% 0, 100% 100%, 0 100%);
    }
    40% {
        clip-path: polygon(13% 72%, 68% 50%, 100% 100%, 0 100%);
    }
    50% {
        clip-path: polygon(13% 72%, 100% 18%, 100% 100%, 0 100%);
    }
    60% {
        clip-path: polygon(11% 15%, 100% 18%, 100% 100%, 0 100%);
    }
    70% {
        clip-path: polygon(0 0, 100% 18%, 100% 100%, 0 100%);
    }
    80% {
        clip-path: polygon(0 64%, 100% 18%, 100% 100%, 0 100%);
    }
    90% {
        clip-path: polygon(0 64%, 47% 36%, 100% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 41%, 100% 30%, 100% 100%, 0% 100%);
    }
}

.slide_2_bottom_right_curves {
    position: absolute;
    bottom: 0px;
    right: 0px;
    mix-blend-mode: hard-light;
}

.slide_2_white_text {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

    float: right;
    text-align: left; 
    word-break: break-word;
    margin-bottom: 0px;
    width: 470px;

    line-height: 136.34%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.slide_2_gradient_text {
    font-style: normal;
    margin-top: -20px;;
    font-weight: 500;
    text-align: left;
    width: 100%;
    font-weight: 300;
    font-size: 56px;

    line-height: 86px;
    // mix-blend-mode: hard-light;
    letter-spacing: -0.005em;
    mix-blend-mode: normal;

    background: linear-gradient(91.45deg, #FF9F93 1.8%, #FD8BFF 47.45%, #AD6CFF 92.53%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.slide_2_tl_animated_groove_1 {
    width: 144px;
    height: 667px;

    transform: rotate(341deg);
    background-size: 700% 400%!important;
    background: linear-gradient(359.49deg, rgba(183, 255, 220, 0.81) 12.98%, rgba(204, 120, 255, 0.81) 46.11%, rgba(148, 121, 255, 0.81) 70.36%);
    mix-blend-mode: hard-light;
    clip-path: polygon(34% 2%, 100% 26%, 100% 100%, 0 71%);
    opacity: 0.2;

    animation: gradient 10s ease infinite, slide_2_tl_animated_groove_1 30s ease infinite!important;
    -moz-animation: gradient 10s ease infinite, slide_2_tl_animated_groove_1 30s ease infinite!important;
    -o-animation: gradient 10s ease infinite,  slide_2_tl_animated_groove_1 30s ease infinite!important;;

    position: absolute;
    top: 0px;
    left: -30px;
}

@keyframes slide_2_tl_animated_groove_1 {
    0% {
        clip-path: polygon(34% 2%, 100% 26%, 100% 100%, 0 71%);
    }
    25% {
        clip-path: polygon(34% 2%, 100% 26%, 99% 27%, 0 71%);
    }
    50% {
        clip-path: polygon(40% 0, 100% 26%, 56% 99%, 0 71%);
    }
    75% {
        clip-path: polygon(0 29%, 99% 0, 100% 48%, 0 88%);
    }
    100% {
        clip-path: polygon(34% 2%, 100% 26%, 100% 100%, 0 71%); 
    }
}

.slide_2_tl_animated_groove_2 {
    width: 344px;
    height: 667px;

    transform: rotate(341deg);
    background-size: 700% 300%!important;
    background: linear-gradient(167.22deg, rgba(35, 252, 239, 0.81) 17.78%, rgba(141, 102, 223, 0.81) 43.91%, rgba(236, 75, 229, 0.81) 85.25%);
    
    // mix-blend-mode: hard-light;
    clip-path: polygon(1% 0, 100% 9%, 84% 81%, 24% 100%);
    opacity: 0.3;

    animation: gradient 10s ease infinite, slide_2_br_animated_groove_2 30s ease infinite!important;
    -moz-animation: gradient 10s ease infinite, slide_2_br_animated_groove_2 30s ease infinite!important;
    -o-animation: gradient 10s ease infinite,  slide_2_br_animated_groove_2 30s ease infinite!important;;

    position: absolute;
    top: -70px;
    left: -10px;
}

@keyframes slide_2_br_animated_groove_2 {
    0% {
        clip-path: polygon(1% 0, 100% 9%, 84% 81%, 24% 100%);
    }
    25% {
        clip-path: polygon(1% 0, 100% 0, 100% 40%, 23% 58%);
    }
    50% {
        clip-path: polygon(0 81%, 59% 14%, 100% 40%, 66% 100%);
    }
    75% {
        clip-path: polygon(0 81%, 12% 2%, 95% 3%, 100% 100%);
    }
    100% {
        clip-path: polygon(1% 0, 100% 9%, 84% 81%, 24% 100%);
    }
}

.slide_2_tl_animated_groove_3 {
    width: 344px;
    height: 667px;

    transform: rotate(341deg);
    background-size: 700% 400%!important;
    background: linear-gradient(172.43deg, rgba(35, 252, 239, 0.81) 12.83%, rgba(160, 113, 255, 0.81) 41.26%, rgba(236, 75, 229, 0.81) 84.02%);        
    // mix-blend-mode: hard-light;
    clip-path: polygon(32% 40%, 100% 10%, 81% 84%, 24% 100%);
    opacity: 0.6;

    animation: gradient 10s ease infinite, slide_2_br_animated_groove_3 30s ease infinite!important;
    -moz-animation: gradient 10s ease infinite, slide_2_br_animated_groove_3 30s ease infinite!important;
    -o-animation: gradient 10s ease infinite,  slide_2_br_animated_groove_3 30s ease infinite!important;

    position: absolute;
    top: -200px;
    transform: rotate(209deg);
    left: 100px;
}

@keyframes slide_2_br_animated_groove_3 {
    0% {
        clip-path: polygon(32% 40%, 100% 10%, 81% 84%, 24% 100%);
    }
    25% {
        clip-path: polygon(0 90%, 32% 33%, 100% 33%, 61% 85%);
    }
    50% {
        clip-path: polygon(0 51%, 0 0, 100% 0, 61% 85%);
    }
    75% {
        clip-path: polygon(0 90%, 32% 33%, 100% 10%, 90% 85%);
    }
    100% {
        clip-path: polygon(32% 40%, 100% 10%, 81% 84%, 24% 100%);
    }
}

.slide_2_tl_animated_groove_4 {
    width: 344px;
    height: 667px;

    transform: rotate(341deg);
    background-size: 700% 300%!important;
    background: linear-gradient(173.14deg, rgba(255, 164, 111, 0.81) 11.11%, rgba(255, 152, 92, 0.81) 22.58%, rgba(138, 84, 255, 0.81) 50.21%, rgba(197, 90, 255, 0.81) 63.82%, rgba(255, 83, 246, 0.81) 83.84%);    
    // mix-blend-mode: hard-light;
    clip-path: polygon(32% 42%, 100% 10%, 80% 85%, 24% 100%);
    opacity: 0.4;

    animation: gradient 10s ease infinite, slide_2_br_animated_groove_4 30s ease infinite!important;
    -moz-animation: gradient 10s ease infinite, slide_2_br_animated_groove_4 30s ease infinite!important;
    -o-animation: gradient 10s ease infinite,  slide_2_br_animated_groove_4 30s ease infinite!important;;

    position: absolute;
    top: -200px;
    transform: rotate(209deg);
    left: 300px;
}

@keyframes slide_2_br_animated_groove_4 {
    0% {
        clip-path: polygon(32% 40%, 100% 10%, 81% 84%, 24% 100%);
    }
    25% {
        clip-path: polygon(1% 0, 100% 0, 100% 40%, 23% 58%);
    }
    50% {
        clip-path: polygon(0 39%, 100% 0, 100% 71%, 0 97%);
    }
    75% {
        clip-path: polygon(0 27%, 100% 0, 100% 66%, 0 100%);
    }
    100% {
        clip-path: polygon(32% 40%, 100% 10%, 81% 84%, 24% 100%);
    }
}

.slide_2_tl_animated_groove_5 {
    width: 214px;
    height: 267px;

    transform: rotate(341deg);
    background-size: 700% 400%!important;
    background: linear-gradient(173.14deg, rgba(255, 185, 144, 0.81) 11.11%, rgba(254, 152, 95, 0.81) 22.58%, rgba(150, 101, 255, 0.81) 50.21%, rgba(199, 95, 255, 0.81) 63.82%, rgba(255, 79, 246, 0.81) 83.84%);   
    // mix-blend-mode: hard-light;
    clip-path: polygon(34% 3%, 100% 26%, 100% 100%, 0 72%);
    opacity: 0.6;

    animation: gradient 10s ease infinite, slide_2_br_animated_groove_5 30s ease infinite!important;
    -moz-animation: gradient 10s ease infinite, slide_2_br_animated_groove_5 30s ease infinite!important;
    -o-animation: gradient 10s ease infinite,  slide_2_br_animated_groove_5 30s ease infinite!important;

    position: absolute;
    top: 130px;
    transform: rotate(109deg);
    left: 300px;
}

@keyframes slide_2_br_animated_groove_5 {
    0% {
        clip-path: polygon(34% 3%, 100% 26%, 100% 100%, 0 72%);
    }
    25% {
        clip-path: polygon(0 39%, 100% 0, 100% 71%, 0 97%);
    }
    50% {
        clip-path: polygon(36% 13%, 100% 23%, 100% 99%, 2% 66%);
    }
    75% {
        clip-path: polygon(0 27%, 100% 0, 100% 66%, 0 100%);
    }
    100% {
        clip-path: polygon(34% 3%, 100% 26%, 100% 100%, 0 72%);
    }
}


.slide_2_tl_animated_groove_6 {
    width: 184px;
    height: 167px;

    transform: rotate(272deg)!important;
    background-size: 700% 400%!important;
    background: linear-gradient(173.14deg, rgba(255, 168, 118, 0.81) 11.11%, rgba(255, 151, 91, 0.81) 22.58%, rgba(146, 95, 255, 0.81) 50.21%, rgba(199, 95, 255, 0.81) 63.82%, rgba(255, 86, 247, 0.81) 83.84%);    
    // mix-blend-mode: hard-light;
    clip-path: polygon(34% 2%, 100% 26%, 100% 100%, 0 71%);
    opacity: 0.6;

    animation: gradient 10s ease infinite, slide_2_br_animated_groove_6 16s ease infinite!important;
    -moz-animation: gradient 10s ease infinite, slide_2_br_animated_groove_6 16s ease infinite!important;
    -o-animation: gradient 10s ease infinite,  slide_2_br_animated_groove_6 16s ease infinite!important;;

    position: absolute;
    top: 290px;
    transform: rotate(109deg);
    left: 120px;
}

@keyframes slide_2_br_animated_groove_6 {
    0% {
        clip-path: polygon(34% 2%, 100% 26%, 100% 100%, 0 71%);
    }
    25% {
        clip-path: polygon(0 90%, 32% 33%, 100% 10%, 90% 85%);
    }
    50% {
        clip-path: polygon(36% 13%, 100% 23%, 100% 99%, 2% 66%);
    }
    75% {
        clip-path: polygon(0 39%, 100% 0, 100% 71%, 0 97%);
    }
    100% {
        clip-path: polygon(34% 2%, 100% 26%, 100% 100%, 0 71%);
    }
}


.slide_3_row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
    position:absolute;
    top:0;bottom:0; /* Aligns Vertically - Remove for Horizontal Only */
    left:0;right:0; /* Aligns Horizontally - Remove for Vertical Only  */
   
    /* Prevent div from overflowing main window */
    max-width:100%;
    max-height:100%;
    overflow:auto;
}

.slide_2_greenGradient {
    width: 100%;
    height: 426px;
    z-index: 999;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(180.38deg, rgba(9, 8, 16, 0) 28.14%, rgba(35, 26, 255, 0.26) 70.47%, rgba(35, 252, 239, 0.22) 90.61%);
    filter: blur(15px);
}

.slide_3_grooves_left_top {
    position: absolute;
    top: 0px;
    left: 0px;
}


.slide_3_bottom_left {
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.slide_3_pink_diamond {
    position: absolute;
    bottom: 110px;
    float: right;
    filter: drop-shadow(26px 30px 16px rgba(0, 0, 0, 0.46));
}

.slide_3_bottom_right_grooves {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.slide_3_white_text {
    font-style: normal;
    font-weight: 300;
    font-size: 56px;
    line-height: 116.34%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
}

.slide_in {
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
}


.slide_5_project_cards {
    transition: all 3s ease;
}

.slide_5_project_cards:hover {
    transform: translateY(-50px);
    transition: all 3s ease;
    cursor: pointer;
}

.collapsed-menu-icon {
    position: absolute;
    z-index: 999999;

    clip-path: polygon(0 0, 0 25%, 0 51%, 0 73%, 0 74%, 70% 74%, 70% 53%, 0 52%, 0 25%, 100% 25%, 100% 0, 100% 0);
    background: white;
    color: white;

    width: 40px;
    height: 40px;

    transition: all 1.5s ease;

    cursor: pointer;
    right: 100px;
    top: 50px;
}



.closed-menu-icon {
    position: absolute;
    right: 100px;
    top: 50px;
    z-index: 999999;

clip-path: polygon(86% 0, 100% 13%, 65% 48%, 100% 87%, 82% 99%, 48% 58%, 10% 98%, 0 80%, 31% 46%, 0 13%, 16% 0%, 49% 36%);
    background: white;

    

    width: 40px;
    height: 40px;

    color: white;

    cursor: pointer;
    transition: all 1.5s ease;
    animation: morphMenuIcon 2s ease;
}

@keyframes morphMenuIcon {
    0% {
        clip-path: polygon(0 0, 0 25%, 0 51%, 0 73%, 0 74%, 70% 74%, 70% 53%, 0 52%, 0 25%, 100% 25%, 100% 0, 100% 0);
    }
    25% {
        clip-path: polygon(0 0, 0 23%, 0 70%, 0 70%, 80% 70%, 80% 72%, 79% 47%, 0 47%, 0 24%, 88% 100%, 100% 78%, 100% 78%);

    }
    50% {
        clip-path: polygon(0 0, 0 23%, 0 70%, 0 70%, 100% 14%, 100% 14%, 81% 0, 0 47%, 0 24%, 93% 100%, 100% 78%, 100% 78%);

    }
    75% {
        clip-path: polygon(0 0, 0 76%, 14% 100%, 14% 100%, 100% 14%, 100% 14%, 81% 0, 0 78%, 0 28%, 93% 100%, 100% 78%, 100% 78%);
    }
    100% {
        clip-path: polygon(86% 0, 100% 13%, 65% 48%, 100% 87%, 82% 99%, 48% 58%, 10% 98%, 0 80%, 31% 46%, 0 13%, 16% 0%, 49% 36%);
    }
}


.menu-arrows-container {
    display: none!important;
}

.slide_5_br_grooves {
    position: absolute;
    bottom: 0px;
    right: 0px;
    mix-blend-mode: hard-light;
}


@keyframes slide_in {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity:1;
        -webkit-transform: none;
        transform: none;
    }
}

.slide_3_main_img {
    position: relative;
    right: 0px;
    // float: right;
}

.slide_3_foreground {
    clip-path: polygon(0 0, 100% 0, 100% 43%, 0 89%);
    background: #080C1E;
    width: 100%;
    height: 90%;
}

.slide_3_gradient_text {
    font-style: normal;
    margin-top: -10px;;
    font-size: 52px;

    line-height: 70px;
    // mix-blend-mode: hard-light;
    letter-spacing: -0.005em;

    mix-blend-mode: normal;
    transition: 0.5s;

    background: linear-gradient(91.89deg, #23FCB9 1.79%, #589BFF 23.27%, #B378FF 72.39%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.slide_3_pearl_img {
    position: absolute;
    top: 47px;
    display: block!important;
    float: right!important;
    // left: 21px;
    z-index: 999;
    animation: floating 3s ease infinite alternate, fadein 5s ease!important;
    filter: drop-shadow(22px 31px 21px rgba(0, 0, 0, 0.46));
}

.slide_3_glowy_circle {
    position: absolute;
    top: 260px;
    float: right;
    mix-blend-mode: hard-light;
    animation: fadein 10s ease!important;
}

.slide_3_glowy_blue_triangle {
    position: absolute;
    top: 130px;
    // left: 86.52px;
    margin-right: -10px;
    float: right;
    // mix-blend-mode: hard-light;
    animation:  fadein 10s ease!important;
}

.slide_3_glowy_pink_rectangle {
    position: absolute;
    top: 71.9%;
    // left: 86.52px;
    margin-right: -10px;
    float: right;
    // mix-blend-mode: hard-light;
    animation: fadein 10s ease!important;
}


.slide_3_animated_groove {
    width: 294px;
    height: 257px;

    background-size: 700% 400%!important;
    background: linear-gradient(217.01deg, #ff76c8 5.13%, #b67eff 59.81%, #7afff6 100.85%);
    // mix-blend-mode: hard-light;
    opacity: 0.66;

    z-index: 9;

    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    
    animation: gradient 4s ease infinite, moving_shape 30s ease infinite !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;

    position: absolute;
    bottom: 0px;
    right: 0px;
}

.slide_3_tl_animated_groove_1 {
    width: 204px;
    height: 167px;
    z-index: 9;
    mix-blend-mode: normal;

    // background-size: 700% 400%!important;
    background: linear-gradient(224.75deg, #ff65c2 -15.84%, #b379ff 50.92%, #8cfff7 101.04%);
    // mix-blend-mode: hard-light;
    opacity: 0.4;
    box-shadow: -22px -11px 15px rgba(0, 0, 0, 0.25);

    clip-path: polygon(1% 100%, 0 0, 100% 0);

    // animation: gradient 4s ease infinite, moving_shape 25s ease infinite !important;
    // -moz-animation: gradient 10s ease infinite;
    // -o-animation: gradient 10s ease infinite;

    position: absolute;
    top: 0px;
    left: 0px;
}

.info-tooltip-container {
    .label-container {
      font-size: 18px!important;
    }
    label {
      font-size: 18px;
    }
  }

  .MuiTooltip {
    .tooltip {
      font-size: "2em";
      color: "yellow";
      background-color: "red";
    }
  }

.slide_3_tl_animated_groove_2 {
    width: 204px;
    height: 137px;
    z-index: 9;

    background-size: 700% 400%!important;
    background: linear-gradient(236.81deg, #FF8BFA 29.49%, #FFBDA0 59.88%);    
    // mix-blend-mode: hard-light;
    opacity: 0.5;

    clip-path: polygon(25% 0, 100% 3%, 60% 98%, 0 35%);
    box-shadow: -22px -11px 15px rgba(0, 0, 0, 0.25);

    animation: gradient 4s ease infinite, tl_moving_groove_2 25s ease infinite !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;

    position: absolute;
    top: -10px;
    left: 100px;
}

.slide_3_tl_animated_groove_3 {
    width: 204px;
    height: 137px;
    z-index: 9;

    background-size: 700% 400%!important;
    background: linear-gradient(236.81deg, #ff64f7 29.49%, #ffa67f 59.88%);    
    // mix-blend-mode: hard-light;
    opacity: 0.5;
    mix-blend-mode: normal;

    clip-path: polygon(25% 0, 100% 3%, 60% 98%, 0 35%);
    box-shadow: -22px -11px 15px rgba(0, 0, 0, 0.25);

    animation: gradient 4s ease infinite, tl_moving_groove_2 25s ease infinite !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;

    position: absolute;
    top: -10px;
    left: 170px;
}


@keyframes tl_moving_groove_2 {
    0% {
        clip-path: polygon(25% 0, 100% 3%, 60% 98%, 0 35%);
    }
    25% {
        clip-path: polygon(25% 0, 100% 3%, 60% 98%, 0 20%);
    }
    50% {
        clip-path: polygon(25% 0, 100% 3%, 60% 98%, 0 100%);
    }
    75% {
        clip-path: polygon(25% 0, 100% 3%, 100% 55%, 0 100%);
    }
    100% {
        clip-path: polygon(25% 0, 100% 3%, 60% 98%, 0 35%);    
    }
}

.slide_3_br_animated_groove_1 {
    width: 494px;
    height: 357px;
    z-index: 9;

    background-size: 700% 400%!important;
    background: linear-gradient(194.51deg, #ff9bd7 16.37%, #ff95fa 35.07%, #ffbc7e 73.38%);
    // mix-blend-mode: hard-light;
    opacity: 0.2;

    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

    animation: gradient 4s ease infinite, moving_shape 60s ease infinite !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;

    box-shadow: -22px -11px 35px rgba(0, 0, 0, 0.317);

    position: absolute;
    bottom: 0px;
    right: 0px;
}

.slide_3_br_animated_groove_2 {
    width: 294px;
    height: 457px;
    z-index: 9;

    transform: rotate(
136deg);
    background-size: 700% 400% !important;
    background: linear-gradient(
194.51deg, #ff9dd8 16.37%, #ff7ef9 35.07%, #ffc48d 73.38%);
    // mix-blend-mode: hard-light;

    opacity: 0.1;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

    animation: gradient 4s ease infinite, moving_shape 60s ease infinite !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;

    position: absolute;
    bottom: 60px;
    right: 210px;
}

.slide_3_br_animated_groove_3 {
 width: 394px;
    height: 257px;
    z-index: 9;
    transform: rotate(
136deg);

    background-size: 700% 400% !important;
    background: linear-gradient(
217.01deg, #ff76c8 5.13%, #c090ff 59.81%, #92fff8 100.85%);
    // mix-blend-mode: hard-light;
    
    opacity: 0.1;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    z-index: 9999;

    animation: gradient 4s ease infinite, moving_shape 60s ease infinite alternate-reverse !important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;

    position: absolute;
    bottom: 200px;
    right: 120px;
}

.slide_3_bl_animated_groove_1 {
    width: 394px;
    height: 194px;
    z-index: 9;

    opacity: 0.66;
    // transform: rotate(120.43deg);

    position: absolute;
    bottom: 0px;
    left: 80px;
    // mix-blend-mode: hard-light;

    background-size: 700% 400%!important;
    background: linear-gradient(236.81deg, #ff81f9 29.49%, #ff996d 59.88%);     
    clip-path: polygon(51% 19%, 0% 100%, 100% 100%);

    animation: gradient 4s ease infinite, slow_transforming_triangle 10s ease infinite alternate-reverse!important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;
}

.slide_3_bl_animated_groove_3 {
    width: 204px;
    height: 104px;
    z-index: 9;

    opacity: 0.66;
    // transform: rotate(120.43deg);

    position: absolute;
    bottom: 0px;
    left: 380px;
    // mix-blend-mode: hard-light;

    background-size: 700% 400%!important;
    background: linear-gradient(217.01deg, #ff7dcb 5.13%, #c191ff 59.81%, #87fff7 100.85%);
    clip-path: polygon(51% 19%, 0% 100%, 100% 100%);

    animation: gradient 4s ease infinite!important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;
}

.slide_3_bl_animated_groove_2 {
    width: 294px;
    height: 124px;
    z-index: 9;

    opacity: 0.66;
    // transform: rotate(120.43deg);

    position: absolute;
    bottom: 0px;
    left: 0px;

    // mix-blend-mode: hard-light;

    background-size: 700% 400%!important;
    background: linear-gradient(217.01deg, #ff62c0 5.13%, #b37ff9 59.81%, #8afff7 100.85%);
    clip-path: polygon(51% 19%, 0% 100%, 100% 100%);

    animation: gradient 4s ease infinite, slow_transforming_triangle 10s ease infinite alternate-reverse!important;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;
}

@keyframes slow_transforming_triangle {
    0% {
        clip-path: polygon(51% 19%, 0% 100%, 100% 100%);
    }

    30% {
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
    }

    60% {
        clip-path: polygon(32% 0, 0% 100%, 100% 100%);
    }

    100% {
        clip-path: polygon(51% 19%, 0% 100%, 100% 100%);
    }
}





@keyframes moving_shape {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    10% {
        clip-path: polygon(0 0, 100% 0, 89% 48%, 0% 100%);
    }
    20% {
        clip-path: polygon(0 0, 100% 0, 89% 48%, 1% 49%);
    }
    30% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 1% 49%);
    }
    40% {
        clip-path: polygon(0 47%, 100% 0, 100% 100%, 1% 49%);
    }
    50% {
        clip-path: polygon(0 47%, 100% 0, 0 100%, 1% 49%);
    }
    60% {
        clip-path: polygon(0 47%, 100% 100%, 0 100%, 1% 49%);
    }
    70% {
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 64%);
    }
    80% {
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
    }
    90% {
        clip-path: polygon(100% 61%, 100% 100%, 0 100%, 0 0);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}

.slide_3_white_sphere {
    position: relative;
    left: 79.69%;
    right: 9.25%;
    z-index: 999;
    display: block!important;
    top: 400px;
    animation: fadein 5s ease!important;
    // float: right;
    bottom: 22.45%;
}



.slide_4_left_grooves {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
    // position: absolute;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0px;
    color: white;
    /* Prevent div from overflowing main window */
    max-width:100%;
    max-height:100%;
}

.progress_bar_numbers {
    position: relative;
    top: 33px;
    z-index: 999;
    left: 70px;
    cursor: pointer;
    font-size: 16px;
    z-index: 999999;
    transition: all 2s ease;
    font-weight: 400;
    color:#c4c4c4;
}

.transition_num_colors {
    transition: all 2s ease;
    margin-bottom: 12px;
}

.slide_4_top_grooves {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
    // position: absolute;
    position: absolute;
    left: 0;
    top: 0px;
    right: 0; /* Aligns Horizontally - Remove for Vertical Only  */
    color: white;
    /* Prevent div from overflowing main window */
    max-width:100%;
    max-height:100%;
}

.transparent {
    display: none;
}

.opaque {
    display: block;
}

.test {
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}

.floating {  
    animation-name: floating!important;
    animation-duration: 2s;
    animation-iteration-count: infinite!important;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}


.slide_4_container {
    display: flex;
    width: 600px;
    align-items: center;
    margin:auto;
    // position: absolute;
    top: 50%!important;
    transform: translateY(-50%)!important;
    position:absolute;
    bottom:0; /* Aligns Vertically - Remove for Horizontal Only */
    left:0;right:0; /* Aligns Horizontally - Remove for Vertical Only  */
    color: white;
    /* Prevent div from overflowing main window */
    max-width:100%;
    max-height:100%;
}


.slide_4_white_text {
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 120.34%;
    /* or 87px */

    text-align: center;
    letter-spacing: -0.03em;

    color: #FFFF;
}

.slide_4_life_gradient_text {
    font-style: normal;
    // margin-top: -10px;;
    font-weight: 500;
    font-size: 72px;

    line-height: 86px;
    // mix-blend-mode: hard-light;
    letter-spacing: -0.005em;
    text-align: center;
    mix-blend-mode: normal;
    transition: 0.5s;
    background-size: 700% 300%!important;
    background: linear-gradient(91.89deg, #4EFFC8 1.79%, #65DAFF 23.75%, #FF7AE2 58.42%);

    animation: gradient 7s ease infinite!important;
    -moz-animation: gradient 7s ease infinite!important;
    -o-animation: gradient 7s ease infinite!important;
    -webkit-text-fill-color: transparent;
    
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.slide_4_bottom_grooves {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
    // position: absolute;
    position: absolute;
    left: 0px!important;
    right: 0px!important;
    bottom: 0px;
    color: white;
    /* Prevent div from overflowing main window */
    max-width:100%;
    max-height:100%;
}

.slide_4_right_grooves {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
    // position: absolute;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px!important;
    bottom: 0px;
    color: white;
    /* Prevent div from overflowing main window */
    max-width:100%;
    max-height:100%;
}

.slide_5_bl_grooves {
    position: absolute;
    bottom: 0px;
    left: 0px;
    transition: all 2s ease;
}

.slide_5_white_text {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 120.34%;
    /* or 30px */

    text-align: center;
    letter-spacing: -0.04em;
    margin-bottom: -1px;

    color: #FFFFFF;
}

.slide_5_gradient_text {
    font-style: normal;
    font-weight: 590;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.065em;

    transition: 0.5s;
    background-size: 700% 200%!important;
    background: linear-gradient(91.89deg, #4EFFC8 1.79%, #65C8FF 23.27%, #EE86FF 72.39%);

    animation: gradient 7s ease infinite!important;
    -moz-animation: gradient 7s ease infinite!important;
    -o-animation: gradient 7s ease infinite!important;
    -webkit-text-fill-color: transparent;
    
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    mix-blend-mode: normal;
}

.slide_5_small_pearl {
    position: absolute;
    bottom: 220px;
    left: 50px;
    animation: fadein 5s ease, floating 5s ease infinite!important;
}

.slide_5_large_pearl {
    position: absolute;
    bottom: 100px;
    left: 160px;
    animation: fadein 5s ease, floating 5s ease infinite!important;
}

.slide_5_container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999;
    width: 100%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}




@keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(0, 25px); }
    to   { transform: translate(0, -0px); }    
}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
   }


// .row {
//     margin: 0 auto!important;
// }

.slide_1_row_1 {
    margin: 0 auto!important;
}

.wrapper_group_sliders_row  {
    margin: 0 auto!important;
    justify-content: center;
}

.scale_window {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    // position: absolute;
    // bottom: -60px;
    transition: all 0.5s;
}



.carousel .slider.animated {
    // border: 1px solid black!important;
    // animation: none!important;
}

.carousel .carousel-status {
    display: none;
}

.carousel .slide{
    background: rgba(0, 0, 0, 0)!important;
    border: none!important;
    outline: black!important;
}

.slide_5_bl_grooves {
    width: auto;
}


.mobile-project-carousel {
    display: none;
}

.desktop-project-slideshow {
    display: block;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    background: white!important;
}

.carousel .control-dots .dot {
    border: 1px solid white!important;
    opacity: .5;
    background: none!important;
}

.regular_size {
    // position: absolute;
    // bottom: 0px;
    // top: 0px;
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    transition: all 0.5s ease;
}

@keyframes scaleWindow {
    0% {
        -webkit-transform:  scale(0.6);
        transform: scale(0.6);
    }
    50% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }
    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }

}

@-webkit-keyframes scaleWindow {
    0% {
        -webkit-transform:  scale(0.6);
        transform: scale(0.6);
    }
    50% {
        -webkit-transform: scale(0.6);
        transform: scale(0.6);
    }
    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }

}


.menu_tabs {
    display: block!important;
    position: absolute;
    top: 50px;
    z-index: 9999!important;
    right: 65px;
}

.menu_tab.items {
    color: white!important;
    cursor: pointer;
    margin-right: 12px;
    transition: 0.5s all;
    font-size: 14px;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
}

.menu_tab.items.name {
    padding-bottom: 7px;
    transition: 0.5s all;
    border-bottom-width: 80%;
    border-bottom: 2px solid white;
}

.menu_tab.items:hover {
    padding-bottom: 7px;
    transition: 0.5s all;
    border-bottom-width: 80%;
    border-bottom: 2px solid white;
}

.project_cards_row {
    justify-content: center;
}

.note_site_designed_and_code {
    margin-top: 20px!important;
    font-size: 12px;
    text-align: center;
    color: #595d78;
    opacity: 1;
}


@media (min-width: 0px) and (max-width: 700.98px) {

    .slide_project_img {
        display: none!important;
    }

    .slide_project_column_padding {
        padding-left: 30px!important;
    }

}

@media (min-width: 1100px) and (min-height: 500px) and (max-height: 700px) {
        .castle {
            transition: 2s ease;
            width: 800px;
            top: -61px;
            position: relative;
            z-index: 0;
        }

        .slide_1_pearl_img {
            position: absolute;
            z-index: 999;
            right: 688px;
            top: 6px;
            animation: floating 10s ease infinite, fadein 6s ease !important;
        }

        .slide_1_glowy_rectangle {
            display: none;
        }

        .slide_1_floating_hexagon {
            position: absolute;
            z-index: 99999;
            right: 218px;
            top: 16px;
            animation: floating 11s ease infinite, fadein 6s ease !important;
        }

        .slide_1_purple_ball {
            display: none;
        }
}

@media (min-width: 1100px) and (min-height: 701px) and (max-height: 850px) {
    .castle {
        transition: 2s ease;
        width: 920px;
        top: -40px;
        position: relative;
        z-index: 0;
    }

    .slide_1_floating_hexagon {
        position: absolute;
        z-index: 999;
        right: 218px;
        top: 16px;
        animation: floating 11s ease infinite, fadein 6s ease !important;
    }
    
    .slide_1_glowy_rectangle {
        position: absolute;
        right: 400px;
        top: 13px;
        z-index: 999;
        animation: floating 10s ease infinite, fadein 6s ease !important;
    }

    .slide_1_purple_ball {
        position: absolute;
        z-index: 999;
        right: 589px;
        top: 96px;
        animation: floating 5s ease infinite, fadein 6s ease !important;
    }

}

@media (min-width: 1100px) and (min-height: 701px) and (max-height: 850px) {

}

@media (min-width: 0px) and (max-width: 500px) {
    .slide_paragraph.container {
        height: 260px;
        overflow-y: auto;
    }

    .slide_1_picture_row {
        display: none!important;
    }
}

@media (min-width: 0px) and (max-width: 1220.98px) {

    .note_site_designed_and_code {
        text-align: left!important;
        padding-left: 14px;
        padding-right: 65px;
    }

    .slide_1_tl_animated_groove_2 {
        width: 294px;
        height: 407px;
    
        background-size: 700% 100%!important;
        background: linear-gradient(194.51deg, #FFE3F4 16.37%, #FF8BFA 35.07%, #FFD4AD 73.38%);    
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
        opacity: .5;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -28px -5px 35px rgb(0, 0, 0), 49px 31px 15px rgba(0, 0, 0, 0.25), inset -4px -15px 17px rgba(0, 0, 0, 0.25);
        
        animation: gradient 10s ease infinite, slide_1_tl_animated_groove_2 45s ease infinite !important;
        -moz-animation: gradient 10s ease infinite;
        -o-animation: gradient 10s ease infinite;
    
        position: absolute;
        top: -100px;
        left: 0px;
    }

    .slide_1_tl_animated_groove_1 {
        width: 194px;
        height: 207px;
        z-index: 9999;
        transform: rotate(450);
        background-size: 700% 300% !important;
        background: linear-gradient(
    194.51deg, #FFE3F4 16.37%, #FF8BFA 35.07%, #FFD4AD 73.38%);
        -webkit-clip-path: polygon(18% 0, 88% 0, 91% 84%, 0% 100%);
        clip-path: polygon(18% 0, 88% 0, 91% 84%, 0% 100%);
        opacity: .3;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, .25), -28px -5px 35px black, 49px 31px 15px rgba(0, 0, 0, .25), inset -4px -15px 17px rgba(0, 0, 0, .25);
        animation: gradient 10s ease infinite, slide_1_tl_animated_groove_1 50s ease infinite !important;
        -moz-animation: gradient 10s ease infinite;
        -o-animation: gradient 10s ease infinite;
        position: absolute;
        top: -100px;
    }

    .slide_1_tl_animated_groove_3 {
        width: 294px;
        height: 307px;
    
        background-size: 700% 100%!important;
        background: linear-gradient(194.51deg, #FFE3F4 16.37%, #FF8BFA 35.07%, #FFD4AD 73.38%);    
        clip-path: polygon(25% 29%, 75% 48%, 59% 100%, 0% 100%);
        opacity: .5;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -28px -5px 35px rgb(0, 0, 0), 49px 31px 15px rgba(0, 0, 0, 0.25), inset -4px -15px 17px rgba(0, 0, 0, 0.25);
        
        animation: gradient 10s ease infinite, slide_1_tl_animated_groove_3 40s ease infinite !important;
        -moz-animation: gradient 10s ease infinite;
        -o-animation: gradient 10s ease infinite;
    
        position: absolute;
        top: -100px;
        left: -40px;
    }    

    .slide_1_tl_animated_groove_4 {
        width: 194px;
        height: 507px;
        transform: rotate(100);
        background-size: 700% 100% !important;
        background: linear-gradient(
    359.49deg, rgba(183, 255, 220, 0.81) 12.98%, rgba(204, 120, 255, 0.81) 46.11%, rgba(148, 121, 255, 0.81) 70.36%);
        opacity: .2;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, .25), -28px -5px 35px black, 49px 31px 15px rgba(0, 0, 0, .25), inset -4px -15px 17px rgba(0, 0, 0, .25);
        -webkit-clip-path: polygon(32% 38%, 100% 39%, 75% 100%, 0% 100%);
        clip-path: polygon(32% 38%, 100% 39%, 75% 100%, 0% 100%);
        animation: gradient 10s ease infinite, slide_1_tl_animated_groove_4 40s ease infinite !important;
        -moz-animation: gradient 10s ease infinite, slide_1_tl_animated_groove_4 40s ease infinite !important;
        -o-animation: gradient 10s ease infinite, slide_1_tl_animated_groove_4 40s ease infinite !important;
        position: absolute;
        top: -100px;
    }

    .slide_1_tl_animated_groove_5 {
        width: 194px;
        height: 307px;
    
        background-size: 700% 100%!important;
        background: linear-gradient(359.49deg, rgba(183, 255, 220, 0.81) 12.98%, rgba(204, 120, 255, 0.81) 46.11%, rgba(148, 121, 255, 0.81) 70.36%);
        opacity: .2;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -28px -5px 35px rgb(0, 0, 0), 49px 31px 15px rgba(0, 0, 0, 0.25), inset -4px -15px 17px rgba(0, 0, 0, 0.25);
        clip-path: polygon(32% 38%, 100% 39%, 75% 100%, 0% 100%);
    
        animation: gradient 10s ease infinite, slide_1_tl_animated_groove_5 40s ease infinite !important;
        -moz-animation: gradient 10s ease infinite, slide_1_tl_animated_groove_5 40s ease infinite !important;
        -o-animation: gradient 10s ease infinite, slide_1_tl_animated_groove_5 40s ease infinite !important;
    
        position: absolute;
        top: -100px;
        left: 0px;
    }

    .slide_paragraph {
        width: 100%;
    }


    .row_read_full_story {
        padding-left: 30px;
    }

    .minimize_story {
        margin-left: -10px;
    }

    .slide_4_container {
        padding-left: 20px;
    }

    .lets_collaborate {
        width: 280px;
    }

    .slide_5_white_text {
        font-size: 20px;
    }

    .carousel .slide img {
        width: 250px!important;
        height: 250px!important;
    }

    .menu_tabs {
        z-index: 9999;
        display: none!important;
    }

    .button_arrow_labels {
        display: none!important;
    }

    .hamburger {
        display: block!important;
    }

    .read-my-story-btn {
        padding-left: 14px!important;
        
        padding-right: 14px!important;
    }

    .slide_1_row_btns {
        justify-content: flex-start;
        padding-left: 30px;
    }

    .hi_i_am {
        padding-left: 10px!important;
        text-align: left!important;
    }

    .kimia-subtitle {
        text-align: left!important;
        padding-left: 14px;
        padding-right: 65px;
        font-size: 14px;
    }



    .left-icon {
        display: none;
    }

    .right-icon {
        display: none;
    }

    .progress_bar_numbers { 
        display: none;
    }

    .slide_1_floating_pink_triangle {
        display: none;
    }

    .slide_1_pearl_img {
        display: none;
    }

    .scale_window {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        // position: absolute;
        // bottom: -60px;
        transition: all 0.5s;
    }

    .regular_size {
        // position: absolute;
        // bottom: 0px;
        // top: 0px;
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        transition: all 0.5s ease;
    }
    

    // .maximize-mini-window {
    //     transition: all 3s;
    //     -webkit-transform: scale(0.6);
    //     transform: scale(0.6);
    //     animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    //     animation: maxWindow 5s ease-out!important;
    // }

    
    // @keyframes maxWindow {
    //     0% {
    //         -webkit-transform: translate3d(0px, 0px, 0px) scale(0.6);
    //         transform: translate3d(0px, 0px, 0px) scale(0.6);
    //     }
    //     50% {
    //         -webkit-transform: scale(0.6) rotate3d(52, 100, 0, 39deg);
    //         transform: scale(0.6) rotate3d(52, 100, 0, 39deg);
    //     }
    //     100% {
    //         -webkit-transform: translate3d(0px, 0px, 0px) scale(0.6);
    //         transform: translate3d(0px, 0px, 0px) scale(1.0);
    //     }
    
    // }

    .opened-menu {
        right: 50px;
        position: absolute;
        z-index: 9999;
        top: 60px;
    }


.shrink-mini-window {
    background: black;
    transition: all 3s;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    animation: shrinkWindow 16s ease infinite alternate!important;
}

@keyframes shrinkWindow {
    0% {
        -webkit-transform: translate3d(0px, 0px, 0px) scale(0.5);
        transform: translate3d(0px, 0px, 0px) scale(0.5);
    }
    25% {
        -webkit-transform: rotate3d(5, 1, 1, 54deg) scale(0.5);
        transform: rotate3d(5, 10, 1, 54deg) scale(0.5);
    }
    50% {
        -webkit-transform: scale(0.7) rotate3d(52, 100, 0, 39deg);
        transform: scale(0.7) rotate3d(52, 100, 0, 39deg);
    }
    75% {
        -webkit-transform: rotate3d(5, 2, 1, 45deg) scale(0.5);
        transform: rotate3d(5, 2, 1, 45deg) scale(0.5);
    }
    100% {
        -webkit-transform: rotate3d(5, 3, 1, 54deg) scale(0.5);
        transform: rotate3d(5, 3, 1, 54deg) scale(0.5);
    }
}

    .mobile-project-carousel {
        display: block;
    }

    .desktop-project-slideshow {
        display: none;
    }

    .slide_5_small_pearl {
        bottom: 100px;
        width: 50px;
        left: 20px;
    }

    .slide_5_large_pearl {
        bottom: 50px;
        width: 50px;
        left: 80px;
    }

    .slide_5_bl_grooves {
        width: 220px;
    }

    .slide_5_br_grooves {
        display: none;
    }

    .slider:hover {

    }

    .carousel .control-dots {
        bottom: -10px!important;
    }

    .slide_5_gradient_text {
        font-size: 25px;
        line-height: 38px;
        font-weight: normal;
    }


    .slide_3_tl_animated_groove_3 {
        left: auto;
    }

    .slide_3_animated_groove {
        bottom: 200px!important;
        left: -100px;
        width: 300px;
    }

    .carousel-root {
        display: flex;
        justify-content: center;
    }

    .slide_3_br_animated_groove_2 {
        display: none;
    }
    
    .slide_3_bl_animated_groove_3 {
        display: none;
    }

    

    .slide_3_bl_animated_groove_1 {
        width: 294px;
        left: auto;
    }

    .slide_2_tl_animated_groove_1 {
        height: 500px;
    }

    .slide_2_tl_animated_groove_2 {
        height: 300px;
        width: 224px;
    }

    .slide_2_br_animated_groove_2 {
        right: auto;
    }

    .slide_2_tl_animated_groove_4 {
        left: auto;
        height: 70px;
        width: 124px;
    }

    //.slide_2_br_animated_groove_3

    .slide_2_tl_animated_groove_5 {
        left: 10px;
    }

    .slide_1_tl_animated_groove_3 {
        left: 30px;
        width: 223px;
    }

    .slide_2_br_animated_groove_3 {
        right: 70px;
        top: 10px;
        height: 400px;
    }

    .slide_3_br_animated_groove_3 {
        display: none;
    }

    .slide_2_main_img {
        display: none;
    }

    .slide_2_tl_animated_groove_3 {
        left: auto;
        height: 50px;
        width: 54px;
    }

    .slide_2_white_text {
        text-align: left;
        font-size: 14px;
        padding-left: 20px;
        width: 100%;
        padding-right: 30px;
        margin-bottom: 0px;
        z-index: 999;
        line-height: 146.34%;
    }

    .slide_2_gradient_text {
        text-align: left;
        font-size: 42px;
        padding-left: 20px;
        margin-bottom: 0px;
        z-index: 9999;
        margin-top: -10px;
        line-height: 66px;
    }

    .slide_4_right_grooves {
       display: none;
    }

    .slide_4_left_grooves {
        display: none;
    }



    .slide_3_col_1 {
        display: none;
    }

    .slide_4_white_text {
        font-size: 52px;
        margin-top: -10px;
    }

    .slide_4_life_gradient_text {
        font-size: 57px;
        margin-top: -20px;
    }

    .slide_3_white_text {
        font-size: 42px;
        width: 300px;
        padding-left: 30px;
    }


    .x1 {
        white-space: pre-wrap!important;
    }

    .x2 {
        white-space: pre-wrap!important;
    }

    .x3 {
        white-space: pre-wrap!important;
    }

    .x4 {
        white-space: pre-wrap!important;
    }

    .x5 {
        white-space: normal!important;
    }

    .slide_3_gradient_text {
        font-size: 42px;
        height: 100px;
        line-height: 50px;
        padding-left: 30px;
    }

    .castle {
        width: 100%;
        position: relative;
        bottom: -30px;
    }

    .slide_1_floating_hexagon {
        width: 40px;
        top: 0px;
    }

    .slide_2_floating_hexagon {
        right: 10px;

    }

    .white-line-divider {
        opacity: 0;
        display: none;
    }

    .light-pink-line-divider {
        opacity: 0;
        display: none;
    }

    .hamburger {
        top: 10px;
        right: -30px;
    }

    // .slide_1_container {
    //     padding-left: 10px;
    // }



    .kimia_logo {
        left: 30px;
        cursor: pointer;
        width: 60px;
        z-index: 99999;
    }
    .slide_1_glowy_rectangle {
        display: none;
    }

    .slide_1_blue_diamond {
        display: none;
    }

    .col.slide_1_col_1 {
        padding-left: 40px;
        z-index: 9999;
        padding-top: 90px;
    }

    .menu-arrows-container {
        position: absolute;
        top: 54%;
        float: right;
        display: block!important;
        cursor: pointer;
        z-index: 999999!important;
        // transform: translateY(-50%) translateX(100%);
        left: auto;
        right: 26px;

        transform: translateY(-50%);
        cursor: pointer;
        z-index: 999999;
    }

    .hamburger {
        top: 10px;
        right: 10px;
    }

    .hamburger__patty:nth-child(1) {
        left: 60px;
    }

    .hamburger__patty:nth-child(2) {
        left: 55px;
    }
    

    .hamburger.active .hamburger__patty:nth-child(1) {
        left: 55px;
    }
    
    .hamburger.active .hamburger__patty:nth-child(2) {
        left: 50px;
    }

    .slide_1_row_1 {
        // position: absolute;
        bottom: 40px;
    }


    

}