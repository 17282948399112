
.projectListMenuBody {
    position: fixed;
    background: rgba(255, 255, 255, 0.06);
    height: 100%;
    width: 252px;
    padding: 30px;
    left: 116px;
}

.projectListTitle {
    color: white;
    letter-spacing: 0.205em;
    font-weight: 600;
    font-size: 15;
}

.projectListProject {
    color: white;
    font-size: 15px;
    font-weight: 500;
    transition: all 1s ease 0s;
}

.projectListProjectActive {
    color: rgba(255, 255, 255, 0.41);
    font-size: 15px;
    margin-left: 20px;
    transition: all 1s ease 0s;
    font-weight: 500px;
}

.projectListProjectActive:hover {
    color: white;
    font-size: 15px;
    margin-left: 40px;
    transition: all 1s ease 0s;
    font-weight: 500px;
}

.activeProjLink {
    color: white;
    font-size: 15px;
    margin-left: 40px;
    transition: all 1s ease 0s;
    font-weight: 500px;
}

.closedDropdownIcon {
    rotate: 90;
    margin-top: 10px;
}