.progressCard {
    border: 0.7px solid rgba(255, 255, 255, 0.18)!important;
    box-sizing: border-box;
    box-shadow: 0px 19px 37px rgba(0, 0, 0, 0.25)!important;
    border-radius: 18px!important;
    margin-right: 10px;
    margin-left: 10px;
}

.numberCard {
    background: rgba(196, 196, 196, 0.06)!important;
    border: none!important;
    box-shadow: 0px 35px 22px rgba(0, 0, 0, 0.25);
    border-radius: 30px!important;
    box-shadow: 0px 35px 22px rgba(0, 0, 0, 0.25)!important;
}

.percentIncrease {
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    /* identical to box height */

    letter-spacing: 0.18em;
    margin-bottom: 0px;
    color: #FFFFFF;
}

.cardValue {
    text-align: center;
    font-weight: 300;
    font-size: 30px;
    line-height: 36px;

    color: #FFFFFF;
}


.numberCardTitle {
    color: white;
    font-size: 25px;
    margin-top: 7px;
    font-weight: 600;
    margin-bottom: 3px;
}

.numberCardHeading {
    color: rgba(255, 255, 255, 0.81);
    font-size: 18px;
    font-weight: normal;
}

.numberCardIcon {
    position: absolute;
    top: 15px;
    right: 30px;
}


