
.onBoardingBg {
    background: linear-gradient(-45deg, #254161, #547fca, #4296ce,#0B1A42);
    height: 80vh;
    border-radius: 30px;
    background-size: 400% 400%;
    transition: 3s ease-in!important;
    background-size: 400% 400%;
    box-shadow: 10px 35px 22px rgb(10, 21, 43);
    border: 1px solid rgba(255, 255, 255, 0.18);
    animation: gradient 15s ease infinite;
}

.onBoardingBody {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.onBoardingTitle {
    color: white;
    font-size: 19px;
    letter-spacing: 0.165em;
    text-align: center;
    margin-bottom: 2px;
}

.onBoardingSubTitle {
    color: white;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
}

.onBoardingText {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 168.84%;
    /* or 32px */
    text-align: center;
}

.onBoardingInput {
    border: 1px solid #FFFFFF!important;
    border-radius: 3px;
    // width: 622px!important;
    width: 100%!important;
    height: 52px!important;
    outline: none;
    color: white!important;
    font-size: 15px!important;
    padding-left: 20px!important;
    background: none!important;
}

.onBoardingInput::-webkit-input-placeholder {
    font-weight: 300!important;
    font-size: 15px!important;
    color: white!important;
}

.onBoardingInput:focus {
    font-weight: 300!important;
    font-size: 15px!important;
    color: white!important;
    outline: none;
}

.onBoardingBtn {
    background: rgba(255, 255, 255, 0.247)!important;   
    border-radius: 40px!important;
    color: white!important;
    font-weight: 600;
    outline: none!important;
    width: 200px!important;
    height: 48px!important;
    letter-spacing:  0.08em!important;
    width: 120px!important;
    box-shadow: 0px 21px 31px rgba(0, 0, 0, 0.25)!important;
}

.onBoardingBtn {
    background: rgba(255, 255, 255, 0.247)!important;   
    border-radius: 40px!important;
    color: white!important;
    font-weight: 600;
    outline: none!important;
    width: 200px!important;
    height: 48px!important;
    letter-spacing:  0.08em!important;
    width: 120px!important;
    box-shadow: 0px 21px 31px rgba(0, 0, 0, 0.25)!important;
}

.numberGlassCard {
    transition: 1s ease-in!important;
    background: rgba(196, 196, 196, 0.06)!important;
    border: none!important;
    box-shadow: 0px 35px 22px rgba(0, 0, 0, 0.25);
    border-radius: 30px!important;
    box-shadow: 0px 35px 22px rgba(0, 0, 0, 0.25)!important;
}

.onBoardingBorder {
    border-color: rgba(255, 254, 255, 0.32);
    width: 400.03px;
    border-width: 0.5px;
}

.onBoardingGlassCard {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 16.55%, rgba(255, 255, 255, 0) 100%)!important;
    box-shadow: none!important;
    border-radius: 36px!important;
    width: 550px!important;
    // width: 738px;
    padding: 30px;
    // height: 366px;
    transition: 2s ease-in!important;
}

.onBoardingLightCard {
    background: rgba(61, 78, 117, 0.83)!important;
    border-radius: 11px!important;
    box-shadow: none!important;
}

.onBoardingLogo {
    position: absolute;
    top: 30px;
    left: 40px;
}
