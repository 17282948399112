.projectCardBody {
    border: 1px solid rgba(180, 180, 180, 0.3)!important;
    border-radius: 11px!important;
    background: none!important;
}

.projectOptionsIcon {
    background: none!important;
    border: none!important;
    position: absolute;
}

.projectCardTitle {
    color: #FBFBFF;
    font-weight: 600;
    font-size: 19px;
}

.projectCardHeading {
    color: white;
    font-size: 15px;
    font-weight: 500;;
}

.projectCardVerticalLine {
    border-left: 1px solid rgba(229, 229, 229, 0.25);
}

.projectCardSubHeading {
    color: white;
    font-size: 14px;
    font-weight: 300;;
}

