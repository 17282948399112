.signInBackground {
    background: linear-gradient(83.12deg, #0B1A42 7.82%, #254262 97.64%);
    height: 200vh;
}

.signInBody {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.signInTitle {
    font-size: 30px;
    color: white;
    letter-spacing: 0.045em;
    margin-bottom: 11px;
    text-align: center;
}

.signInHeading {
    width: 376px;
    font-size: 18px;
    text-align: center;
    line-height: 139.34%;
    color: white;
    margin-bottom: 30px;
}

.signInSubText {
    color: white;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
}

.signInInput {
    border: 1px solid #FFFFFF;
    padding-left: 25px;
    font-size: 18px;
    color: white;
    outline: none;
    background: none;
    width: 411px;
    height: 58px;
    border-radius: 3px;
}

.signInBtn {
    background: white!important;
    outline: none!important;
    border-radius: 32px!important;
    height: 64px;
    width: 216px;
    color: #182E52;
    font-size: 17px!important;
    font-weight: bold!important;
    letter-spacing: 0.08em!important;
}

