.reuploadFileTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.24em;
    color: #FFFFFF;
}

.reuploadFileContainer {
    padding: 20px;
    border-radius: 25px;
    background: linear-gradient(-45deg, #254161, #547fca, #4296ce,#0B1A42);
    background-size: 400% 400%;
    border-radius: 25px;
    background-size: 400% 400%;
    box-shadow: 0px 35px 22px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.18);
    animation: gradient 15s ease infinite;
}

.reuploadFileBtn {
    background: rgba(255, 255, 255, 0.11)!important;
    outline: none!important;
    font-style: normal;
    font-weight: 500!important;
    font-size: 12px!important;
    line-height: 139.34%!important;
    text-align: center!important;
    letter-spacing: 0.11em!important;
    color: #FFFFFF!important;
    width: 115px!important;
    height: 41px!important;
}