
.ml-production-featureCard {
    background: linear-gradient(93.35deg, rgba(255, 255, 255, 0.05) 14%, rgba(255, 255, 255, 0) 84.9%)!important;    border-radius: 30px!important;
    border: 1px solid rgba(255, 255, 255, 0.19)!important;
     box-shadow: none!important;
    
}

.featureCardPadding {
    padding: 1.9rem;
}



.featureGradientCard {
    background: linear-gradient(148.66deg, #4850FF -7.89%, #282FD4 106.7%)!important;
    box-shadow: 0px 50px 25px rgba(0, 0, 0, 0.25)!important;
    border-radius: 14px!important;
    border: 1px solid rgba(255, 255, 255, .46)!important;
}

.btn-group, .btn-group-vertical {
    display: inline-block!important;
}

.featureGradientCardTitle {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.24em;
    
    color: #FFFF;
}

.featureGradientHeading {
    color: white;
    font-weight: 700;
    font-size: 30px;
    margin-top: 10px;
 }


