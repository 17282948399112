
label {
    color: #FFFFFF!important;
  
  }
  
  .ml-production-home-body {
    background: linear-gradient(180deg, #2B326A -3.36%, #242F5C 9.58%, #262545 28.94%, #233161 53.7%, #241E39 100%) ;
    height: 100%;
    padding-top: 60px;
    overflow-x: hidden;
  }
  
  .stackstrLogo {
    z-index: 999;
    left: 40px;
    width: 40px;
    top: 10px;
    position: absolute;
  }
  
  .menuLink {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-right: 65px;
    margin-top: 23px;
    line-height: 18px;
    height: 18px;
    cursor: pointer;
    transition: 1s ease-in;
    color: rgba(255, 255, 255, 0.42);
  }
  
  .mainDashboardImg {
    height: 800px!important;
    z-index: 999;
  }
  
  .whiteLabel {
    margin-bottom: 1px;
  
  }
  
  
  .menuLink:hover {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    margin-right: 65px;
    margin-top: 23px;
    cursor: pointer;
    line-height: 18px;
    height: 18px;
    transition: 1s ease-in;
    color: white;
  }
  
  .titleMLMonitoring {
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 84px;
    text-align: center;
    color: #FFFFFF;
  }
  
  
  .paragraphMonitoring {
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    text-align: center;
    color: white;
    line-height: 152.84%;
    margin-top: 18px;
    /* or 34px */
  }
  
  .paragraphMonitoringBold {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    line-height: 152.84%;
    color: #FFFFFF;
  }
  
  .floatingIconDiamond {
    -webkit-animation: action .75s infinite  alternate;
    animation: action .75s infinite  alternate;
  }
  
  @-webkit-keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-15px); }
  }
  @keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-15px); }
  }
  
  .MLmonitoringBtn {
    background: linear-gradient(95.42deg, #6398FF 16.41%, #4850FF 95.87%)!important;
    outline: none!important;
    border: 1px solid rgba(255, 255, 255, 0.36)!important;
    box-sizing: border-box;
    box-shadow: 0px 31px 18px rgba(0, 0, 0, 0.15)!important;
    border-radius: 13px!important;
    font-weight: bold!important;
    font-size: 16px!important;
    line-height: 19px;
    width: 220px;
    height: 54px;
    color: #FFFFFF;
  }
  
  
  .btnMonitoring {
    width: 130px;
    outline: none!important;
    margin-top: 10px;
    float: right!important;
    border: none;
    height: 43px;
    color: white!important;
    background: rgba(255, 255, 255, 0.26);
    border-radius: 53px;
  }
  
  
  
  
  .btnMonitoringText {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
  }
  
  .backedBySubText {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.255em;
    color: #FFFFFF;
  }
  
  .featureTitle {
    font-weight: 600;
    font-size: 38px;
    line-height: 45px;
    background: linear-gradient(to right, #7E41FF 0%, #48BDFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
  
  .featureSubTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;
  }
  
  .featureText {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    text-align: right;
  
    color: #FFFFFF;
  }
  
  .rowCenterpiece {
    justify-content: center;
  }
  
  
  .bulletPointTitle {
    font-style: normal;
    border-left: 2px solid #767BFF;
    padding-left: 9px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
  }
  
  .bulletPointText {
    // height: 63px;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
  }
  
  .whyStackStrTitle {
    width: 359px;
    height: 90px;
    left: 479px;
    top: 2656px;
  
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 45px;
  
    color: #FFFFFF;
  }
  
  .whyStackStrBulletPointText {
    width: 174px;
    height: 88px;
    left: 488px;
    top: 2893px;
    margin-top: -100px;
    
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    /* or 147% */
    
    
    color: rgba(255, 255, 255, 0.84);
    
  }
  
  .circleGradient {
    background: linear-gradient(-45deg,#4850FF, #97c0ff);
    background-size: 700% 400%;
    backface-visibility: hidden;
    transition: 2s ease-in;
    perspective: 1000;
    width: 136px;
    left: 30px;
    position: absolute;
    backdrop-filter: blur(15px);
    top: 100px;
    box-shadow: 53px 48px 20px rgba(0, 0, 0, 0.25);  
    border-radius: 50%;
    height: 145px;
    animation: gradient 4s ease infinite;
    transform: opacity 1.8s ease-in 50ms;
  }
  
  .circleLightGradient {
    z-index: 999;
    background: linear-gradient(-45deg,#4850FF, #F95EB2);
    background-size: 700% 400%;
    backface-visibility: hidden;
    position: absolute;
    top: 490px;
    right: 50px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    transition: 2s ease-in;
    // opacity: 20%;
    backdrop-filter: blur(15px);
    perspective: 1000;
    width: 136px;
    box-shadow: 53px 48px 20px rgba(0, 0, 0, 0.25);  
    border-radius: 50%;
    height: 136px;
    animation: gradient 4s ease infinite;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;
    transform: opacity 1.8s ease-in 50ms;
  }
  
  .circleLightTealGradient {
    z-index: 999;
    background: linear-gradient(-45deg, #4850FF, #7075FF);
    background-size: 700% 400%;
    backface-visibility: hidden;
    position: absolute;
    top: 220px;
    right: 40px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    transition: 2s ease-in;
    opacity: 20%;
    backdrop-filter: blur(15px);
    perspective: 1000;
    width: 136px;
    box-shadow: 53px 48px 20px rgba(0, 0, 0, 0.25);  
    border-radius: 50%;
    height: 136px;
    animation: gradient 4s ease infinite;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;
    transform: opacity 1.8s ease-in 50ms;
  }
  
  .circleLightestTealGradient {
    background: linear-gradient(-45deg,#4850FF, #97c0ff);
    background-size: 700% 400%;
    backface-visibility: hidden;
    transition: 2s ease-in;
    perspective: 1000;
    width: 136px;
    right: 10px;
    position: absolute;
    backdrop-filter: blur(15px);
    top: 260px;
    box-shadow: 53px 48px 20px rgba(0, 0, 0, 0.25);  
    border-radius: 50%;
    height: 145px;
    animation: gradient 4s ease infinite;
    transform: opacity 1.8s ease-in 50ms;
  }
  
  .point {
    padding-left: 15px;
    padding-top: 5px;
    margin-bottom: 4px;
    transition: 1s ease-out;
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 24px;
    cursor: pointer;
  }
  
  .point.active {
    transition: 1s ease-in;
    margin-bottom: 4px;
    border: 1px solid white;
    border-radius: 24px;
    cursor: pointer;
  }
  
  .designedForDevelopers {
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 45px;
    background: linear-gradient(to right, #61EEF0 0%, #48BDFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
  
  .designedfordeveloperImg {
    width: 100%;
    margin-top: -130px;
  }
  
  .APIdocumentation {
    width: 123px;
    height: 40px;
    left: 905px;
    top: 10px;
    outline: none!important;
    background: linear-gradient(95.42deg, #6398FF 16.41%, #4850FF 95.87%);
    border: 1px solid rgba(255, 255, 255, 0.36);
    box-sizing: border-box;
    box-shadow: 0px 0px 18px #0C1A50;
    border-radius: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
  }
  
  .subTitleBulletPoint {
    width: 221px;
  
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  
    color: #FFFFFF;
  }
  
  .seamlessIntegration {
  }
  
  .bulletPointText {
    width: 213px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
  }
  
  .seamlessIntegration {
    width: 365px;
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    text-align: center!important;
    line-height: 45px;
    background: linear-gradient(95.42deg, #9B8BFF
    16.41%, #FFABFC
    95.87%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  
  }
  
  @keyframes gradient {
   0% {
     background-position: 0% 50%;
   }
   50% {
     background-position: 100% 50%;
   }
   100% {
     background-position: 0% 50%;
   }
  }
  
  .homeHeader {
    position: sticky;
    top: 0px;
    width: 100%;
    background: rgba(255, 255, 255, 0.2) ;
    backdrop-filter: blur(12px);
    height: 70px;
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
  }
  
  
  
  .triangleBody {
    background: linear-gradient(-45deg, #ADE2FF, #4F75FF, #94C7FF, #412DBB, #6691FF, #412DBB);
    background-size: 700% 400%;
    backface-visibility: hidden;
    // opacity: 80%;
    perspective: 1000;
    width: 70%;
    box-shadow: 0px 163px 76px rgb(28, 29, 58);  
    border-bottom: 7px solid rgba(254, 192, 255, 0.15);
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 418px;
    border-top-left-radius: 10px;
    border-top-right-radius: 400px;
    height: 50%;
    position: absolute;
    top: 100px;
    transform: translateZ(0);
    left: 30px;
    animation: gradient 10s ease infinite;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;
    // transform: opacity 1.8s ease-in 50ms;
    transition: 2s ease-in;
  }
  
  .triangleBody:hover {
    background: linear-gradient(-45deg, #ADE2FF, #4F75FF, #94C7FF, #412DBB, #6691FF, #412DBB);
    background-size: 700% 400%;
    backface-visibility: hidden;
    // opacity: 10%;
    transition: 2s ease-in;
    perspective: 1000;
    width: 70%;
    box-shadow: 0px 163px 76px rgb(28, 29, 58);  
    border-bottom: 7px solid rgba(254, 192, 255, 0.15);
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 400px;
    transform: translateZ(0);
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: gradient 10s ease infinite;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;
    transition: 2s ease-in;
    // transform: opacity 1.8s ease-in 50ms;
  }
  
  .bottomTriangleBody {
    // background: linear-gradient(-45deg, #ADE2FF, #412DBB);
    background-size: 700% 400%;
    backface-visibility: hidden;
    // opacity: 80%;
    perspective: 1000;
    width: 70%;
    border-top: 7px solid rgba(254, 192, 255, 0.15);
    box-shadow: 0px 163px 76px rgb(28, 29, 58);  
    border-bottom: 7px solid rgba(254, 192, 255, 0.15);
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 418px;
    border-top-right-radius: 400px;
  
    height: 50%;
    position: absolute;
    bottom: 0px;
    right: 30px;
    animation: gradient 10s ease infinite;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;
    transform: opacity 1.8s ease-in 50ms;
  }
  
  
  .bottomRowTriangleBody {
    background: linear-gradient(-45deg, #ADE2FF, #412DBB);
    background-size: 700% 400%;
    backface-visibility: hidden;
    // opacity: 80%;
    perspective: 1000;
    width: 70%;
    box-shadow: 0px 163px 76px rgb(28, 29, 58);  
    border-bottom: 7px solid rgba(254, 192, 255, 0.15);
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 418px;
    border-top-right-radius: 400px;
    height: 50%;
    position: absolute;
    bottom: 30px;
    right: 30px;
    animation: gradient 10s ease infinite;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;
    transform: opacity 1.8s ease-in 50ms;
  }
  
  .triangledownfigure {
    background: linear-gradient(-45deg, #ADE2FF, #412DBB);
    background-size: 700% 400%;
    backface-visibility: hidden;
    // opacity: 80%;
    perspective: 1000;
    width: 70%;
    box-shadow: 0px 163px 76px rgb(28, 29, 58);  
    border-bottom: 7px solid rgba(254, 192, 255, 0.15);
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 418px;
    border-top-right-radius: 400px;
    height: 50%;
    position: absolute;
    right: 30px;
    animation: gradient 10s ease infinite;
    -moz-animation: gradient 10s ease infinite;
    -o-animation: gradient 10s ease infinite;
    transform: opacity 1.8s ease-in 50ms;
  
  }
  
  .tinyStackstrLogo {
    display: none;
  }
  
  .dashboardPreview {
    background-size: 700% 400%;
    background: linear-gradient(-45deg, #B19DFF, #2D42FF)!important;
    border: 1px solid rgba(255, 255, 255, 0.49)!important;
    box-sizing: border-box;
    box-shadow: 8px 56px 34px rgba(0, 0, 0, 0.25)!important;
    border-radius: 23px!important;
    backface-visibility: hidden;
    // opacity: 80%;
    perspective: 1000;
    animation: gradient 10s ease infinite!important;
  }
  
  .dashboardPreviewGif {
    border: 1px solid rgba(174, 172, 255, 0.33)!important;
    box-sizing: border-box;
    filter: drop-shadow(0px 18px 23px rgba(0, 0, 0, 0.25))!important;
    border-radius: 12px;
  }
  
  .whyStackStrStrip {
    background: linear-gradient(255.84deg, #10223E 23.02%, #182E51 60.07%);
    padding: 150px
  }
  
  .whyStackStrSubTitle {
    height: 21px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    background: linear-gradient(to right, #20C9FF  100%, #cef0ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
  
  @media (max-width: 1200px) { 
    .titleMLMonitoring {
      font-style: normal;
      font-weight: 500;
      width: 320px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 60px;
      font-size: 50px;
      text-align: left;
      line-height: 1.02;
      margin-bottom: 20px;
      color: #FFFFFF;
    }
  
    .circleLightGradient {
      display: none;
    }
  
    .circleLightTealGradient {
      display: none;
    }
  
    .circleLightestTealGradient {
      display: none;
    }
  
    .whyStackStrTitle {
      width: 100%;
      margin-bottom: 100px;
    }
    .tinyStackstrLogo {
      display: block;
      position: absolute;
      top: 90px;
      right: 25px;
    }
  
    .whyStackStrBulletPointText {
      width: 100%;
    }
  
    .whyStackStrStrip {
      padding-top: 60px!important;
      padding-bottom: 60px!important;
      background: linear-gradient(255.84deg, #10223E 23.02%, #182E51 60.07%);
      padding: 0px
    }
  
    .circleGradient {
      right: 0px;
      left: auto;
      z-index: -9999;
    }
  
    .seamlessIntegration {
      padding: 5px;
    }
  
    .rowCenterpiece {
      justify-content: start;
      padding-left: 20px;
    }
    
  
    .bubbles {
      display: none!important;
      width: 100%!important;
    }
  
    .apiBenefits {
      padding-left: 15px;
      padding-right: 15px;
    }
  
    .designedfordeveloperImg {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  
    .seamlessIntegrationImg {
      width: 100%;
    }
  
    .paragraphMonitoring {
      font-style: normal;
      padding-left: 10px;
      margin-bottom: 30px;
      font-weight: 300;
      padding-right: 10px;
      text-align: left;
      z-index: 999!important;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
    }
    .btnMonitoring {
      width: 152px;
      outline: none!important;
      border: none;
      height: 30px!important;
      background: rgba(255, 255, 255, 0.26);
      border-radius: 53px;
    }
    .mainDashboardImg {
      width: 100%!important;
      height: auto!important;
      padding-top: 30px;
      margin-bottom: -50px;
    }
  
    .ventureFirms {
    }
    
    .homeHeader {
      display: none;
      position: sticky;
      top: 0px;
      width: 100%;
      background: rgba(255, 255, 255, 0.2) ;
      backdrop-filter: blur(12px);
      height: 70px;
      border-bottom-left-radius: 70px;
      border-bottom-right-radius: 70px;
    }
  
  .triangleBody {
      background: linear-gradient(-45deg, #ADE2FF, #4F75FF, #94C7FF, #412DBB, #6691FF, #412DBB);
      background-size: 700% 400%;
      transform: translateZ(0px);
      backface-visibility: hidden;
      // opacity: 80%;
      perspective: 1000px;
      width: 90%;
      box-shadow: 0px 163px 76px rgb(28, 29, 58);  
      border-bottom: 7px solid rgba(254, 192, 255, 0.15);
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 418px;
      border-top-left-radius: 10px;
      border-top-right-radius: 400px;
      height: 50%;
      position: absolute;
      top: 100px;
      left: 30px;
      animation: gradient 10s ease infinite;
      -moz-animation: gradient 10s ease infinite;
      -o-animation: gradient 10s ease infinite;
      transform: opacity 1.8s ease-in 50ms;
      transition: 2s ease-in;
  }
  .bottomTriangleBody {
      background: linear-gradient(-45deg, #ADE2FF, #412DBB);
      background-size: 700% 400%;
      transform: translateZ(0px);
      backface-visibility: hidden;
      // opacity: 80%;
      perspective: 1000px;
      width: 100%;
      box-shadow: 0px 163px 76px rgb(28, 29, 58);  
      border-bottom: 7px solid rgba(254, 192, 255, 0.15);
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 418px;
      border-top-right-radius: 400px;
  
      height: 80%;
      position: absolute;
      bottom: 0px;
      right: 30px;
      animation: gradient 10s ease infinite;
      -moz-animation: gradient 10s ease infinite;
      -o-animation: gradient 10s ease infinite;
      transform: opacity 1.8s ease-in 50ms;
    }
    .btnMonitoring {
      width: 120px;
      outline: none!important;
      border: none;
      height: 43px;
      background: rgba(255, 255, 255, 0.26);
      border-radius: 53px;
    }
  
  }